export default {
  // boolean if getPatientInformation is available
  getPatientInformation: true,

  // boolean if getPatientCondition is available
  getPatientCondition: false,

  // boolean if getPatientEncounter is available
  getPatientEncounter: false,

  // boolean if getPatientEOB is available
  getPatientEOB: true,

  // boolean if getPatientDrugs is available
  getPatientDrugs: true
};
