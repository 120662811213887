import pollingHelpers from "../../utils/pollingHelpers";

export default {
  getBestDrugPrices: async (JWT) => {
    const response = await fetch("/api/rxLink/getBestPrices", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${JWT}`
      },
      body: JSON.stringify({
        // using random number since this isn't crucial to the fetch, and we don't have the user's phone number when we do this fetch
        userPhoneNumber: "5551004199"
      })
    });
    const { pollingEventID } = await response.json();
    return await pollingHelpers.initiatePolling(pollingEventID, JWT);
  },

  // initiate drug search and return polling event id
  initiateDrugSearch: async (userToken, drug) => {
    try {
      const response = await fetch("/api/rxLink/getBestPrice", {
        method: "POST",
        body: JSON.stringify({
          drugName: drug
        }),
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${userToken}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        return data.pollingEventID;
      }
      console.error("error polling");
      return null;
    } catch (err) {
      console.error(err);
      return null;
    }
  },

  // poll for drug event data results
  pollBestPriceResults: async (pollingEventID, userToken) => {
    try {
      const pollingResponse = await pollingHelpers.initiatePolling(pollingEventID, userToken);
      if (pollingResponse.response === "Drug not found") {
        return "no drug found";
      }
      if (pollingResponse.response === "error") {
        return "server error";
      }

      return pollingResponse.response;
    } catch (err) {
      console.error(err);
      return null;
    }
  },

  rxLinkFetch: async (userToken, userPhoneNumber) => {
    const response = await fetch("/api/rxLink/getEnrollment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${userToken}`
      },
      body: JSON.stringify({ userPhoneNumber })
    });

    if (response.ok) {
      const data = await response.json();
      return data.pollingEventID;
    }
    const error = await response.json();
    console.error(error);
    return null;
  }
};
