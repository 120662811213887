import React from "react";
import "./home.css";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import CardImage from "../../assets/croppedJessica.jpg";

export default function CuresActCard() {
  return (
    <div className="curesContainer">
      <div className="curesContent">
        <img
          className="curesImage"
          src={CardImage}
          alt="A book, cell phone and laptop sit atop a rustic desk."
        />
        <div className="curesTextContainer">
          <h2>21st Century Cures Act</h2>
          <br />
          <p style={{ color: "black" }}>How does this affect you?</p>
          <br />
          <p>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            The 21st Century Cures Act, signed into law in 2016, prioritizes the patient's
            perspective, granting you or your chosen representative access to your medical records.
            This empowers you to find a healthcare plan that aligns best with your needs.
          </p>
          <br />
          <Link to="/curesAct" className="arrowLink">
            Understanding the Cures Act &nbsp; <FaLongArrowAltRight />
          </Link>
        </div>
      </div>
    </div>
  );
}
