import React, { useEffect } from "react";

// Prop Chart:
// prop name              | default value                  | is required | permission required to update
// ------------------------------------------------------------------------------------------------------
// value                  | -                              | true        | -
// setValue               | -                              | true        | -
// label                  | -                              | true        | -
// onChange               | (e) => {                       | false       | -
//                        |   setValue(e.target.value);    | false       | -
//                        | }                              | false       | -
// type                   | "text"                         | false       | -
// placeholder            | ""                             | false       | -
// maxlength              | ""                             | false       | -
// id                     | -                              | false       | no
// name                   | -                              | false       | no
// rows                   | 5                              | false       | no
// maxWidth               | "calc(100%-0.75rem)"           | false       | no
// disabled               | false                          | false       | no
// required               | false                          | false       | no
// backgroundColor        | var(--white-text-color)             | false       | no
// labelClasses           | ""                             | false       | yes
// inputClasses           | ""                             | false       | yes
// wrapperClasses         | ""                             | false       | yes

export default function TextArea({
  value,
  setValue,
  label,
  onChange = (e) => {
    setValue(e.target.value);
  },
  placeholder = "",
  maxlength = 250,
  id,
  name,
  rows = 5,
  minWidth = "calc(100%-0.75rem)",
  disabled = false,
  required = false,
  backgroundColor = "var(--white-text-color)",
  labelClasses = "",
  inputClasses = "",
  wrapperClasses = ""
}) {
  // will ensure that both value, set value, and label are provided
  useEffect(() => {
    if (value === undefined || setValue === undefined || label === undefined) {
      throw new Error("Input component requires value, setValue, and label props");
    }
  }, []);

  return (
    <div className={`min-w-full ${wrapperClasses}`}>
      <label
        htmlFor={label}
        className={`block text-left text-lg font-medium leading-6 text-darkGrayColor ${labelClasses}`}>
        {label}
      </label>
      <div className="mt-4 min-w-full">
        <div className="inline-block rounded-md shadow-sm ring-1 ring-inset ring-grayColor relative min-w-full">
          <textarea
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            className={`
            block w-[calc(100%-0.75rem)] rounded-md p-1.5 ring-grayColor border border-solid border-opacity-25 text-lg
            scrollbarThin
            scrollbar-thumb-darkGrayTextColor
            scrollbar-track-secondaryAccentColor
            hover:scrollbar-thumb-grayHighlightColor
            resize-none
            disabled:bg-mediumHighlightColor disabled:text-darkGrayTextColor
              ${inputClasses}`}
            // inline styles are used here because tailwindcss does not support dynamic styling
            style={{
              backgroundColor,
              minWidth
            }}
            maxLength={maxlength}
            placeholder={placeholder}
            disabled={disabled}
            required={required}
            rows={rows}
          />
        </div>
      </div>
    </div>
  );
}
