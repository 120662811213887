export default async function generateLead(JWT, phoneNumber) {
  const postingError = {
    error: true,
    message: "Posting error sending lead to Elite Insurance Partners"
  };
  try {
    const response = await fetch("/api/eliteInsurance/generateLead", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${JWT}`
      },
      body: JSON.stringify({ phoneNumber })
    });

    if (!response.ok) {
      return postingError;
    }

    return await response.json();
  } catch (err) {
    console.error(err);
    return postingError;
  }
}
