/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { IoClose } from "react-icons/io5";
import { IoMdLock } from "react-icons/io";
import { MdOutlineEmail } from "react-icons/md";
import React from "react";
import Button from "../button/Button";
import Input from "../input/Input";
import HCDLLogo from "../../assets/logo.png";

export default function MainLoginForm({
  errorText,
  showSpinner,
  setLoginModalState,
  loginEmail,
  setLoginEmail,
  loginPassword,
  setLoginPassword,
  closeButtonClicked,
  closeButtonClickedNoPrevent,
  navigate,
  handleLogin
}) {
  return (
    <form
      className="pt-[2.5rem] pb-[1.5rem] m-0 px-[2rem] sm:px-[2rem] md:px-[5rem]"
      onSubmit={(e) => handleLogin(e)}>
      <div className="text-right text-3xl">
        <IoClose className="cursor-pointer" onClick={closeButtonClicked} />
      </div>
      <div className="flex justify-center items-center mt-0 flex-col">
        <div className="flex justify-center items-center">
          <img className="w-[4rem] me-5" alt="HCDL logo" src={HCDLLogo} />
          <h2 className="text-[1.5rem] sm:text-[2rem]">Healthcare Download</h2>
        </div>
        <p className="mt-1">Sign In To Your Account</p>
      </div>
      <Input
        label="Email"
        value={loginEmail}
        setValue={setLoginEmail}
        type="text"
        placeholder="Enter your email address"
        inlineStartIcon={MdOutlineEmail}
        wrapperClasses="mt-2"
        backgroundColor="var(--medium-highlight-color:)"
        required
        inputClasses="text-base"
      />
      <Input
        label="Password"
        value={loginPassword}
        setValue={setLoginPassword}
        type="password"
        placeholder="*********"
        wrapperClasses="mt-8"
        passwordRevealToggle
        inlineStartIcon={IoMdLock}
        backgroundColor="var(--medium-highlight-color:)"
        required
        inputClasses="text-base"
      />
      <p
        className="text-base mt-4 cursor-pointer underline text-start"
        onClick={() => setLoginModalState("passRecovEmail")}>
        Forgot Password?
      </p>
      <p className="text-dangerColor text-base mt-2 mb-0">{errorText}</p>
      {showSpinner ? (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          <div className="flex justify-center min-w-full">
            <div className="spinner" />
          </div>
        </>
      ) : (
        <>
          <Button fullWidth className="mt-5" type="submit">
            Sign In
          </Button>
          <div className="flex justify-center items-center mt-3">
            <p className="text-base">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Don't have an account?{" "}
              <span
                className="text-primary cursor-pointer underline text-center"
                onClick={() => {
                  navigate("/register");
                  closeButtonClickedNoPrevent();
                }}>
                Sign Up
              </span>
            </p>
          </div>
        </>
      )}
    </form>
  );
}
