export default {
  // boolean if getPatientInformation is available
  getPatientInformation: false,

  // boolean if getPatientCondition is available
  getPatientCondition: false,

  // boolean if getPatientEncounter is available
  getPatientEncounter: false,

  // boolean if getPatientEOB is available
  getPatientEOB: true
};
