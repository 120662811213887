import pollingHelpers from "../../utils/pollingHelpers";

// check poll status
const pollData = async (pollingEventID, JWT) => {
  const millimanData = await pollingHelpers.initiatePolling(pollingEventID, JWT);

  if (millimanData.status === "failed" || millimanData.status === "missing") {
    return millimanData.status;
  }

  const allResults = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const result of millimanData.response.result) {
    if (result.Value && result.Value.rows && result.Value.rows.length) {
      allResults.push(...result.Value.rows);
    }
  }

  return allResults;
};

// this function will trigger the milliman workflow when called upon
export default {
  trigger: async (JWT) => {
    try {
      const response = await fetch("/api/milliman/getAllPlanRanks", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${JWT}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        return pollData(data.pollingEventID, JWT);
      }
      console.error("failed to trigger milliman workflow");
      return [];
    } catch {
      console.error("failed to trigger milliman workflow");
      return [];
    }
  }
};
