import pollingHelpers from "../../utils/pollingHelpers";

export default {
  async pollProviderOrDrug(pollingEventID, token) {
    const response = await pollingHelpers.initiatePolling(pollingEventID, token);
    return response;
  },

  async searchProviderOrDrug(token, input, carrier, type) {
    const payload = {
      carrier
    };

    let fetchURL;

    if (type === "provider") {
      payload.providerName = input;
      fetchURL = "/api/providerSearch";
    } else if (type === "drug") {
      payload.drugName = input;
      fetchURL = "/api/drugFormularySearch";
    }

    // fetch provider search
    const response = await fetch(fetchURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    });

    if (response.ok) {
      const data = await response.json();
      return data.pollingEventID;
    }
  },

  async getPlanBenefits(token, hNumber, planIdentifier) {
    try {
      const response = await fetch("/api/planBenefits", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ hNumber, planIdentifier })
      });

      if (!response.ok) {
        return null;
      }

      const data = await response.json();
      return data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }
};
