import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginModalStockImg from "../../assets/loginDoc.jpg";
import ReactGATracking from "../../clients/googleAnalyticsClient";
import ReactPixelInitialize from "../../clients/pixelClient";
import Auth from "../../utils/auth";
import Modal from "../modal/Modal";
import MainLoginForm from "./mainLoginForm";
import "./modalstyles.css";
import PassRecovEmailForm from "./passRecovEmailForm";
import PassRecovPassUpdateForm from "./passRecovPassUpdateForm";
import PassRecovRandCodeForm from "./passRecovRandCodeForm";

export default function Login({ loginModal, setLoginModal }) {
  const [errorText, setErrorText] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [loginModalState, setLoginModalState] = useState("login");
  const [tempToken, setTempToken] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  const navigate = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault();
    setShowSpinner(true);
    // ---- save user input to an object
    const userInput = {
      email: loginEmail.toLowerCase(),
      password: loginPassword
    };
    ReactGATracking("Button Click", "Login");
    ReactPixelInitialize("Login", { value: "none" });
    // We are awaiting auth validation.
    try {
      await Auth.validation(userInput, "login", { setErrorText });
    } catch (error) {
      console.error("There was an issue with your login");
    }
    setShowSpinner(false);
  };

  const closeButtonClickedNoPrevent = () => {
    setErrorText("");
    setLoginModal(false);
    setTempToken("");
    setUserEmail("");
    setLoginModalState("login");
    setLoginEmail("");
    setLoginPassword("");
  };

  const closeButtonClicked = (e) => {
    e.preventDefault();
    closeButtonClickedNoPrevent();
  };

  const renderForm = () => {
    switch (loginModalState) {
      case "passRecovEmail":
        return (
          <PassRecovEmailForm
            setLoginModalState={setLoginModalState}
            closeButtonClicked={closeButtonClicked}
            userEmail={userEmail}
            setUserEmail={setUserEmail}
          />
        );
      case "passRecovRandCode":
        return (
          <PassRecovRandCodeForm
            setLoginModalState={setLoginModalState}
            closeButtonClicked={closeButtonClicked}
            userEmail={userEmail}
            setTempToken={setTempToken}
          />
        );
      case "passRecovPassUpdate":
        return (
          <PassRecovPassUpdateForm
            setLoginModalState={setLoginModalState}
            closeButtonClicked={closeButtonClicked}
            tempToken={tempToken}
            email={userEmail}
          />
        );
      case "login":
      default:
        return (
          <MainLoginForm
            errorText={errorText}
            showSpinner={showSpinner}
            setLoginModalState={setLoginModalState}
            loginEmail={loginEmail}
            setLoginEmail={setLoginEmail}
            loginPassword={loginPassword}
            setLoginPassword={setLoginPassword}
            closeButtonClicked={closeButtonClicked}
            closeButtonClickedNoPrevent={closeButtonClickedNoPrevent}
            navigate={navigate}
            handleLogin={handleLogin}
          />
        );
    }
  };

  return (
    <Modal
      open={loginModal}
      setOpen={setLoginModal}
      closeOnBackdropClick={false}
      padding="0rem"
      withCloseButton={false}
      maxHeight="100%"
      onModalClose={closeButtonClickedNoPrevent}>
      <div className="flex justify-center min-w-fit overflow-hidden">
        <div className="hidden lg:block m-0 p-0">
          <div className="w-[28rem] h-full m-0 p-0">
            <img
              className="object-cover w-full h-full m-0 p-0"
              src={LoginModalStockImg}
              alt="A hand holding a cup of coffee"
            />
          </div>
        </div>
        {renderForm()}
      </div>
    </Modal>
  );
}
