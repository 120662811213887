import React from "react";
import toast from "react-hot-toast";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ReactGATracking from "../../clients/googleAnalyticsClient";
import Mixpanel from "../../clients/mixPanelClient";
import ReactPixelInitialize from "../../clients/pixelClient";
import Button from "../../components/button/Button";
import Auth from "../../utils/auth";
import "../dashboard/dashboard.css";

export default function deleteAccount() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = useNavigate();

  // Creates a fetch call to delete the user from the database
  async function deleteUser() {
    Mixpanel("User Deleted");
    ReactGATracking("Button Click", "User Deleted");
    ReactPixelInitialize("User Deleted", { value: "none" });

    if (Auth.loggedIn()) {
      const userToken = Auth.getToken();
      const fetchURL = `/api/users`;
      try {
        const deletedUser = await fetch(fetchURL, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            authorization: `Bearer ${userToken}`
          }
        });
        if (deletedUser.ok) {
          Auth.logoutUser();
        } else {
          console.error("Error deleting user");
          toast.error("There was an error deleting your account. Please try again later.");
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  // if the user is not logged in reroute them to the home page
  if (!Auth.loggedIn()) {
    return <Navigate to="/" />;
  }

  return (
    <div className="min-h-screen">
      <Button className="mt-20 ml-5 mb-0 ">
        <Link className="text-base" to="/app/dashboard">
          Back
        </Link>
      </Button>
      <h1 className="deleteHeader">Delete Your Healthcare Download Account</h1>
      <div className="outline-box">
        <h2 className="pink-accent">Warning</h2>
        <p>
          Warning this action cannot be undone. You will lose all saved insurance data and all
          account information on healthcare download.
        </p>
        <p>Are you sure you want to delete your account?</p>
        <Button className="m-4 text-base" onClick={() => deleteUser()}>
          Yes
        </Button>
        <Button className="m-4 text-base" onClick={() => navigate("/app/dashboard")}>
          No
        </Button>
      </div>
    </div>
  );
}
