// eslint-disable-next-line import/no-extraneous-dependencies
import ReactPixel from "react-facebook-pixel";
import getCookie from "../utils/cookieHelpers";

export default function ReactPixelInitialize(event, value) {
  const consentCookie = getCookie("consentCookie");
  if (consentCookie !== "rejectAll") {
    ReactPixel.init(process.env.REACT_APP_PIXEL_TOKEN);
    ReactPixel.trackCustom(event, value);
  }
}
