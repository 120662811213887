import React, { useEffect, useState } from "react";
import { PiArrowsHorizontal } from "react-icons/pi";
import { FaRegCircleQuestion } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useBreakpoints } from "react-breakpoints-hook";
import auth from "../../utils/auth";
import Tooltip from "../tooltips/tooltipLogic";
import tooltipObject from "../tooltips/tooltipObject";
import rxInteractionFetch from "../../useFetch/rxInteractionFetch/rxInteractionFetch";
import Table from "../table/table";
import LoadingSkeleton from "../loadingSkeleton/loadingSkeleton";
import DashboardExhibitContainer from "./dashboardExhibitContainer";

export default function RxInteractions2({ setFetchStatus, patientDrugList, dashboardView }) {
  const [drugInteractions, setDrugInteractions] = useState([]);
  const [status, setStatus] = useState("loading");
  const [activeTooltip, setActiveTooltip] = useState(false);
  const [tooltips, setTooltips] = useState({
    rxDrugsTooltip: false,
    rxSeverityTooltip: false,
    rxDescriptionTooltip: false
  });
  const [severityObject] = useState({
    minor: {
      color: "successColor"
    },
    moderate: {
      color: "thirdAccentColor"
    },
    major: {
      color: "dangerColor"
    },
    unknown: {
      color: "grayTextColor"
    }
  });

  const { sm } = useBreakpoints({
    sm: { min: 0, max: 640 }
  });

  useEffect(() => {
    (async function initialInteractionsFetch() {
      if (auth.loggedIn()) {
        const userToken = auth.getToken();
        try {
          const drugInteractionsArr = await rxInteractionFetch.getDrugInteractions(userToken);

          if (patientDrugList.length === 0) {
            setStatus("missing data");
          }

          if (drugInteractionsArr.length > 0) {
            setDrugInteractions(drugInteractionsArr);
            setStatus("complete");
          }

          if (patientDrugList.length > 0 && drugInteractionsArr.length === 0) {
            setStatus("no interactions");
          }
          setFetchStatus("success");
        } catch (err) {
          console.error(err);
          setStatus("error");
          setFetchStatus("failed");
        }
      }
    })();
  }, []);

  const handleTooltip = (tooltip, value) => {
    setTooltips((prev) => ({ ...prev, [tooltip]: value }));
  };

  const severityColor = (severity, severityData) => {
    const { color } = severityData[severity.toLowerCase()];
    return (
      <strong className={`text-whiteTextColor bg-${color} rounded-md p-1 flex w-24 justify-center`}>
        {severity.charAt(0).toUpperCase() + severity.slice(1)}
      </strong>
    );
  };

  // produces the amount of interactions for each type of severity to present a sort of overview to the users
  const countInteractions = () => {
    // Initialize severity count object to iterate over
    const severityCount = {
      minor: 0,
      moderate: 0,
      major: 0,
      unknown: 0
    };

    const severityLevels = [];

    // updated severity count based off users drug interactions
    drugInteractions.forEach((drug) => {
      severityCount[drug.level.toLowerCase()]++;
    });

    const spanGenerator = (severityKey, count, color) => (
      <span key={severityKey} className={`font-semibold text-${color}`}>
        {severityKey.charAt(0).toUpperCase() + severityKey.slice(1)}({count})
      </span>
    );

    // loop through severity count and push instances where count > 0 to severity levels array
    Object.entries(severityCount).forEach(([severityKey, count]) => {
      const { color } = severityObject[severityKey];
      if (count > 0) {
        severityLevels.push(spanGenerator(severityKey, count, color));
      }
    });

    const textString = (
      <span>
        {drugInteractions.length === 1 ? "There is " : "We found "}
        {severityLevels.reduce((accumulator, currentValue, index) => {
          if (index === 0) return currentValue;
          if (index === severityLevels.length - 1)
            return (
              <>
                {accumulator} and {currentValue}
              </>
            );
          return (
            <>
              {accumulator}, {currentValue}
            </>
          );
        }, null)}{" "}
        level{drugInteractions.length === 1 ? "" : "s"} of severity between drugs you are taking.
      </span>
    );

    return textString;
  };

  const renderTableData = () => {
    if (status === "loading") {
      const loadingSkeletonArray = new Array(3);
      loadingSkeletonArray.fill([
        <LoadingSkeleton key="1" />,
        <LoadingSkeleton key="2" />,
        <LoadingSkeleton key="3" />
      ]);
      return loadingSkeletonArray;
    }
    if (status === "complete") {
      const drugInteractionsToMap = dashboardView ? drugInteractions.slice(0, 3) : drugInteractions;

      return drugInteractionsToMap.map((drug, index) => [
        <React.Fragment key={index + "drug"}>
          <div>
            {drug.drug_a}&nbsp; <PiArrowsHorizontal /> &nbsp;
            {drug.drug_b}
          </div>
        </React.Fragment>,
        <div key={index + "severity"}>{severityColor(drug.level, severityObject)}</div>,
        <div key={index + "description"}>
          More information can be found by visiting the FDA for{" "}
          <a
            className="text-lg"
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.fda.gov/search?s=${drug.drug_a}`}>
            {drug.drug_a}
          </a>{" "}
          &{" "}
          <a
            className="text-lg"
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.fda.gov/search?s=${drug.drug_b}`}>
            {drug.drug_b}
          </a>
        </div>
      ]);
    }
    return null;
  };

  return (
    <DashboardExhibitContainer
      title={
        <div className="sm:whitespace-nowrap">
          Rx Interactions
          <FaRegCircleQuestion
            className="text-sm mb-2 ml-2"
            onMouseOver={() => setActiveTooltip(true)}
            onMouseLeave={() => setActiveTooltip(false)}
          />
          {activeTooltip && (
            <Tooltip
              className="tooltipContainer flex items-center"
              text={tooltipObject.rxInteractionsTitle}
              size={sm ? "beneathInline" : "small"}
            />
          )}
        </div>
      }
      subtitle={
        drugInteractions.length > 0
          ? countInteractions()
          : "The rx interactions list is for informational use only."
      }
      viewAll={dashboardView}
      viewAllCount={drugInteractions.length}
      viewAllParam="/rxInteractions">
      <Table
        tableDivClass="universalTableDivClass"
        tableClass={`${
          drugInteractions.length || status === "loading" ? "" : "noDataHeaders"
        } universalTable rxInteractionsMobile`}
        headers={[
          <div key="drug">
            Drugs
            <FaRegCircleQuestion
              className="text-sm ml-2"
              onMouseOver={() => handleTooltip("rxDrugsTooltip", true)}
              onMouseLeave={() => handleTooltip("rxDrugsTooltip", false)}
            />
            {tooltips.rxDrugsTooltip && (
              <Tooltip
                className="tooltipContainer flex items-center"
                text={tooltipObject.rxInteractionsDrugs}
                size="small"
              />
            )}
          </div>,
          <div key="severity">
            Severity
            <FaRegCircleQuestion
              className="text-sm ml-2"
              onMouseOver={() => handleTooltip("rxSeverityTooltip", true)}
              onMouseLeave={() => handleTooltip("rxSeverityTooltip", false)}
            />
            {tooltips.rxSeverityTooltip && (
              <Tooltip
                className="tooltipContainer flex items-center"
                text={tooltipObject.rxInteractionSeverity}
                size="small"
              />
            )}
          </div>,
          <div key="interactionDescription">
            Interaction Description
            <FaRegCircleQuestion
              className="text-sm ml-2"
              onMouseOver={() => handleTooltip("rxDescriptionTooltip", true)}
              onMouseLeave={() => handleTooltip("rxDescriptionTooltip", false)}
            />
            {tooltips.rxDescriptionTooltip && (
              <Tooltip
                className="tooltipContainer flex items-center"
                text={tooltipObject.rxInteractionDescription}
                size={sm ? "beneathInline" : "extra-large"}
              />
            )}
          </div>
        ]}
        data={renderTableData()}
      />
      {status === "missing data" && (
        <p className="m-0 p-2">
          No Rx Interactions found. Add data by{" "}
          <Link to="/insuranceSelect">linking your insurer.</Link>
        </p>
      )}
      {status === "no interactions" && (
        <p>
          It looks like we weren&apos;t able to find any interactions between drugs you&apos;re
          taking.
        </p>
      )}
    </DashboardExhibitContainer>
  );
}
