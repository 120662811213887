import React, { useRef, useState } from "react";
import ReactGATracking from "../../clients/googleAnalyticsClient";
import ReactPixelInitialize from "../../clients/pixelClient";
import Button from "../../components/button/Button";
import Auth from "../../utils/auth";
import "./register.css";

// eslint-disable-next-line import/order
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

export default function SignUp() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const [errorText, setErrorText] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPW, setShowConfirmPW] = useState(false);

  const linkToPrivacyPolicy = (
    <a className="text-base" href="/privacyPolicy">
      Privacy Policy
    </a>
  );
  const handleSignup = async (e) => {
    e.preventDefault();
    setShowSpinner(true);

    ReactGATracking("Form Submit", "Sign up");
    ReactPixelInitialize("Signup", { value: "none" });
    const userInput = {
      firstName: firstNameRef.current.value,
      lastName: lastNameRef.current.value,
      email: emailRef.current.value.toLowerCase(),
      password: passwordRef.current.value,
      confirmPassword: confirmPasswordRef.current.value
    };

    try {
      await Auth.validation(userInput, "signup", { setErrorText });
    } catch (error) {
      console.error("There was an issue with your registration");
    }
    setShowSpinner(false);
  };

  const handlePWVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPW = () => {
    setShowConfirmPW(!showConfirmPW);
  };

  const isInputInvalid = (type, reference) => {
    if (errorText === "Please fill all fields" && reference.current.value === "") {
      return true;
    }
    if (errorText === "Password does not match" && type === "password") {
      return true;
    }
    if (errorText === "Please enter a valid email address" && type === "email") {
      return true;
    }
    return false;
  };

  return (
    <div className="bg-whiteTextColor">
      <form onSubmit={handleSignup} className="card-body register">
        <h1 className="sign-up-title">Sign Up</h1>
        <div className="nameHolder">
          <label className="text-mainTextColor font-semibold" htmlFor="firstName">
            First Name
          </label>
          <input
            className={`inputStyle ${
              isInputInvalid("firstName", firstNameRef) ? "inputStyle-error" : ""
            } `}
            type="text"
            ref={firstNameRef}
            name="firstName"
            placeholder="First Name"
          />
        </div>
        <div className="nameHolder">
          <label className="text-mainTextColor font-semibold" htmlFor="lastName">
            Last Name
          </label>
          <input
            className={`inputStyle ${
              isInputInvalid("lastName", lastNameRef) ? "inputStyle-error" : ""
            } `}
            type="text"
            alt="Last Name Information Here"
            ref={lastNameRef}
            name="lastName"
            placeholder="Last Name"
          />
        </div>
        <div className="nameHolder">
          <label className="text-mainTextColor font-semibold" htmlFor="email">
            Email
          </label>
          <input
            className={`inputStyle ${isInputInvalid("email", emailRef) ? "inputStyle-error" : ""} `}
            type="email"
            ref={emailRef}
            name="email"
            placeholder="youremail@email.com"
          />
        </div>
        <div className="password-input">
          <label className="text-mainTextColor font-semibold" htmlFor="password">
            Password
          </label>
          <div className="password-wrapper">
            <input
              className={` inputStyle ${
                isInputInvalid("password", passwordRef) ? "inputStyle-error" : ""
              } `}
              type={showPassword ? "text" : "password"}
              ref={passwordRef}
              name="password"
            />
            <div className="eye-icon" onClick={handlePWVisibility}>
              {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </div>
          </div>
        </div>

        <div className="password-input">
          <label className="text-mainTextColor font-semibold" htmlFor="confirmPassword">
            Confirm Password
          </label>
          <div className="password-wrapper">
            <input
              className={`
                inputStyle 
                ${isInputInvalid("password", confirmPasswordRef) ? "inputStyle-error" : ""}
              `}
              type={showConfirmPW ? "text" : "password"}
              ref={confirmPasswordRef}
              name="confirmPassword"
            />
            <div className="eye-icon" onClick={toggleConfirmPW}>
              {showConfirmPW ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </div>
          </div>
        </div>
        {showSpinner ? (
          <div className="spinner spinnerContainer" />
        ) : (
          <div>
            <p className="errorText">{errorText}</p>
            <Button type="submit" className="text-base" name="submit">
              Submit
            </Button>
          </div>
        )}
        <p className="openModalButton text-md">
          Passwords must be at least 10 characters long, include one capital letter, and one special
          character.
        </p>
        <p className="text-base">
          Our website uses cookies to enhance your browsing experience. We collect some identifiers
          and electronic network activity information. This does not include your health information
          which is stored in our secure database. We do not, nor ever plan to share or sell any of
          your personal data. Please review our {linkToPrivacyPolicy}.
        </p>
      </form>
    </div>
  );
}
