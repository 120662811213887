import React from "react";

// Prop Chart:
// prop name         | default value                      | is required | permission required to update
// ------------------------------------------------------------------------------------------------------
// height            | 0.5rem                             | false       | -
// maxWidth          | 28rem                              | false       | -
// backgroundColor   | var(--medium-gray-text-color)      | false       | -
// className         | -                                  | false       | -
// rowCount          | 1                                  | false       | -

// Loading skeleton
export default function LoadingSkeleton({
  height = "0.5",
  maxWidth = "20",
  backgroundColor = "var(--medium-gray-text-color)",
  className,
  skeletonRenderCount = 1
}) {
  const skeletons = [];

  for (let i = 0; i < skeletonRenderCount; i++) {
    const style = {
      height: `${height}rem`,
      maxWidth: `${maxWidth}rem`,
      backgroundColor
    };

    skeletons.push(
      <div key={i} className={`animate-pulse rounded-md ${className}`} style={style} />
    );
  }

  return skeletons;
}
