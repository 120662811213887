import React from "react";

// LoadingBar Prop Chart:
// prop name                     | default value         | is required | permission required to update
// ------------------------------------------------------------------------------------------------------
// percentComplete               | 0                     | false       | -
// label                         | ""                    | false       | -
// progressBarBackgroundColor    | "bg-grayTextColor"    | false       | -
// completedBarBackgroundColor   | "bg-thirdAccentColor" | false       | -
// textColor                     | ""                    | false       | -
// className                     | ""                    | false       | -

export default function LoadingBar({
  percentComplete = 0,
  label = "",
  progressBarBackgroundColor = "bg-grayTextColor",
  completedBarBackgroundColor = "bg-thirdAccentColor",
  textColor = "",
  className = ""
}) {
  const progressBarCompletionStyle = {
    width: `${percentComplete > 100 ? 100 : percentComplete}%`
  };

  return (
    <div className={className}>
      <div className="flex justify-between mb-1">
        <span className={`text-lg font-medium ${textColor}`}>{label}</span>
        <span className={`text-lg font-medium ${textColor}`}>
          {percentComplete > 100 ? 100 : percentComplete}%
        </span>
      </div>
      <div className={`w-full ${progressBarBackgroundColor} rounded-md h-3`}>
        <div
          className={`${completedBarBackgroundColor} h-3 rounded-md`}
          style={progressBarCompletionStyle}
        />
      </div>
    </div>
  );
}
