import React, { useState } from "react";
import "./contact.css";
import { IoChatboxEllipses } from "react-icons/io5";
import { Link } from "react-router-dom";
import { FaLinkedin, FaPhoneAlt } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { FaMagnifyingGlass } from "react-icons/fa6";
import toast from "react-hot-toast";
import Input from "../../components/input/Input";
import TextArea from "../../components/input/TextArea";
import Button from "../../components/button/Button";
import ReactGATracking from "../../clients/googleAnalyticsClient";

function GetInTouchLink({ icon, title, description, link, linkText }) {
  return (
    <div className="flex items-center mb-5">
      {icon}
      <div className="flex justify-start items-start flex-col">
        <p className="text-3xl font-bold mb-2">{title}</p>
        <p className="text-xl mt-0">
          {description} <a href={link}>{linkText}</a>
        </p>
      </div>
    </div>
  );
}

export default function Contact() {
  const [userForm, setUserForm] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: ""
  });
  const [formState, setFormState] = useState("pending");
  const [loading, setLoading] = useState(false);

  const submitForm = async (event) => {
    ReactGATracking("Form Submitted", "Contact Us");
    try {
      event.preventDefault();
      setLoading(true);

      // Create a new Promise that wraps around the fetch call
      // This disable is necessary to use toast.promise() to handle loading, success, and error states
      // eslint-disable-next-line no-async-promise-executor
      const fetchDataPromise = new Promise(async (resolve, reject) => {
        try {
          const response = await fetch("/api/contactUs", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(userForm)
          });

          if (response.ok) {
            setFormState("success");
            resolve(response);
          } else {
            setFormState("error");
            reject(response);
          }
        } catch (error) {
          setFormState("error");
          reject(error);
        }
      });

      // Use toast.promise() to handle loading, success, and error states
      toast.promise(
        fetchDataPromise,
        {
          loading: "Submitting form...",
          success: "Form submitted successfully",
          error: "Error submitting form"
        },
        {
          success: {
            duration: 5000
          }
        }
      );

      // still need to await the promise to catch any errors and set the form state/user form state
      await fetchDataPromise;
    } catch (err) {
      setFormState("error");
    } finally {
      setLoading(false);
      setUserForm({
        name: "",
        email: "",
        phoneNumber: "",
        message: ""
      });
    }
  };

  const updateUserForm = (event, formProperty) => {
    const { value } = event.target;

    // If conditions are not met, update userForm
    setUserForm({
      ...userForm,
      [formProperty]: value
    });
  };

  const inputs = [
    {
      label: "Full Name",
      type: "text",
      property: "name",
      required: false
    },
    {
      label: "Email*",
      type: "email",
      property: "email",
      required: true
    },
    {
      label: "Phone Number",
      type: "tel",
      property: "phoneNumber",
      required: false
    }
  ];

  const getInTouchLinkData = [
    {
      icon: <MdOutlineEmail className="text-3xl mr-7" />,
      title: "Email",
      description: "info@healthcare-download.com"
    },
    {
      icon: <FaLinkedin className="text-3xl mr-7" />,
      title: "LinkedIn",
      description: "Follow Us for updates and cool articles on",
      link: "https://www.linkedin.com/company/hcdl-inc/about/",
      linkText: "LinkedIn"
    },
    {
      icon: <FaMagnifyingGlass className="text-3xl mr-7" />,
      title: "Blog",
      description: "Check out some recent news and articles that may directly effect you on our",
      link: "https://healthcaredownload.blog/",
      linkText: "blog"
    },
    {
      icon: <FaPhoneAlt className="text-3xl mr-7" />,
      title: "Call",
      description: "Call our broker to switch health plans at +1 (442) 271-7998"
    }
  ];

  return (
    <div className="contactUsPage">
      <header className="bg-mainTextColor min-h-40 lg:min-h-60 mt-16 flex justify-center items-center">
        <h1 className="text-whiteTextColor my-0 flex justify-center items-center">
          <IoChatboxEllipses className="mr-6" />
          Contact Us
        </h1>
      </header>
      <main className="flex justify-center items-center flex-col lg:justify-around lg:items-start lg:flex-row lg:my-20">
        <form
          className="max-w-[80%] lg:max-w-[35%] m-0 text-start"
          onSubmit={(event) => submitForm(event)}>
          <h2 className="text-4xl mb-10">Have a question?</h2>
          <p className="mb-10">
            Please fill out the form below to submit your question. Your feedback is very important
            and we take pride in helping our users.
          </p>
          {inputs.map((input) => (
            <Input
              key={input.property}
              value={userForm[input.property]}
              setValue={setUserForm}
              onChange={(e) => updateUserForm(e, input.property)}
              label={input.label}
              type={input.type}
              required={input.required}
              wrapperClasses="mb-10"
              inputClasses="text-lg"
            />
          ))}
          <TextArea
            value={userForm.message}
            setValue={setUserForm}
            onChange={(event) => updateUserForm(event, "message")}
            label="Message*"
            required
            wrapperClasses="mb-10"
          />

          <Button className="min-w-full mb-5 text-base" type="submit" disabled={loading}>
            {loading ? "Sending..." : "Send Message"}
          </Button>

          {formState !== "pending" && (
            <p
              className={`text-center text-lg ${
                formState === "success" ? "text-successColor" : "text-dangerColor"
              }`}>
              {formState === "success"
                ? "Message sent successfully!"
                : "Failed to send message. Please try again later."}
            </p>
          )}
          <p className="text-center text-lg">
            Not a member? <Link to="/register">Sign up for free today!</Link>
          </p>
        </form>

        <section className="mt-10 lg:mt-0 w-[calc(100%-5rem)] lg:w-[30%] bg-secondaryAccentColor rounded px-10 py-5 border-solid border border-mediumGrayColor">
          <h2 className="text-4xl mb-10">Get in touch</h2>
          <p className="mb-8">
            We would love to hear from you. Our friendly team is always here to chat. Please allow
            up to 24 hours for a response.
          </p>
          <div>
            {getInTouchLinkData.map((link) => (
              <GetInTouchLink
                key={link.title}
                icon={link.icon}
                title={link.title}
                description={link.description}
                link={link.link}
                linkText={link.linkText}
              />
            ))}
          </div>
        </section>
      </main>
    </div>
  );
}
