export default {
  // boolean if getPatientInformation is available
  getPatientInformation: true,

  // boolean if getPatientCondition is available
  getPatientCondition: true,

  // boolean if getPatientEncounter is available
  getPatientEncounter: false,

  // boolean if getPatientEOB is available
  getPatientEOB: true,

  // boolean if getPatientCoverage is available
  getPatientCoverage: true
};
