import pollingHelpers from "../../utils/pollingHelpers";

export default {
  // fetch user clinical trials
  getUserClinicalTrials: async (JWTToken) => {
    try {
      const response = await fetch("/api/clinicalTrials/retrieveClinicalTrials", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + JWTToken
        }
      });
      if (!response.ok) {
        throw new Error("Error fetching clinical trials");
      }
      const data = await response.json();
      return data.pollingEventID;
    } catch (err) {
      throw new Error("Error fetching clinical trials");
    }
  },

  // poll the clinical trials endpoint until the trials are ready
  pollClinicalTrials: async (pollingID, JWTToken) => {
    try {
      const result = await pollingHelpers.initiatePolling(pollingID, JWTToken);
      if (result.status === "missing") {
        return [];
      }

      if (result.status === "error") {
        throw new Error("Error fetching clinical trials");
      }

      if (result.response) {
        return result.response;
      }

      return [];
    } catch (err) {
      if (err) {
        throw new Error(err);
      }
      throw new Error("Error fetching clinical trials");
    }
  }
};
