import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import smallLogo from "../../assets/logo.png";
import ReactGATracking from "../../clients/googleAnalyticsClient";
import Mixpanel from "../../clients/mixPanelClient";
import ReactPixelInitialize from "../../clients/pixelClient";
import LoadingBar from "../../components/loadingBar/loadingBar";
import aetnaSandboxFetch from "../../useFetch/insuranceFetch/Aetna/aetnaSandboxFetch";
import anthemFetch from "../../useFetch/insuranceFetch/Anthem/anthemFetch";
import blueButton from "../../useFetch/insuranceFetch/BlueButton/blueButtonFetch";
import blueButtonSandboxFetch from "../../useFetch/insuranceFetch/BlueButton/blueButtonSandboxFetch";
import centeneFetch from "../../useFetch/insuranceFetch/Centene/centeneFetch";
import cignaFetch from "../../useFetch/insuranceFetch/Cigna/cignaFetch";
import cignaSandboxFetch from "../../useFetch/insuranceFetch/Cigna/cignaSandboxFetch";
import highmarkFetch from "../../useFetch/insuranceFetch/Highmark/highmarkFetch";
import humanaFetch from "../../useFetch/insuranceFetch/Humana/humanaFetch";
import insurerAPI from "../../useFetch/insuranceFetch/InsurerAPIFetch";
import kaiserFetch from "../../useFetch/insuranceFetch/Kaiser/kaiserFetch";
import UHC from "../../useFetch/insuranceFetch/UHC/UHCFetch";
import UHCSandboxFetch from "../../useFetch/insuranceFetch/UHC/UHCSandboxFetch";
import Auth from "../../utils/auth";
import pollingHelpers from "../../utils/pollingHelpers";
import "./intermediary.css";
import Button from "../../components/button/Button";

export default function Intermediary() {
  // this navigate function from the useNavigate hook will be used to redirect the user to the dashboard once the data is gathered
  const navigate = useNavigate();
  // this variable will be used to store the insurer name from the url path parameter
  const { insurerPathParam } = useParams();
  const insurerName = insurerPathParam
    ? insurerPathParam.charAt(0).toUpperCase() + insurerPathParam.slice(1)
    : "";

  // states for loading bar
  const [progress, setProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState("Authenticating With Your Insurer...");
  const [error, setError] = useState("");
  const [errorDescription, setErrorDescription] = useState("");

  // if the user is logged in, access the id property from their token, otherwise redirect them to the ('/') route
  let userID;
  let JWTtoken;
  if (Auth.loggedIn()) {
    userID = Auth.getUser().data.id;
    JWTtoken = Auth.getToken();
  } else {
    return navigate("/");
  }

  // this function will determine which insurer fetch object to use
  function whichInsurer() {
    switch (insurerPathParam) {
      case "aetna":
      case "aetnaSandbox":
        return aetnaSandboxFetch;
      case "anthem":
      case "anthemSandbox":
        return anthemFetch;
      case "blueButton":
        return blueButton;
      case "blueButtonSandbox":
        return blueButtonSandboxFetch;
      case "centene":
      case "centeneSandbox":
        return centeneFetch;
      case "cignaSandbox":
        return cignaSandboxFetch;
      case "cigna":
        return cignaFetch;
      case "highmark":
        return highmarkFetch;
      case "humana":
      case "humanaSandbox":
        return humanaFetch;
      case "kaiser":
        return kaiserFetch;
      case "kaiserSandbox":
        return kaiserFetch;
      case "uhc":
        return UHC;
      case "uhcSandbox":
        return UHCSandboxFetch;
      default:
        throw new Error(`No insurer found for: ${insurerPathParam}`);
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    // this function will get the auth code from the url
    (async function getAuthCode() {
      // this variable will be used to get the code from the url
      const urlParams = new URL(window.location);
      const params = new URLSearchParams(urlParams.search);
      const authCode = params.get("code");
      const redirectError = params.get("error");
      const redirectErrorDescription = params.get("error_description");
      if (redirectErrorDescription) {
        setErrorDescription(redirectErrorDescription);
      }
      if (redirectError) {
        setError(redirectError);
        return;
      }

      // this variable will be used to store the proper insurer object that tells us which fetches are available
      const insurerHas = whichInsurer();
      if (urlParams.search.length) {
        try {
          // retrieve the token using a fetch to our token callback route in our server
          const authTokenEventID = await insurerAPI.getAuthToken(
            insurerPathParam,
            authCode,
            userID,
            JWTtoken
          );

          // once the eventID is retrieved, begin polling for the token event confirmation
          const tokenPoll = await pollingHelpers.initiatePolling(authTokenEventID, JWTtoken);
          if (tokenPoll.status !== "complete") {
            setError("Authentication Error");
            setErrorDescription(
              "There was an error authenticating with your insurance company. Please try again later"
            );
            return;
          }

          // update the loading bar once the initial fetches are complete
          setProgress(20);
          setProgressMessage("Initializing Data Fetch...");
          Mixpanel("Init Insurance Fetch");
          ReactGATracking("Page View", "Init Insurance Fetch");
          ReactPixelInitialize("Init Insurance Fetch", { value: "none" });
          let progressIndex = 0;
          const progressMessages = [];
          if (insurerHas.getPatientInformation) {
            progressMessages.push("Retrieving Your Demographics...");
          }
          if (insurerHas.getPatientCondition) {
            progressMessages.push("Retrieving Your Conditions...");
          }
          if (insurerHas.getPatientEncounter) {
            progressMessages.push("Retrieving Your Medical Encounters...");
          }
          if (insurerHas.getPatientEOB) {
            progressMessages.push("Retrieving Your Explanation of Benefits...");
          }
          if (insurerHas.getPatientDrugs) {
            progressMessages.push("Retrieving Your Drug Data...");
          }
          if (insurerHas.getPatientCoverage) {
            progressMessages.push("Retrieving Your Coverage Data...");
          }
          const interval = setInterval(() => {
            if (progressIndex < progressMessages.length) {
              setProgress((prevProgress) => prevProgress + 10);
              const message = progressMessages[progressIndex];
              setProgressMessage(message);
              Mixpanel(message);
              ReactGATracking("Page View", message);
              ReactPixelInitialize(message);
              progressIndex++;
            } else {
              clearInterval(interval);
              setProgress(90);
              setProgressMessage("Finalizing...");
              Mixpanel("90% loaded");
              ReactPixelInitialize("90% loaded");
              ReactGATracking("Page View", "90% loaded");
            }
          }, 1000);

          // once token is retrieved, begin fetches for all available clinical data. If the insurer does not have a fetch for a particular data type, then the fetch will be skipped
          const [
            patientInformationEventID,
            patientConditionsEventID,
            patientEncountersEventID,
            patientCoverageEventID
          ] = await Promise.all([
            insurerHas.getPatientInformation
              ? insurerAPI.getInformation(
                  insurerPathParam,
                  "getPatientInformation",
                  userID,
                  JWTtoken
                )
              : Promise.resolve(null),
            insurerHas.getPatientCondition
              ? insurerAPI.getInformation(insurerPathParam, "getPatientCondition", userID, JWTtoken)
              : Promise.resolve(null),
            insurerHas.getPatientEncounter
              ? insurerAPI.getInformation(insurerPathParam, "getPatientEncounter", userID, JWTtoken)
              : Promise.resolve(null),
            insurerHas.getPatientCoverage
              ? insurerAPI.getInformation(insurerPathParam, "getPatientCoverage", userID, JWTtoken)
              : Promise.resolve(null)
          ]);

          // once the eventIDs are retrieved, begin polling for the data event confirmations
          await Promise.all([
            patientInformationEventID
              ? pollingHelpers.initiatePolling(patientInformationEventID, JWTtoken)
              : Promise.resolve(null),
            patientConditionsEventID
              ? pollingHelpers.initiatePolling(patientConditionsEventID, JWTtoken)
              : Promise.resolve(null),
            patientEncountersEventID
              ? pollingHelpers.initiatePolling(patientEncountersEventID, JWTtoken)
              : Promise.resolve(null),
            patientCoverageEventID
              ? pollingHelpers.initiatePolling(patientCoverageEventID, JWTtoken)
              : Promise.resolve(null)
          ]);

          const [patientEOBEventID, patientDrugsEventID] = await Promise.all([
            insurerHas.getPatientEOB
              ? insurerAPI.getInformation(insurerPathParam, "getPatientEOB", userID, JWTtoken)
              : Promise.resolve(null),
            insurerHas.getPatientDrugs
              ? insurerAPI.getInformation(insurerPathParam, "getPatientDrugs", userID, JWTtoken)
              : Promise.resolve(null)
          ]);

          await Promise.all([
            patientEOBEventID
              ? pollingHelpers.initiatePolling(patientEOBEventID, JWTtoken)
              : Promise.resolve(null),
            patientDrugsEventID
              ? pollingHelpers.initiatePolling(patientDrugsEventID, JWTtoken)
              : Promise.resolve(null)
          ]);

          // update the loading bar once the data fetches are complete
          clearInterval(interval);
          setProgress(100);
          setProgressMessage("Redirecting to Your Dashboard...");
          Mixpanel("Redirecting To Dashboard");
          ReactGATracking("Page View", "Redirecting To Dashboard");
          ReactPixelInitialize("Redirecting To Dashboard", { value: interval });
          await new Promise((resolve) => {
            setTimeout(resolve, 1000);
          });

          // redirect the user to the dashboard
          navigate("/app/dashboard");
        } catch (err) {
          console.error(err);
          toast.error("There was an error retrieving some of your data. Please try again later.");
          navigate("/app/dashboard");
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error || errorDescription) {
    return (
      <div className="interm-page">
        <img src={smallLogo} alt="Healthcare download logo" className="interm-page-logo" />
        <div className="interm-page-heading">
          <h1 className="text-center">Error Gathering Data</h1>
        </div>
        <p className="text-center">
          There was an issue gathering your health care data from {insurerName}. Please try again
          later.
        </p>
        {error && (
          <p className="my-2 text-center">
            <span className="text-dangerColor">Error:</span> {error}
          </p>
        )}
        {errorDescription && (
          <p className="my-2 text-center">
            <span className="text-dangerColor">Description:</span> {errorDescription}
          </p>
        )}
        <div className="flex flex-col justify-center items-center w-4/5 lg:w-1/5 mt-8">
          <Button className="my-2 text-base min-w-full" onClick={() => navigate("/app/dashboard")}>
            Dashboard
          </Button>
          <Button
            className="my-2 text-base min-w-full"
            onClick={() => navigate("/insuranceSelect")}>
            Select Another Insurance
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="interm-page">
      <img src={smallLogo} alt="Healthcare download logo" className="interm-page-logo" />
      <div className="interm-page-heading">
        <h1>Gathering Data</h1>
      </div>
      <p>You Will Be Redirected Shortly</p>
      <LoadingBar percentComplete={progress} label={progressMessage} className="w-3/4 md:w-1/2" />
    </div>
  );
}
