/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { useBreakpoints } from "react-breakpoints-hook";
import "./Modal.css";

// Prop Chart:
// prop name             | default value | is required | permission required to update
// ------------------------------------------------------------------------------------
// open                  | -             | true        | -
// setOpen               | -             | true        | -
// children              | -             | false       | -
// id                    | -             | false       | no
// withCloseButton       | true          | false       | no
// withBackdrop          | true          | false       | no
// closeOnBackdropClick  | true          | false       | no
// width                 | --            | false       | no
// minWidth              | "35%"         | false       | no
// minHeight             | "30%"         | false       | no
// maxHeight             | "70%"         | false       | no
// onModalClose          | () => {}      | false       | no
// blockScrollOnMount    | true          | false       | no
// closeOnEsc            | true          | false       | no
// background            | "white"       | false       | yes
// padding               | "2rem"        | false       | yes

export default function Modal({
  open,
  setOpen,
  children,
  id,
  withCloseButton = true,
  withBackdrop = true,
  closeOnBackdropClick = true,
  width,
  minWidth = "35%",
  minHeight = "30%",
  maxHeight = "70%",
  onModalClose = () => {},
  blockScrollOnMount = true,
  closeOnEsc = true,
  background = "white",
  padding = "2rem"
}) {
  const { sm } = useBreakpoints({
    sm: { min: 0, max: 640 }
  });

  // tailwind classes for the backdrop
  const backdropStyles = `
    fixed
    top-0
    left-0
    w-full
    h-full
    flex
    items-center
    justify-center
    ${withBackdrop ? "bg-black bg-opacity-50" : null}
    text-mainTextColor
    z-[9000]
  `;

  // tailwind classes for the modal
  const modalStyles = `
    rounded
    shadow-lg
    scrollbarThin
    scrollbar-thumb-darkGrayTextColor
    scrollbar-track-whiteAccentColor
    hover:scrollbar-darkGrayColor
  `;

  // since tailwind doesn't support dynamic string interpolation/concatenation for classes, the dynamic classes are applied as inline styles
  const modalDimensions = {
    minWidth: sm ? "100%" : minWidth,
    width: sm ? "100%" : width,
    minHeight,
    maxHeight,
    overflowY: "auto",
    background,
    padding
  };

  // this will throw an error if the required props are not passed in
  useEffect(() => {
    if (open === undefined || open === null || !setOpen) {
      throw new Error("Modal component requires 'open' and 'setOpen' props");
    }
  }, []);

  // this will handle closing the modal
  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
    onModalClose();
    // eslint-disable-next-line no-use-before-define
    window.removeEventListener("keydown", handleKeyPress);
    document.body.classList.remove("modalOpen");
  };

  // this will handle closing the modal when the escape key is pressed (if closeOnEsc is true)
  const handleKeyPress = (e) => {
    if (e.key === "Escape") {
      handleClose(e);
    }
  };

  // this will handle if scroll in background is allowed or not
  // will also enable event listener for escape key (cb only executes if closeOnEsc is true)
  useEffect(() => {
    // Add and remove the event listener for the escape key based on the modal state
    if (open && closeOnEsc) {
      window.addEventListener("keydown", handleKeyPress);
    } else {
      window.removeEventListener("keydown", handleKeyPress);
    }

    // Add and remove the 'modalOpen' class to body based on the modal state
    if (open && blockScrollOnMount) {
      document.body.classList.add("modalOpen");
    } else {
      document.body.classList.remove("modalOpen");
    }

    // Clean up the effect when the component is unmounted
    return () => {
      document.body.classList.remove("modalOpen");
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [open]);

  // this will handle closing the modal when the backdrop is clicked (if closeOnBackdropClick is true)
  const handleBackdropClick = (e) => {
    if (e.target.classList.contains("modalBackdrop") && closeOnBackdropClick) {
      handleClose(e);
    }
  };

  return (
    <>
      {open && (
        // backdrop
        <div className={`modalBackdrop ${backdropStyles}`} onClick={(e) => handleBackdropClick(e)}>
          {/* actual modal */}
          <div className={modalStyles} style={modalDimensions} id={id || null}>
            {/* this is the close button unless disabled */}
            {withCloseButton && (
              <div className="text-right text-3xl">
                <IoClose className="cursor-pointer" onClick={(e) => handleClose(e)} />
              </div>
            )}

            {/* this is the content of the modal */}
            {children}
          </div>
        </div>
      )}
    </>
  );
}
