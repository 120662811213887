/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { MdOutlineEmail } from "react-icons/md";
import ReactPixelInitialize from "../../clients/pixelClient";
import loginServices from "./loginServices";
import HCDLLogo from "../../assets/logo.png";
import Input from "../input/Input";
import Button from "../button/Button";

export default function PassRecovEmailModal({
  setLoginModalState,
  closeButtonClicked,
  userEmail,
  setUserEmail
}) {
  const [showSpinner, setShowSpinner] = useState(false);
  const [errorText, setErrorText] = useState("");

  const handleFormSubmit = async (e) => {
    // begin spinner
    e.preventDefault();
    setShowSpinner(true);
    ReactPixelInitialize("Password Recovery Form", { value: userEmail });

    // attempt a request to server to send password recovery email
    try {
      const response = await loginServices.requestPassRecovEmail(userEmail);
      if (response === "success") {
        setLoginModalState("passRecovRandCode");
      } else {
        setErrorText("There was an issue with your request, please try again later.");
      }
    } catch (err) {
      console.error(err);
      setErrorText("There was an issue with your request, please try again later.");
    }

    // end spinner
    setShowSpinner(false);
  };

  return (
    <form
      className="pt-[2.5rem] pb-[1.5rem] m-0 px-[2rem] sm:px-[2rem] md:px-[5rem]"
      onSubmit={(e) => handleFormSubmit(e)}>
      <div className="text-right text-3xl">
        <IoClose className="cursor-pointer" onClick={closeButtonClicked} />
      </div>
      <div className="flex justify-center items-center mt-0 flex-col">
        <div className="flex justify-center items-center">
          <img className="w-[4rem] me-5" alt="HCDL logo" src={HCDLLogo} />
          <h2 className="text-[1.5rem] sm:text-[2rem]">Healthcare Download</h2>
        </div>
        <p className="mt-1">Verify Email To Reset Password</p>
      </div>
      <Input
        label="Email"
        value={userEmail}
        setValue={setUserEmail}
        type="text"
        placeholder="Enter your email address"
        inlineStartIcon={MdOutlineEmail}
        wrapperClasses="mt-2"
        backgroundColor="var(--medium-highlight-color:)"
        required
        inputClasses="text-base"
      />
      <p className="text-dangerColor text-base mt-2 mb-0">{errorText}</p>
      {showSpinner ? (
        <>
          <div className="flex justify-center min-w-full">
            <div className="spinner" />
          </div>
        </>
      ) : (
        <>
          <Button fullWidth className="mt-5" type="submit">
            Send Code To Email
          </Button>
          <div className="flex justify-center items-center mt-1">
            <p className="text-base">
              <span
                className="text-primary cursor-pointer underline text-center"
                onClick={() => {
                  setLoginModalState("login");
                }}>
                Return to login
              </span>
            </p>
          </div>
        </>
      )}
    </form>
  );
}
