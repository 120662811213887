/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { IoCaretBackSharp, IoCaretForwardSharp } from "react-icons/io5";
import { useSwipeable } from "react-swipeable";
import Button from "../button/Button";

export function CarouselItem({ children, width }) {
  return (
    <div className="carousel-item" style={{ width }}>
      {children}
    </div>
  );
}

function Carousel({ children }) {
  // the active index stateful value tracks which index our carousel is at
  const [activeIndex, setActiveIndex] = useState(0);

  // the paused stateful value tracks if the user has a mouse on the carousel
  const [paused, setPaused] = useState(false);

  // this function automatically updates the index
  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  // this use effect hook updates the active index automatically every 4 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1);
      }
    }, 4000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1)
  });

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...handlers}
      className="carousel"
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}>
      <div className="inner" style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
        {React.Children.map(children, (child) => React.cloneElement(child, { width: "100%" }))}
      </div>
      <div className="flex justify-center items-center">
        <Button
          className="m-2"
          type="button"
          onClick={() => {
            updateIndex(activeIndex - 1);
          }}>
          <IoCaretBackSharp size="1rem" />
        </Button>
        {React.Children.map(children, (child, index) => (
          <Button
            className={`m-2 ${index === activeIndex ? "active" : ""}`}
            onClick={() => {
              updateIndex(index);
            }}
            type="button">
            {index + 1}
          </Button>
        ))}
        <Button
          className="m-2"
          type="button"
          onClick={() => {
            updateIndex(activeIndex + 1);
          }}>
          <IoCaretForwardSharp size="1rem" />
        </Button>
      </div>
    </div>
  );
}

export default Carousel;
