import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaRegTrashAlt, FaRegUser } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlineLockPerson } from "react-icons/md";
import { Navigate, useNavigate } from "react-router-dom";
import ReactGATracking from "../../clients/googleAnalyticsClient";
import Mixpanel from "../../clients/mixPanelClient";
import ReactPixelInitialize from "../../clients/pixelClient";
import Button from "../../components/button/Button";
import "../../tailwind.css";
import userProfileFetches from "../../useFetch/userFetch/userProfileFetches";
import auth from "../../utils/auth";

const formObject = {};

// Each section for inputs or plain text can be found here, this will be what renders the sections
function Section({ title, icon, content, inputs, userProfileDB, setUserProfileDB }) {
  const [errorText, setErrorText] = useState(false);
  const navigate = useNavigate();

  if (inputs) {
    inputs.forEach((input) => {
      formObject[input.title] = input.value;
    });
  }

  const [formData, setFormData] = useState(formObject);

  // custom helper for errors based on the server messages
  const handleToastError = (errorMessage) => {
    switch (errorMessage) {
      case "Invalid credentials":
      case "User not found":
        toast.error("Invalid credentials.");
        break;
      case "Failed to update password":
      case "No changes detected":
      case "Something went wrong":
        toast.error("Something went wrong. Please try again later.");
        break;
      case "This is the same email we have on file":
        toast.error("This is the same email you already have on file.");
        break;
      case "User already exists":
        toast.error("This email is already taken.");
        break;
      default:
        break;
    }
  };

  const passwordVariables = {
    numbers: true,
    letters: true,
    specialCharacter: true,
    minPassLength: 9
  };

  const validatePassword = (password) => {
    // Check if password is longer than the specified length
    if (password.length <= passwordVariables.minPassLength) {
      setErrorText("Please enter a password with a length of 10 characters or longer.");
      return false;
    }

    // Check if password has at least one number
    if (passwordVariables.numbers && !/\d/.test(password)) {
      setErrorText("Your password must include at least 1 number.");
      return false;
    }

    // Check if password has at least one letter
    if (passwordVariables.letters && !/[a-zA-Z]/.test(password)) {
      setErrorText("Your password must include at least 1 letter.");
      return false;
    }

    // Check if password has at least one special character
    if (
      passwordVariables.specialCharacter &&
      !/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password)
    ) {
      setErrorText("Your password must contain at least one special character.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Determine the type of update based on the section title
    switch (title) {
      case "Profile": {
        const profileObj = {};

        // fill profile object with values that are different from the users profile settings
        try {
          // eslint-disable-next-line no-restricted-syntax
          for (const key in userProfileDB) {
            if (userProfileDB[key] !== formData[key]) {
              profileObj[key] = formData[key];
            }
          }
          // if no changes, don't do anything
          if (!Object.keys(profileObj).length) {
            return;
          }
          const token = auth.getToken();
          // make call to backend and update users profile state and if it includes a response token from server, set that in local storage as users new auth token
          const response = await userProfileFetches.updateUserProfile(token, profileObj);
          if (response.message === "Successfully updated profile information.") {
            toast.success(response.message);
            setUserProfileDB((prevData) => ({
              ...prevData,
              ...profileObj
            }));
            if (response.hasOwnProperty("token")) {
              localStorage.setItem("userToken", token);
            }
          } else {
            toast.error("Something went wrong, please try again later.");
          }
        } catch (err) {
          toast.error("Something went wrong, please try again later.");
        }
        break;
      }
      case "Change password":
        try {
          setErrorText("");
          const token = auth.getToken();
          if (formData["Current password"] === formData["New password"]) {
            setErrorText("New password cannot match current password.");
            return;
          }
          if (formData["New password"] === formData["Confirm password"]) {
            const passwordValidity = validatePassword(formData["New password"]);
            if (!passwordValidity) {
              return;
            }
            const response = await userProfileFetches.settingsPasswordUpdate(
              token,
              formData["Current password"],
              formData["New password"]
            );
            if (response?.message === "Password updated") {
              toast.success("You've successfully updated your password.");
            } else {
              handleToastError(response.message);
            }
          } else {
            toast.error("Your passwords do not line up.");
          }
        } catch (error) {
          toast.error("Something went wrong, please try again later.");
        }
        break;
      // Add more cases as needed
      default:
        break;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 max-w-7xl mx-auto">
      <div className="px-4 sm:px-0">
        <h2 className="flex items-center text-2xl font-semibold leading-7 text-mainTextColor">
          {icon}
          {title}
        </h2>
        <p className="mt-1 text-lg leading-6 text-darkGrayColor">{content}</p>
      </div>
      {inputs && inputs.length > 0 && (
        <form
          onSubmit={(e) => handleSubmit(e)}
          className="m-0 bg-whiteTextColor shadow-sm ring-1 ring-mediumGrayColor rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-1">
              {inputs.map((input, index) => (
                <div
                  key={`${title} #${index}`}
                  className={title === "Profile" ? "sm:col-span-6" : "sm:col-span-4"}>
                  <div className="mt-1 grid md:grid-cols-2 sm:grid-cols-1">
                    <label
                      htmlFor={input.label}
                      className="flex text-lg md:mt-5 sm-mt-1 align-left font-medium leading-6 text-darkGrayColor">
                      {input.label}
                    </label>
                    <div className="grid md-grid-cols-2 sm:grid-cols-1 lg:ml-[-9rem] md:ml-[-4rem]">
                      <input
                        type={input.type}
                        name={input.title}
                        value={formData[input.title]}
                        onChange={handleInputChange}
                        className=" shadow-sm w-full mr-2 ring-1 ring-inset ring-grayTextColor rounded leading-8 border-0 bg-transparent py-1 pl-2 text-darkGrayColor placeholder:text-mediumGrayTextColor focus:ring-0 text-lg sm:leading-10"
                        placeholder={input.placeholder}
                        required={input.required}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <p className="m-0 p-0 text-base text-dangerColor">{errorText}</p>
          </div>
          <div className="flex items-center justify-end gap-x-6 gap-y-2 border-t border-mediumGrayColor">
            <Button type="submit" className="text-base mt-[-1rem] mb-7 mr-6 sm:mb-3 sm:mr-[1.5rem]">
              Save
            </Button>
          </div>
        </form>
      )}
      {!inputs && (
        <div className="m-0 bg-whiteTextColor shadow-sm ring-1 ring-darkGrayColor sm:rounded-xl md:col-span-2">
          <div className="flex">
            <div className="m-0 flex flex-col justify-center items-end">
              <p className="text-lg px-6 font-medium">
                This action is not reversible, and all information related to this account will be
                deleted permanently.
              </p>
              <Button
                type="button"
                theme="danger"
                onClick={() => navigate("/deleteAccount")}
                className="text-base mb-5 mr-5">
                Yes, delete my account
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default function UserProfile() {
  const [userProfileDB, setUserProfileDB] = useState({});
  const navigate = useNavigate();

  // Retrieve user's info
  useEffect(() => {
    (async function initialPatientFetch() {
      if (auth.loggedIn()) {
        const userToken = auth.getToken();

        try {
          const userTableInfo = await userProfileFetches.retrieveUserInfo(userToken);
          setUserProfileDB((prevData) => ({
            ...prevData,
            email: userTableInfo.email,
            phone_number: userTableInfo.phoneNumber,
            first_name: userTableInfo.firstName,
            last_name: userTableInfo.lastName,
            address_1: userTableInfo.address1,
            address_2: userTableInfo.address2,
            city: userTableInfo.city,
            district: userTableInfo.district,
            state: userTableInfo.state,
            postal_code: userTableInfo.postalCode,
            birthdate: userTableInfo.birthdate,
            gender: userTableInfo.gender
          }));
        } catch (err) {
          console.error("Error retrieving patient info:", err);
        }
      }
    })();
    Mixpanel("User Profile");
    ReactGATracking("Page View", "User Profile");
    ReactPixelInitialize("User Profile", { value: "none" });
  }, []);

  if (!auth.loggedIn()) {
    return <Navigate to="/" />;
  }

  const profileInputs = [
    {
      title: "email",
      label: "Email address",
      placeholder: userProfileDB.email,
      value: userProfileDB.email || "",
      type: "email",
      required: true
    },
    {
      title: "phone_number",
      label: "Phone Number",
      placeholder: userProfileDB.phone_number,
      value: userProfileDB.phone_number || "",
      type: "text",
      required: false
    },
    {
      title: "first_name",
      label: "First name",
      placeholder: userProfileDB.first_name,
      value: userProfileDB.first_name || "",
      type: "text",
      required: true
    },
    {
      title: "last_name",
      label: "Last name",
      placeholder: userProfileDB.last_name,
      value: userProfileDB.last_name || "",
      type: "text",
      required: true
    },
    {
      title: "address_1",
      label: "Address 1",
      placeholder: userProfileDB.address_1,
      value: userProfileDB.address_1 || "",
      type: "text",
      required: false
    },
    {
      title: "address_2",
      label: "Address 2",
      placeholder: userProfileDB.address_2,
      value: userProfileDB.address_2 || "",
      type: "text",
      required: false
    },
    {
      title: "city",
      label: "City",
      placeholder: userProfileDB.city,
      value: userProfileDB.city || "",
      type: "text",
      required: false
    },
    {
      title: "district",
      label: "District/County",
      placeholder: userProfileDB.district,
      value: userProfileDB.district || "",
      type: "text",
      required: false
    },
    {
      title: "state",
      label: "State",
      placeholder: userProfileDB.state,
      value: userProfileDB.state || "",
      type: "text",
      required: false
    },
    {
      title: "postal_code",
      label: "Postal Code",
      placeholder: userProfileDB.postal_code,
      value: userProfileDB.postal_code || "",
      type: "text",
      required: false
    },
    {
      title: "gender",
      label: "Gender",
      placeholder: userProfileDB.gender,
      value: userProfileDB.gender || "",
      type: "text",
      required: false
    },
    {
      title: "birthdate",
      label: "Birthdate",
      placeholder: userProfileDB.birthdate,
      value: userProfileDB.birthdate || "",
      type: "date",
      required: false
    }
  ];

  const passwordInputs = [
    {
      label: "Current password",
      title: "Current password",
      placeholder: "",
      value: "",
      type: "password"
    },
    {
      label: "New password",
      title: "New password",
      placeholder: "",
      value: "",
      type: "password"
    },
    {
      label: "Confirm password",
      title: "Confirm password",
      placeholder: "",
      value: "",
      type: "password"
    }
  ];

  return (
    <div className="min-h-screen py-28 mx-auto px-3 sm:px-10 space-y-10 divide-y divide-gray-300/25 bg-gray-300/10">
      <Button onClick={() => navigate("/app/dashboard")} className="text-base mt-0 ml-5 mb-0">
        Back
      </Button>
      <h1 className="text-3xl flex items-center justify-center m-0 mx-auto max-w-7xl md:text-5xl text-mainTextColor">
        <IoSettingsOutline className="text-4xl text-thirdAccentColor" />
        &nbsp; Welcome {userProfileDB.first_name}
      </h1>
      <p className="mt-1 text-lg leading-6 text-darkGrayColor max-w-7xl mx-auto text-center">
        Update your account information, preferences and settings below.
      </p>
      <hr className="max-w-7xl mx-auto" />

      <Section
        title="Profile"
        icon={<FaRegUser className="text-thirdAccentColor text-lg pr-4" />}
        content="Update the email and phone number associated with your account."
        inputs={profileInputs}
        userProfileDB={userProfileDB}
        setUserProfileDB={setUserProfileDB}
      />

      <hr className="max-w-7xl mx-auto" />

      <Section
        title="Change password"
        icon={<MdOutlineLockPerson className="text-lg pr-4 text-thirdAccentColor" />}
        content="Update the password associated with your account."
        inputs={passwordInputs}
      />

      <hr className="max-w-7xl mx-auto" />

      <Section
        title="Delete Your Account"
        icon={<FaRegTrashAlt className="text-lg pr-4 text-thirdAccentColor" />}
        content="No longer want to use our service? You can delete your account here."
      />
    </div>
  );
}
