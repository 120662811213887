import React, { useState, useEffect } from "react";
import { MdLocalPharmacy } from "react-icons/md";
import { Navigate } from "react-router-dom";
import toast from "react-hot-toast";
import Auth from "../../utils/auth";
import DashboardHeader from "../../components/dashboardHeader/DashboardHeader";
import RxHistory from "../../components/dashboardExhibit/rxHistory";

export default function RxInteractionsDetails() {
  const [fetchStatus, setFetchStatus] = useState("pending");

  useEffect(() => {
    if (fetchStatus.rxHistory === "failed") {
      toast.error("There was an error retrieving some of your data. Please try again later.");
    }
  }, [fetchStatus]);

  if (!Auth.loggedIn()) {
    return <Navigate to="/" />;
  }

  return (
    <div className="min-h-screen">
      <DashboardHeader icon={<MdLocalPharmacy className="sm:pr-6" />} title="Rx History">
        <p className="text-grayColor">Below is a full view of your Rx History.</p>
      </DashboardHeader>
      <div className="mt-2">
        <RxHistory setFetchStatus={setFetchStatus} dashboardView={false} />
      </div>
    </div>
  );
}
