export default {
  settingsPasswordUpdate: async (JWTToken, curPassword, newPassword) => {
    const response = await fetch(`/api/settingsPasswordUpdate`, {
      method: "PUT",
      body: JSON.stringify({ curPassword, newPassword }),
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + JWTToken
      }
    });
    try {
      const data = await response.json();
      return data;
    } catch (err) {
      return { message: "Something went wrong. Please try again later." };
    }
  },
  updateUserProfile: async (JWTToken, profileData) => {
    const response = await fetch(`/api/updateUserProfile`, {
      method: "PUT",
      body: JSON.stringify(profileData),
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + JWTToken
      }
    });
    try {
      const data = await response.json();
      return data;
    } catch (err) {
      return { message: "Something went wrong. Please try again later." };
    }
  },
  retrieveUserInfo: async (JWT) => {
    const response = await fetch("/api/user", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${JWT}`
      }
    });
    try {
      const data = await response.json();
      return data;
    } catch (err) {
      return { message: "Something went wrong. Please try again later." };
    }
  }
};
