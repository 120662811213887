import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AiFillDatabase } from "react-icons/ai";
import { Navigate } from "react-router-dom";
import MedicareOptions from "../../components/dashboardExhibit/medicareOptions";
import DashboardHeader from "../../components/dashboardHeader/DashboardHeader";
import Auth from "../../utils/auth";

export default function RxInteractionsDetails() {
  const [fetchStatus, setFetchStatus] = useState("pending");
  const [toasted, setToasted] = useState(false);

  useEffect(() => {
    if (fetchStatus.planRanks === "failed" && !toasted) {
      toast.error("There was an error retrieving some of your data. Please try again later.");
      setToasted(true);
    }
  }, [fetchStatus]);

  if (!Auth.loggedIn()) {
    return <Navigate to="/" />;
  }

  return (
    <div className="min-h-screen">
      <DashboardHeader icon={<AiFillDatabase className="sm:pr-6" />} title="Medicare Options">
        <p className="text-grayColor">Below is a full view of your Medicare Options.</p>
      </DashboardHeader>
      <div className="mt-2">
        <MedicareOptions dashboardView={false} setFetchStatus={setFetchStatus} />
      </div>
    </div>
  );
}
