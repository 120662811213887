import React, { useEffect, useState } from "react";
import { HiOutlineChartSquareBar } from "react-icons/hi";
import { MdLocalPharmacy } from "react-icons/md";
import patientFetches from "../../useFetch/userFetch/patientFetches";
import Auth from "../../utils/auth";
import Table from "../table/table";

export default function CurrentPlanCard() {
  const [totalEOBPriceState, setTotalEOBPriceState] = useState(0);
  const [totalDrugsPriceState, setTotalDrugsPriceState] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [patientCurrentPlanState, setPatientCurrentPlanState] = useState({});

  useEffect(() => {
    (async function () {
      if (Auth.loggedIn()) {
        const JWTToken = Auth.getToken();

        // fetch the patient's current plan, drugs, and EOBs
        const [patientCurrentPlan, patientDrugs, getPatientEOB] = await Promise.all([
          patientFetches.getUsersCurrentPlan(JWTToken),
          patientFetches.getPatientDrugs(JWTToken),
          patientFetches.getPatientEOB(JWTToken)
        ]);

        // calculate the total cost of the patient's drugs
        const totalDrugsPrice = patientDrugs.reduce(
          (accumulator, drug) => accumulator + parseFloat(drug.patient_paid, 10),
          0
        );

        // calculate the total cost of the patient's EOBs
        const totalEOBPrice = getPatientEOB.reduce(
          (accumulator, eob) => accumulator + parseFloat(eob.payment_amount, 10),
          0
        );

        // set the state of the patient's current plan, total drugs price, total EOB price, and total cost
        setPatientCurrentPlanState(patientCurrentPlan);
        setTotalDrugsPriceState(totalDrugsPrice);
        setTotalEOBPriceState(totalEOBPrice);
        setTotalCost(totalDrugsPrice + totalEOBPrice);
      }
    })();
  }, []);

  if (!patientCurrentPlanState || Object.keys(patientCurrentPlanState).length === 0) {
    return null;
  }

  return (
    <div className="my-2 ring-1 ring-whiteAccentColor rounded-md bg-thirdAccentColorHighlight p-4">
      <div className="text-center mb-2">
        <h3 className="m-0 text-3xl">Your Current Plan:</h3>
        <h4 className="m-0 text-2xl text-lightGrayColor">
          {patientCurrentPlanState.marketing_name} - {patientCurrentPlanState.plan_year}
        </h4>
        <p className="m-0 text-xl text-lightGrayColor">{patientCurrentPlanState.plan_name}</p>
      </div>

      <hr className="my-4" />

      <Table
        tableDivClass="universalTableDivClass"
        tableClass="universalTable currentPlanCardTableMobile"
        headers={[
          <div className="flex justify-start items-center m-0" key="currentPlanMedicalCost">
            <HiOutlineChartSquareBar /> &nbsp;
            <p className="m-0 text-xl">Medical Cost</p>
          </div>,
          <div className="flex justify-start items-center m-0" key="currentDrugCost">
            <MdLocalPharmacy /> &nbsp;
            <p className="m-0 text-xl">Rx Cost</p>
          </div>,
          <div className="flex justify-start items-center m-0" key="currentDrugCost">
            <p className="m-0 text-xl">Total Cost (Excluding Premium)</p>
          </div>
        ]}
        data={[
          [
            <p className="text-xl m-0" key="currentPlanEOBCost">
              ${totalEOBPriceState.toFixed(2)}
            </p>,
            <p className="text-xl m-0" key="currentPlanDrugsCost">
              ${totalDrugsPriceState.toFixed(2)}
            </p>,
            <p className="text-dangerColor text-xl m-0 font-bold" key="currentPlanTotalCost">
              ${totalCost.toFixed(2)}
            </p>
          ]
        ]}
      />
    </div>
  );
}
