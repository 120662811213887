/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from "react";
import { IoMdLock } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import toast from "react-hot-toast";
import HCDLLogo from "../../assets/logo.png";
import Auth from "../../utils/auth";
import Input from "../input/Input";
import Button from "../button/Button";

export default function PassRecovPassUpdateForm({
  setLoginModalState,
  closeButtonClicked,
  email,
  tempToken
}) {
  const [showSpinner, setShowSpinner] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorText, setErrorText] = useState("");

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // begin spinner
    setShowSpinner(true);
    setErrorText("");

    // attempt a request to server to send password recovery email
    try {
      const response = await Auth.validation(
        { password, confirmPassword, email, tempToken },
        "forgotPasswordUpdate",
        { setErrorText }
      );

      // if it is a success, or failure, we will display a toast
      // if it is a validation failure, the auth service will handle it
      if (response === "success") {
        toast.success("Password Updated!");
        setLoginModalState("login");
      } else if (response === "failed") {
        setErrorText("There was an issue with your request, please try again later.");
      }
    } catch (err) {
      console.error(err);
      setErrorText("There was an issue with your request, please try again later.");
    }

    // end spinner and reset modal
    setShowSpinner(false);
  };

  return (
    <form
      className="pt-[2.5rem] pb-[1.5rem] m-0 px-[2rem] sm:px-[2rem] md:px-[5rem]"
      onSubmit={(e) => handleFormSubmit(e)}>
      <div className="text-right text-3xl">
        <IoClose className="cursor-pointer" onClick={closeButtonClicked} />
      </div>
      <div className="flex justify-center items-center mt-0 flex-col">
        <div className="flex justify-center items-center">
          <img className="w-[4rem] me-5" alt="HCDL logo" src={HCDLLogo} />
          <h2 className="text-[1.5rem] sm:text-[2rem]">Healthcare Download</h2>
        </div>
        <p className="mt-1">Enter New Password</p>
      </div>
      <Input
        label="Password"
        value={password}
        setValue={setPassword}
        type="password"
        placeholder="Enter your new password"
        inlineStartIcon={IoMdLock}
        wrapperClasses="mt-2"
        passwordRevealToggle
        backgroundColor="var(--medium-highlight-color:)"
        required
        inputClasses="text-base"
      />
      <Input
        label="Confirm Password"
        value={confirmPassword}
        setValue={setConfirmPassword}
        type="password"
        placeholder="*********"
        wrapperClasses="mt-8"
        passwordRevealToggle
        inlineStartIcon={IoMdLock}
        backgroundColor="var(--medium-highlight-color:)"
        required
        inputClasses="text-base"
      />
      <p className="text-dangerColor text-base mt-2 mb-0">{errorText}</p>
      {showSpinner ? (
        <>
          <div className="flex justify-center min-w-full">
            <div className="spinner" />
          </div>
        </>
      ) : (
        <>
          <Button fullWidth className="mt-5" type="submit">
            Update Password
          </Button>
          <div className="flex justify-center items-center mt-1">
            <p className="text-base">
              <span
                className="text-primary cursor-pointer underline text-center"
                onClick={() => {
                  setLoginModalState("login");
                }}>
                Return to login
              </span>
            </p>
          </div>
        </>
      )}
    </form>
  );
}
