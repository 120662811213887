import React, { Fragment, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

// Dropdown Prop Chart:
// prop name           | default value | is required | permission required to update
// ------------------------------------------------------------------------------------
// open                | -             | true        | -
// setOpen             | -             | true        | -
// button              | -             | true        | -
// menuItems           | -             | true        | -
// buttonWrapperStyles | -             | false       | -

export default function Dropdown({ open, setOpen, button, menuItems, buttonWrapperStyles }) {
  useEffect(() => {
    // this is a way to check if the button and menuItems props exist
    if (!button || !menuItems || open === null || open === undefined || !setOpen) {
      throw new Error("Dropdown component requires open, setOpen, button, menuItems props.");
    }

    // this is a way to check if the menuItems prop is an array
    if (!Array.isArray(menuItems)) {
      throw new Error("Dropdown menuItems prop must be an array.");
    }
  }, []);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div
      onClick={() => {
        setOpen(!open);
      }}
      className={classNames("relative", buttonWrapperStyles)}>
      <div>{button}</div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        show={open}>
        <div className="absolute left-[-.5rem] top-[3.75rem] z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <div>
              {menuItems.map((item, index) => (
                <Link
                  onClick={() => {
                    setOpen(false);
                    item.onClick();
                  }}
                  key={item.label + index}
                  to={item.to}
                  className={classNames(
                    "hover:bg-lightGray hover:text-blackTextColor text-darkGrayColor",
                    "block px-4 py-2 text-sm"
                  )}>
                  {item.label}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}
