import React from "react";
import { Link } from "react-router-dom";
import { FaUserPlus, FaLink, FaClipboardList } from "react-icons/fa";
import Button from "../../components/button/Button";
import "./home.css";

// list of steps to render to our section
const features = [
  {
    name: "Create an account",
    description:
      "Sign up easily and securely. Your personalized account is the key to unlocking a wealth of health insights tailored just for you.",
    icon: <FaUserPlus />
  },
  {
    name: "Link your insurer",
    description:
      "Connect with your insurance provider effortlessly. We ensure a seamless link to consolidate your healthcare information in one secure place.",
    icon: <FaLink />
  },
  {
    name: "Find healthcare data insights",
    description:
      "Dive into a comprehensive view of your health journey. Access and analyze your medical records, prescriptions, payments, and more—all in one convenient location.",
    icon: <FaClipboardList />
  }
];

export default function HowItWorks() {
  return (
    <div className="darkHomeSection">
      <div className="homeTitleCenter">
        <h4>How it works</h4>
        <h2>Access your healthcare data with just a few clicks.</h2>
        <br />
        <h4>
          You are just a couple clicks away from gaining access to the healthcare data that big
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          insurance companies have. It's your data - you should see it too.
        </h4>
      </div>
      <br />
      <div className="homeDarkFlex">
        {features.map((feature, index) => (
          <div key={`the process, step #${index}`} className="homeDarkCardClear">
            <h5>
              <span className="homeDarkIcon">{feature.icon}</span> &nbsp; {feature.name}
            </h5>
            <br />
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
      <br />
      <div className="ctaButtonLight">
        <Link to="/register">
          <Button invertedTheme className="text-2xl/[1.75rem]">
            Get Started
          </Button>
        </Link>
      </div>
    </div>
  );
}
