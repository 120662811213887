/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { RxCaretDown, RxCaretUp, RxHamburgerMenu } from "react-icons/rx";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/logo.png";
import ReactGATracking from "../../clients/googleAnalyticsClient";
import Mixpanel from "../../clients/mixPanelClient";
import ReactPixelInitialize from "../../clients/pixelClient";
import Auth from "../../utils/auth";
import Button from "../button/Button";
import Dropdown from "../dropdown/dropdown";
import LoginModal from "../loginModal/loginModal";
import "./Header.css";

// our header component is a navbar with the logo
export default function Header() {
  // used for determining which nav item is active based on the current path on page load
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();

  // determines which nav item is active based on the current path
  const determinePath = (currentPathParam) => {
    switch (currentPathParam) {
      case "/":
        return "home";
      case "/about":
        return "about";
      case "/curesAct":
        return "cures act";
      case "/contact":
        return "contact";
      case "/register":
        return "register";
      case "/app/dashboard":
        return "dashboard";
      default:
        return null;
    }
  };

  const [activeNav, setActiveNav] = useState(determinePath(currentPath));
  const [loginModal, setLoginModal] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // updates active nav item when the path changes via the url, link click, or back/forward button
  useEffect(() => {
    const newPath = determinePath(currentPath);
    setActiveNav(newPath);
  }, [currentPath]);

  // determines if nav item is active and returns the appropriate class
  // nav2 is used for the dropdown, so that both links in dropdown can be evaluated to make the dropdown active
  const isActive = (nav, nav2) =>
    nav === activeNav || nav2 === activeNav ? "headerLinkActive" : "headerLinkInactive";

  // determines if mobile nav item is active and returns the appropriate class
  const isActiveMobile = (nav) =>
    nav === activeNav ? "headerLinkMobileActive" : "headerLinkMobileInactive";

  // state updates when nav item is clicked
  const navLinkClicked = () => {
    setAboutOpen(false);
  };

  // state updates when mobile nav item is clicked
  const mobileNavLinkClicked = () => {
    setIsMobileMenuOpen(false);
  };

  // state updates when logo is clicked
  const logoClicked = () => {
    navigate("/");
    setActiveNav("home");
  };

  // mobile menu items
  const mobileMenuItems = [
    { label: "Home", to: "/", navType: "home" },
    { label: "About Us", to: "/about", navType: "about" },
    { label: "Cures Act", to: "/curesAct", navType: "cures act" },
    { label: "Contact", to: "/contact", navType: "contact" }
  ];

  // base styles for navbar links (desktop)
  const navbarLinkBaseStyle = "inline-flex items-center px-1 pt-1 pb-1 text-[1.1rem] font-medium";

  return (
    <>
      {/* Desktop menu */}
      <div className="bg-white fixed z-[9000] min-w-full shadow-sm top-0">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 justify-between">
            <div className="flex">
              <div className="flex flex-shrink-0 items-center">
                <img
                  onClick={logoClicked}
                  className="h-12 w-auto cursor-pointer pt-1"
                  src={Logo}
                  alt="HCDL Logo"
                />
              </div>
              <div className="hidden sm:ml-10 sm:flex sm:space-x-8">
                <Link
                  to="/"
                  onClick={() => navLinkClicked()}
                  className={`${navbarLinkBaseStyle}
                    ${isActive("home")}
                  `}>
                  Home
                </Link>
                <Dropdown
                  open={aboutOpen}
                  setOpen={setAboutOpen}
                  button={
                    <div
                      className="flex justify-center items-center select-none"
                      onClick={() => {
                        setAboutOpen(!aboutOpen);
                      }}>
                      About{" "}
                      {aboutOpen ? (
                        <RxCaretUp size={20} className="ml-1" />
                      ) : (
                        <RxCaretDown size={20} className="ml-1" />
                      )}
                    </div>
                  }
                  buttonWrapperStyles={`cursor-pointer ${navbarLinkBaseStyle} ${isActive(
                    "about",
                    "cures act"
                  )}`}
                  menuItems={[
                    {
                      label: "About Us",
                      to: "/about",
                      onClick: () => {
                        navLinkClicked();
                        Mixpanel("About Page");
                        ReactGATracking("Page View", "About Page");
                        ReactPixelInitialize("About Page", { value: "none" });
                      }
                    },
                    {
                      label: "Cures Act",
                      to: "/curesAct",
                      onClick: () => navLinkClicked()
                    }
                  ]}
                />
                <Link
                  to="/contact"
                  onClick={() => {
                    navLinkClicked();
                    Mixpanel("Contact Page");
                    ReactGATracking("Page View", "Contact");
                    ReactPixelInitialize("Contact Page", { value: "none" });
                  }}
                  className={`${navbarLinkBaseStyle}
                    ${isActive("contact")}
                  `}>
                  Contact
                </Link>
                <a
                  href="https://healthcaredownload.blog"
                  className={`${navbarLinkBaseStyle}
                    ${isActive("blog")}
                  `}>
                  Blog
                </a>
                {Auth.loggedIn() && (
                  <Link
                    to="/app/dashboard"
                    onClick={() => navLinkClicked()}
                    className={`${navbarLinkBaseStyle}
                      ${isActive("dashboard")}
                    `}>
                    Dashboard
                  </Link>
                )}
              </div>
            </div>
            <div className="flex">
              {!Auth.loggedIn() && (
                <div className="flex">
                  <div className="hidden sm:ml-10 sm:flex sm:space-x-2">
                    <Link
                      to="register"
                      onClick={() => navLinkClicked("register")}
                      className={`inline-flex items-center px-1 pt-1 mr-4 pb-1 text-[1.1rem] font-medium
                          ${isActive("register")}
                        `}>
                      Register
                    </Link>
                  </div>
                </div>
              )}
              <div className="flex items-center">
                <div className="hidden sm:flex sm:space-x-2">
                  {Auth.loggedIn() ? (
                    <Button
                      className="text-base"
                      onClick={() => {
                        Mixpanel("Logout");
                        ReactGATracking("Button Click", "Logout");
                        ReactPixelInitialize("Logout", { value: "none" });
                        Auth.logoutUser();
                      }}>
                      Logout
                    </Button>
                  ) : (
                    <Button className="text-base" onClick={() => setLoginModal(true)}>
                      Login
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="-mr-2 flex items-center sm:hidden">
              {/* Mobile menu button */}
              <div
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="relative inline-flex items-center justify-center rounded-md p-2 text-mediumGrayTextColor hover:bg-mediumHighlightColor hover:text-darkGrayTextColor focus:outline-none focus:ring-2 focus:ring-inset focus:ring-thirdAccentColor">
                <span className="sr-only">Open main menu</span>
                {isMobileMenuOpen ? (
                  <IoClose className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <RxHamburgerMenu className="block h-6 w-6" aria-hidden="true" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile menu, show/hide based on mobile menu state. */}
      <Transition
        as={Fragment}
        enter="transition ease-out duration-300 transform"
        enterFrom="opacity-0 translate-y-[-2rem] scale-100"
        enterTo="opacity-100 translate-y-0 scale-100"
        leave="transition ease-in duration-150 transform"
        leaveFrom="opacity-100 translate-y-0 scale-100"
        leaveTo="opacity-0 translate-y-[-2rem] scale-100"
        show={isMobileMenuOpen}>
        <div className="sm:hidden fixed bg-white shadow-md z-[8000] min-w-full top-[4rem]">
          <div className="space-y-1 pb-3 pt-2">
            {/* Mobile menu items */}
            {mobileMenuItems.map((item, index) => (
              <Link
                key={index + item.label}
                to={item.to}
                className={`${isActiveMobile(item.navType)}`}
                onClick={() => mobileNavLinkClicked(item.navType)}>
                {item.label}
              </Link>
            ))}
            <a href="https://healthcaredownload.blog" className="headerLinkMobileInactive">
              Blog
            </a>
            {Auth.loggedIn() && (
              <Link
                to="/app/dashboard"
                className={`${isActiveMobile("dashboard")}`}
                onClick={() => mobileNavLinkClicked()}>
                Dashboard
              </Link>
            )}
          </div>
          <hr className="border-gray-50/40 mx-3" />
          <div className="border-t border-whiteAccentColor pb-6">
            <div className="mt-3 space-y-1">
              {/* Mobile menu items for user profile */}
              {Auth.loggedIn() ? (
                <Button
                  onClick={() => {
                    Mixpanel("Logout");
                    ReactGATracking("Button Click", "Logout");
                    ReactPixelInitialize("Logout", { value: "none" });
                    Auth.logoutUser();
                  }}
                  className="ml-2">
                  Logout
                </Button>
              ) : (
                <>
                  <Link
                    to="/register"
                    className={`${isActiveMobile("register")}`}
                    onClick={() => mobileNavLinkClicked()}>
                    Register
                  </Link>
                  <Button onClick={() => setLoginModal(true)} className="ml-2">
                    Login
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </Transition>
      <LoginModal loginModal={loginModal} setLoginModal={setLoginModal} />
    </>
  );
}
