/* eslint-disable jsx-a11y/no-redundant-roles */
import { React, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import Logo from "../../assets/logo.png";

// object containing menu items if it is linking to external page, it is to be "href", if linking internally it is to be "to"
const navigation = {
  Solutions: [
    { name: "Healthcare Data", to: "/app/dashboard" },
    { name: "Analytics", to: "/app/dashboard" },
    { name: "Rx Compare", to: "/app/dashboard" },
    { name: "Insights", to: "/app/dashboard" }
  ],
  Support: [
    { name: "Contact Us", to: "/contact" },
    { name: "info@healthcare-download.com", href: "mailto:info@healthcare-download.com" },
    { name: "Call our broker at" },
    { name: "+1 (442) 271-7998" }
  ],
  Company: [
    { name: "About Us", to: "/about" },
    { name: "Cures Act", to: "/curesAct" },
    { name: "Blog", href: "https://healthcaredownload.blog/" }
  ],
  Legal: [
    { name: "Privacy Policy", to: "/privacyPolicy" },
    {
      name: "Terms of Service",
      to: "/termsOfService"
    }
  ],
  Social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/profile.php?id=61552550189105",
      icon: <FaFacebook />
    },
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/hcdl-inc/",
      icon: <FaLinkedin />
    }
  ]
};

// component to make footer links from above navigation object
function FooterLink({ item }) {
  if (item.to) {
    return (
      <Link
        to={item.to}
        className="text-base leading-6 text-darkGrayColor hover:text-blackTextColor">
        {item.name}
      </Link>
    );
  }
  return (
    <a
      href={item.href}
      className="text-base leading-6 text-darkGrayColor hover:text-blackTextColor"
      target="_blank"
      rel="noopener noreferrer">
      {item.name}
    </a>
  );
}

// One section component for the lists i.e. Solutions, Support, Company, Legal
function FooterSection({ title, items }) {
  return (
    <div>
      <h3 className="text-xl font-semibold leading-6 text-mainTextColor">{title}</h3>
      <ul role="list" className="mt-6 space-y-4 list-none p-0">
        {items.map((item) => (
          <li key={item.name}>
            <FooterLink item={item} className="text-base leading-6 text-darkGrayColor" />
          </li>
        ))}
      </ul>
    </div>
  );
}

// Footer component JSX
export default function Footer() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <footer
      className={`bg-secondaryAccentColor absolute ${
        location.pathname === "/app/dashboard"
          ? "w-[calc(100%-5rem)] pl-[5rem] lg:w-[calc(100%-15rem)] lg:pl-[15rem] 3xl:w-full 3xl:pl-0"
          : "w-full"
      }`}
      aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-24">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8">
            <img className="h-7" src={Logo} width={30} height={30} alt="Company name" />
            <p className="text-base leading-6 text-darkGrayColor">
              Enriching people&apos;s lives through better healthcare accessibility.
            </p>
            <div className="flex space-x-6">
              {navigation.Social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  className="text-mainTextColor hover:text-grayHighlightColor">
                  <span className="sr-only">{item.name}</span>
                  {item.icon}
                </a>
              ))}
            </div>
          </div>
          {/* This container houses the sections, if we need more sections, adjust cols as necessary */}
          <div className="mt-16 grid grid-cols-2 md:grid-cols-4 gap-8 xl:col-span-2 xl:mt-0">
            {/* for each object, map through that key where it isn't social (social just houses the icons, they don't need their own text section) and render a section based on that */}
            {Object.keys(navigation)
              .filter((section) => section !== "Social")
              .map((section) => (
                <FooterSection key={section} title={section} items={navigation[section]} />
              ))}
          </div>
        </div>
        <hr className="mt-5 border-gray-50/40" />
        <div className="border-t border-gray-900/10 pt-8">
          <p className="text-base leading-5 text-mediumGrayColor">
            &copy; 2022 HCDL Inc. All rights reserved, Los Angeles, CA.
          </p>
        </div>
      </div>
    </footer>
  );
}
