import React, { useEffect, useState } from "react";
import { FaClinicMedical } from "react-icons/fa";
import toast from "react-hot-toast";
import { Navigate } from "react-router-dom";
import DashboardHeader from "../../components/dashboardHeader/DashboardHeader";
import ClinicalTrials from "../../components/dashboardExhibit/clinicalTrials";
import Auth from "../../utils/auth";

export default function ClinicalTrialsDetails() {
  const [fetchStatus, setFetchStatus] = useState("pending");

  useEffect(() => {
    if (fetchStatus.clinicalTrials === "failed") {
      toast.error("There was an error retrieving some of your data. Please try again later.");
    }
  }, [fetchStatus]);

  if (!Auth.loggedIn()) {
    return <Navigate to="/" />;
  }
  return (
    <div className="min-h-screen">
      <DashboardHeader title="Clinical Trials" icon={<FaClinicMedical className="sm:pr-6" />}>
        <p className="text-grayColor">
          Below is a full view of clinical trials you may be eligible for.
        </p>
      </DashboardHeader>
      <ClinicalTrials setFetchStatus={setFetchStatus} dashboardView={false} />
    </div>
  );
}
