import React, { useState } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import About from "./pages/about/about";
import ClinicalTrialsMore from "./pages/clinicalTrialsMore/clinicalTrialsMore";
import Contact from "./pages/contact/contact";
import CuresAct from "./pages/curesAct/curesAct";
import Dashboard from "./pages/dashboard/dashboard";
import DeleteAccount from "./pages/deleteAccount/deleteAccount";
import Home from "./pages/home/home";
import InsuranceSelect from "./pages/insuranceSelect/insuranceSelect";
import Intermediary from "./pages/intermediary/intermediary";
import IntermediaryExternalEnrollment from "./pages/intermediaryExternalEnrollment/intermediaryExternalEnrollment";
import PlanDetails from "./pages/planDetails/planDetails";
import PrivacyPolicy from "./pages/privacyPolicy/privacyPolicy";
import Register from "./pages/Register/register";
import TermsOfService from "./pages/termsOfService/termsOfService";
import UserProfile from "./pages/userProfile/userProfile";
// add an object with card title, card body text, card button link or navigation
// add a function to create cards iteratively
import ClinicalTrialsDetails from "./pages/clinicalTrials/clinicalTrialsDetails";
import CookieUsageNotice from "./pages/cookies/cookieUsageNotice";
import HealthHistoryDetails from "./pages/healthHistory/healthHistoryDetails";
import MedicareOptionsDetails from "./pages/medicareOptions/medicareOptionsDetails";
import RxHistoryDetails from "./pages/rxHistory/rxHistoryDetails";
import RxInteractionsDetails from "./pages/rxInteractions/rxInteractionsDetails";

export default function App() {
  const [cookieConsentOpen, setCookieConsentOpen] = useState(false);

  const handleCookieConsentToggle = () => {
    setCookieConsentOpen(!cookieConsentOpen);
  };

  // upon clicking banner button, set cookie to the button's value
  const changeCookie = (_, type) => {
    document.cookie = `consentCookie=${type}; path=/`;
    setCookieConsentOpen(false);
  };

  // Check if protocol is HTTP, and we're in production (dev server still counts as production)
  if (window.location.protocol === "http:" && process.env.NODE_ENV === "production") {
    // Redirect to HTTPS version
    window.location.href = "https://" + window.location.host + window.location.pathname;
    return null; // Return null to prevent rendering anything
  }
  return (
    <BrowserRouter>
      <Toaster position="top-right" />
      <Header />
      <CookieUsageNotice
        cookieConsentOpen={cookieConsentOpen}
        handleCookieConsentToggle={handleCookieConsentToggle}
        changeCookie={changeCookie}
      />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/clinicalTrials" element={<ClinicalTrialsDetails />} />
        <Route path="/clinicalTrialsMore" element={<ClinicalTrialsMore />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/app/dashboard" element={<Dashboard />} />
        <Route path="/insuranceSelect" element={<InsuranceSelect />} />
        <Route path="/register" element={<Register />} />
        <Route path="/intermediary/:insurerPathParam" element={<Intermediary />} />
        <Route path="/authenticateReferral" element={<IntermediaryExternalEnrollment />} />
        <Route path="/deleteAccount" element={<DeleteAccount />} />
        <Route path="/curesAct" element={<CuresAct />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/termsOfService" element={<TermsOfService />} />
        <Route path="/planDetails" element={<PlanDetails />} />
        <Route path="/userProfile" element={<UserProfile />} />
        <Route path="/rxInteractions" element={<RxInteractionsDetails />} />
        <Route path="/medicareOptions" element={<MedicareOptionsDetails />} />
        <Route path="/healthHistory" element={<HealthHistoryDetails />} />
        <Route path="/rxHistory" element={<RxHistoryDetails />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
