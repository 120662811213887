import React from "react";
import termsOfServiceObject from "./termsOfServiceObject";
import { scrollToSection } from "../../utils/scrollHelper";

export default function TermsOfService() {
  const mapTableOfContents = (item, index) => (
    <li key={item} className="mb-2">
      <span
        className="underline text-thirdAccentColor cursor-pointer"
        onClick={() => scrollToSection(index + 1 + ".-" + item.split(" ").join("-"))}>
        {item}
      </span>
    </li>
  );

  const mapListItems = (listItem, listItemIndex) => (
    <li className="mb-2" key={listItemIndex + listItem.substring(0, 10)}>
      {listItem}
    </li>
  );

  const mapSectionContent = (contentItem, contentIndex) => {
    switch (contentItem.type) {
      case "paragraph":
        return <p key={contentIndex + contentItem.text.substring(0, 10)}>{contentItem.text}</p>;
      case "subtitle":
        return <h3 key={contentIndex + contentItem.text.substring(0, 10)}>{contentItem.text}</h3>;
      case "unorderedList":
        return (
          <ul key={contentIndex + "orderedList"}>{contentItem.listItems.map(mapListItems)}</ul>
        );
      default:
        return null;
    }
  };

  const mapSections = (section, index) => (
    <div key={section.title + index} className="mb-16">
      <h2 id={section.title.split(" ").join("-")}>{section.title}</h2>
      {section.content.map(mapSectionContent)}
    </div>
  );

  return (
    <div className="p-32">
      {/* Title */}
      <div>
        <h1>{termsOfServiceObject.title}</h1>
        <p className="grayText">{termsOfServiceObject.lastUpdated}</p>
        <p className="grayText">{termsOfServiceObject.effectiveDate}</p>
      </div>

      {/* Table of Contents */}
      <div>
        <h2>{termsOfServiceObject.tableOfContentsHeader}</h2>
        <ol>{termsOfServiceObject.tableOfContentsOL.map(mapTableOfContents)}</ol>
      </div>

      {/* Main Content */}
      {termsOfServiceObject.sections.map(mapSections)}

      {/* Contact Footer */}
      <p className="my-0 text-darkGrayColor font-bold">{termsOfServiceObject.footer.company}</p>
      <p className="my-0 text-darkGrayColor">{termsOfServiceObject.footer.address}</p>
      <p className="my-0 text-darkGrayColor">{termsOfServiceObject.footer.cityStateZip}</p>
      <p className="my-0 text-darkGrayColor">{termsOfServiceObject.footer.country}</p>
      <p className="my-0 text-darkGrayColor">{termsOfServiceObject.footer.phone}</p>
      <p className="text-center text-base">Copyright © 2022 HCDL inc</p>
    </div>
  );
}
