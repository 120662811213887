import React from "react";
import { IoClose } from "react-icons/io5";
import { FiLink } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/button/Button";
import Modal from "../../components/modal/Modal";

export default function DashboardToInsuranceSelectModal({
  directUserToInsuranceSelect,
  setDirectUserToInsuranceSelect,
  closeButtonClicked
}) {
  const navigate = useNavigate();

  const navigateToInsuranceSelect = () => {
    navigate("/insuranceSelect");
  };
  return (
    <Modal
      open={directUserToInsuranceSelect}
      setOpen={setDirectUserToInsuranceSelect}
      withCloseButton={false}
      minHeight="0"
      padding="0"
      width="42rem">
      <div>
        <div className="text-right text-3xl pt-2 pr-2">
          <IoClose className="cursor-pointer" onClick={closeButtonClicked} />
        </div>
        <div className="p-8 sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-dangerOpacityColor sm:mx-0 sm:h-12 sm:w-12">
            <FiLink className="h-8 w-8 text-dangerColor" aria-hidden="true" />
          </div>
          <div className="pt-2 text-center sm:ml-4 sm:text-left">
            <h3 className="m-0 sm:text-4xl font-semibold leading-6 text-gray-900">
              Link your health insurance
            </h3>
            <div className="mt-2">
              <p className="sm:text-2xl">
                To see your personalized results
                <Link to="/insuranceSelect" className="sm:text-2xl">
                  {" "}
                  link your insurance.
                </Link>
              </p>
              <p className="sm:text-2xl">Or speak directly with us at +1 (442) 271-7998</p>
            </div>
          </div>
        </div>
      </div>
      <div className="p-3 flex justify-end bg-thirdAccentColor">
        <Button
          invertedTheme
          className="mr-3 sm:text-lg"
          type="button"
          onClick={() => setDirectUserToInsuranceSelect(false)}>
          Close
        </Button>
        <Button
          invertedTheme
          className="mr-3 sm:text-lg"
          type="button"
          onClick={navigateToInsuranceSelect}>
          Continue
        </Button>
      </div>
    </Modal>
  );
}
