// eslint-disable-next-line import/prefer-default-export
export function makeFirstLetterCapital(string) {
  const splitString = string.split(" ");
  const capitalizedString = splitString.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );
  return capitalizedString.join(" ");
}

// makes sentences regular from all caps go from "THIS. SENTENCE" to "This. Sentence"
export function formatAllCapsSentences(words) {
  const string = words
    .split(". ")
    .map((word) => word.charAt(0).toUpperCase() + words.substring(1).toLowerCase())
    .join(". ");
  return string;
}

// Renders a neat rendering provider name given an array of common titles
export function renderingProviderCase(words) {
  // List of common titles and abbreviations that should always be capitalized
  const commonTitles = [
    "ph.d.",
    "phd",
    "m.d.",
    "md",
    "md.",
    "m.d",
    "pa.",
    "pa",
    "rn.",
    "esq.",
    "pharmd."
  ];

  const text = words
    .toLowerCase()
    .split(" ")
    .map((word) => {
      if (commonTitles.includes(word)) {
        // Check if the word is a common title or abbreviation
        // Capitalize the entire word
        return word.toUpperCase();
      }
      // Capitalize the first letter and convert the rest to lowercase
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
  return text;
}
