import React, { useState, useEffect } from "react";
import { HiOutlineChartSquareBar } from "react-icons/hi";
import toast from "react-hot-toast";
import { Navigate } from "react-router-dom";
import Auth from "../../utils/auth";
import DashboardHeader from "../../components/dashboardHeader/DashboardHeader";
import HealthHistory from "../../components/dashboardExhibit/healthHistory";

export default function HealthHistoryDetails() {
  const [fetchStatus, setFetchStatus] = useState("pending");

  useEffect(() => {
    if (fetchStatus.healthHistory === "failed") {
      toast.error("There was an error retrieving some of your data. Please try again later.");
    }
  }, [fetchStatus]);

  if (!Auth.loggedIn()) {
    return <Navigate to="/" />;
  }

  return (
    <div className="min-h-screen">
      <DashboardHeader
        icon={<HiOutlineChartSquareBar className="sm:pr-6" />}
        title="Health History">
        <p className="text-grayColor">Below is a full view of your Health History.</p>
      </DashboardHeader>
      <HealthHistory setFetchStatus={setFetchStatus} />
    </div>
  );
}
