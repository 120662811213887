import React from "react";
import { LiaCookieSolid } from "react-icons/lia";
import Button from "../../components/button/Button";
import Card from "../../components/cards/card";

// creates each button in cookie disclaimer
function CookieButton({ name, onClick }) {
  return (
    <Button key={name} className="mx-1" onClick={onClick} type="button">
      {name}
    </Button>
  );
}

export default function CookieUsageNotice({
  cookieConsentOpen,
  handleCookieConsentToggle,
  changeCookie
}) {
  const linkToPrivacyPolicy = (
    <a className="text-base" href="/privacyPolicy">
      Privacy Policy
    </a>
  );

  // array of cookie button options
  const cookieButtons = [
    {
      name: "Accept all",
      onClick: (e) => changeCookie(e, "acceptAll")
    },
    {
      name: "Reject all",
      onClick: (e) => changeCookie(e, "rejectAll")
    },
    {
      name: "Close",
      onClick: handleCookieConsentToggle
    }
  ];
  return (
    <div>
      {cookieConsentOpen ? (
        <div className="fixed z-[9999] left-0 right-0 bottom-0 p-2 my-2 mx-auto max-w-[50rem] w-[calc(100%-5rem)]">
          <div>
            <Card className="ring-mainTextColor  m-0 p-0">
              <h6 className="flex items-center text-mainTextColor text-sm lg:text-base font-semibold m-0">
                <LiaCookieSolid className="text-base lg:text-3xl pr-2" /> This website uses cookies
              </h6>
              <p className="text-sm lg:text-base text-mainTextColor">
                Our website uses cookies to enhance your browsing experience. We collect some
                identifiers and electronic network activity information. This does not include your
                health information which is stored in our secure database. We do not, nor ever plan
                to share or sell any of your personal data. Please review our {linkToPrivacyPolicy}.
              </p>
              <div className="flex justify-end">
                {/* map through cookie buttons array and use CookieButton component to render buttons */}
                {cookieButtons.map((cookie) => (
                  <CookieButton key={cookie.name} name={cookie.name} onClick={cookie.onClick} />
                ))}
              </div>
            </Card>
          </div>
        </div>
      ) : (
        <Button
          type="button"
          theme="noStyle"
          onClick={handleCookieConsentToggle}
          className=" bg-none fixed z-[9999] bottom-5 right-5 text-base">
          <LiaCookieSolid /> Cookies
        </Button>
      )}
    </div>
  );
}
