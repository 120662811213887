import React from "react";

export default function TooltipLogic({ text, size }) {
  let positionClass = "top-0";
  if (size === "small") {
    positionClass = "bottom-9 inset-x-44";
  } else if (size === "medium") {
    positionClass = "bottom-9 right-60";
  } else if (size === "large") {
    positionClass = "bottom-9 right-72";
  } else if (size === "extra-large") {
    positionClass = "bottom-9 right-80";
  } else if (size === "beneathInline") {
    positionClass = "top-5 left-0";
  }

  return (
    <div className={`tooltipContainer relative inline-block ${positionClass}`}>
      <div className="max-w-48 tooltipStyling absolute bg-thirdAccentColor text-white !important py-2 px-2 rounded-md z-10 w-80 whitespace-normal text-wrap font-normal text-base text-center transform -translate-x-1/2 ">
        {text}
      </div>
    </div>
  );
}
