import React from "react";
import CardImage from "../../../assets/pexels-anna-shvets-3727541.jpg";
import "./Card.css";

export default function AboutPageHeader() {
  // This in the intro for the meet the team page

  return (
    <div className="card-container">
      <div className="card-left">
        <div className="card-body">
          <p className="horizontal-image-text">
            We founded HCDL to make it easy for{" "}
            <strong>you to access and own your healthcare </strong>data currently{" "}
            <strong> passed between insurers and vendors </strong> via health plan APIs, and to{" "}
            <strong>provide analysis to reveal what&apos;s most valuable to you</strong>. Many of us
            have highly paid careers finding value in your data… for the benefit of big insurance
            companies. We would rather put our skills to work for everyday people like you now that
            the 21st Century Cures Act gives you a legal right to that data!
          </p>
        </div>
        <figure className="card-image">
          <img
            src={CardImage}
            className="horizontal-image"
            alt="Two people embrace affectionately while walking along a beach."
          />
        </figure>
      </div>
    </div>
  );
}
