import React from "react";
import privacyPolicyObject from "./privacyPolicyObject";
import "./privacyPolicy.css";

export default function PrivacyPolicyDisplay() {
  return (
    <div className="privacyPage">
      <div>
        <h1>{privacyPolicyObject.privacyTitle}</h1>
        <p className="grayText">{privacyPolicyObject.lastUpdated}</p>
        <p className="grayText">{privacyPolicyObject.effectiveDate}</p>
      </div>

      {/* Overview */}
      <div>
        <h2>{privacyPolicyObject.overviewTitle}</h2>
        <p>{privacyPolicyObject.overviewParagraph1}</p>
        <p>{privacyPolicyObject.overviewParagraph2}</p>
      </div>
      <hr />

      {/* Section 1 */}
      <h2>{privacyPolicyObject.sec1Title}</h2>

      {/* Information That You Give Us */}
      <div>
        <h3>{privacyPolicyObject.infoTitle}</h3>
        <p>{privacyPolicyObject.infoParagraph1}</p>
        <p>{privacyPolicyObject.infoParagraph2}</p>
      </div>

      {/* Our Website and Servers, Your Use of Browsers */}
      <div>
        <h3>{privacyPolicyObject.websiteTitle}</h3>
        <p>{privacyPolicyObject.websiteParagraph1}</p>
        <ul>
          <li>{privacyPolicyObject.websiteList1}</li>
          <li>{privacyPolicyObject.websiteList2}</li>
          <li>{privacyPolicyObject.websiteList3}</li>
          <li>{privacyPolicyObject.websiteList4}</li>
          <li>{privacyPolicyObject.websiteList5}</li>
        </ul>
        <p>{privacyPolicyObject.websiteParagraph2}</p>
      </div>

      {/* Do-not-track */}
      <div>
        <h3>{privacyPolicyObject.dntTitle}</h3>
        <p>{privacyPolicyObject.dntParagraph1}</p>
      </div>

      {/* Cookies */}
      <div>
        <h3>{privacyPolicyObject.cookiesTitle}</h3>
        <p>{privacyPolicyObject.cookiesParagraph1}</p>
        <ul>
          <li>{privacyPolicyObject.cookiesList1}</li>
          <li>{privacyPolicyObject.cookiesList2}</li>
          <li>{privacyPolicyObject.cookiesList3}</li>
          <li>{privacyPolicyObject.cookiesList4}</li>
          <li>{privacyPolicyObject.cookiesList5}</li>
        </ul>
        <p>{privacyPolicyObject.cookiesParagraph2}</p>
      </div>

      {/* How Do We Use Your Information? */}
      <div>
        <h3>{privacyPolicyObject.yourInfoTitle}</h3>
        <p>{privacyPolicyObject.yourInfoParagraph1}</p>
        <ul>
          <li>{privacyPolicyObject.yourInfoList1}</li>
          <li>{privacyPolicyObject.yourInfoList2}</li>
          <li>{privacyPolicyObject.yourInfoList3}</li>
          <li>{privacyPolicyObject.yourInfoList4}</li>
          <li>{privacyPolicyObject.yourInfoList5}</li>
        </ul>
        <p>{privacyPolicyObject.yourInfoParagraph2}</p>
      </div>

      {/* Who Has Access to Your Information? */}
      <div>
        <h3>{privacyPolicyObject.accessTitle}</h3>
        <p>{privacyPolicyObject.accessParagraph1}</p>
      </div>

      {/* How We Protect Your Information */}
      <hr />
      <div>
        <h2>{privacyPolicyObject.protectTitle}</h2>
        <p>{privacyPolicyObject.protectParagraph1}</p>
      </div>

      {/* How you control the sharing of your personal information and the limited circumstances in
which we may disclose it to others */}
      <div>
        <h3>{privacyPolicyObject.controlSharingTitle}</h3>
        <p>{privacyPolicyObject.controlSharingParagraph1}</p>
        <ul>
          <li>{privacyPolicyObject.controlSharingList1}</li>
          <li>{privacyPolicyObject.controlSharingList2}</li>
          <li>{privacyPolicyObject.controlSharingList3}</li>
        </ul>
        <p>{privacyPolicyObject.controlSharingParagraph2}</p>
      </div>

      {/* How Long Does HCDL Keep Your Information? */}
      <div>
        <h3>{privacyPolicyObject.keepTitle}</h3>
        <p>{privacyPolicyObject.keepParagraph1}</p>
      </div>

      {/* Ways in which you can further protect your personal information */}
      <div>
        <h3>{privacyPolicyObject.waysYouCanProtectTitle}</h3>
        <p>{privacyPolicyObject.waysYouCanProtectParagraph1}</p>
      </div>

      {/* Your California Privacy Rights */}
      <hr />
      <div>
        <h2>{privacyPolicyObject.privacyRightsTitle}</h2>
        <p>{privacyPolicyObject.privacyRightsParagraph1}</p>
      </div>

      {/* Contact HCDL */}
      <hr />
      <div>
        <h2>{privacyPolicyObject.contactTitle}</h2>
        <p>{privacyPolicyObject.contactParagraph1}</p>
      </div>
      <br />
      <p className="text-center text-base">Copyright © 2022 HCDL inc</p>
    </div>
  );
}
