export default async function exchangeCodeForToken(code) {
  const genericError = {
    error: true,
    message: "An error occurred redirecting you to Healthcare Download. Please try again later."
  };

  try {
    const response = await fetch(`/api/externalEnrollment/exchangeReferralCodeForToken/${code}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    });
    if (!response.ok) {
      return genericError;
    }

    const data = await response.json();

    if (data.message === "Referral code invalid, expired, or already used") {
      return {
        error: true,
        message: data.message
      };
    }
    if (data.token) {
      return data;
    }
    return genericError;
  } catch (error) {
    console.log(error.toString());
    return genericError;
  }
}
