import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ReactGATracking from "../../clients/googleAnalyticsClient";
import ReactPixelInitialize from "../../clients/pixelClient";
import Button from "../../components/button/Button";
import Input from "../../components/input/Input";
import auth from "../../utils/auth";

export default function RequestInsurerAdd() {
  const [email, setEmail] = useState("");
  const [insurerName, setInsurerName] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleRequestSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const JWTtoken = auth.getToken();
    try {
      const response = await fetch("/api/brokerForms/insuranceAddRequest", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JWTtoken
        },
        body: JSON.stringify({
          insurerName,
          email
        })
      });
      if (response.ok) {
        toast.success(response.message);
        ReactPixelInitialize("Request Add", { contents: [email, insurerName] });
        ReactGATracking("Form Submitted", "Request Insurer");
        navigate("/app/dashboard");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      toast.error("Your request could not be completed at this time. Please try again later.");
    }
    setLoading(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <h2>Cannot find what you are looking for?</h2>
      <p>Let us know which insurance company you would like to see added!</p>
      <form
        className="insuranceCompaniesAdded insurance-suggest-form"
        onSubmit={(e) => handleRequestSubmit(e)}>
        <Input
          type="text"
          label="Insurance Company"
          value={insurerName}
          setValue={setInsurerName}
          name="insurer-name"
          placeholder="Insurer Name"
          required
        />
        <Input
          type="email"
          label="Your Email"
          name="email"
          value={email}
          setValue={setEmail}
          placeholder="Your Email"
          required
        />
        {loading ? (
          <div className="spinner" />
        ) : (
          <Button fullWidth className="text-base mt-6" type="submit">
            Submit
          </Button>
        )}
      </form>
    </>
  );
}
