import React from "react";
import { FaClinicMedical } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/button/Button";
import { makeFirstLetterCapital } from "../../utils/dataFormatHelpers";

// renders a label and value pair
function LabelAndInfo({ label, value }) {
  return (
    <p className="text-darkGrayColor">
      <span className="text-darkShadow font-semibold">{label}: </span>
      {value}
    </p>
  );
}

export default function ClinicalTrialsMore() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // gather trial info from the query params
  const trialInfo = {};
  queryParams.forEach((value, key) => {
    trialInfo[key] = value;
  });

  const determineBgColor = (status) => {
    switch (status) {
      case "RECRUITING":
        return "bg-successColor";
      case "NOT_YET_RECRUITING":
        return "bg-thirdAccentColor";
      case "COMPLETED":
      case "TERMINATED":
      case "WITHDRAWN":
      case "ACTIVE_NOT_RECRUITING":
        return "bg-dangerColor";
      default:
        return "bg-thirdAccentColor";
    }
  };

  const refineStatusLabel = (status) => {
    const refinedStatus = status.split("_").join(" ");
    return makeFirstLetterCapital(refinedStatus);
  };

  return (
    <>
      <div className="pt-16 bg-mediumHighlightColor">
        <Button className="text-base ml-5 mt-5 mb-0" onClick={() => navigate(-1)}>
          Back
        </Button>
      </div>
      <div className="flex items-center justify-center pt-12 min-full-w bg-mediumHighlightColor">
        <div className="w-[90%] pb-12">
          <h2 className="mt-0 mb-4 text-center flex items-center justify-center">
            <FaClinicMedical className="text-thirdAccentColor" /> &nbsp;{trialInfo.organization}
          </h2>
          <div className="flex justify-center items-center mb-8">
            <Button
              className="mr-2 text-2xl"
              onClick={(e) => {
                e.preventDefault();
                window.open(`https://clinicaltrials.gov/study/${trialInfo.nctId}`, "_blank");
              }}>
              View Full Trial
            </Button>
          </div>
          <div className="border-solid border-2 border-grayHighlightColor p-20 rounded bg-whiteTextColor">
            <h3 className="mt-0">{trialInfo.officialTitle}</h3>
            <div
              className={`${determineBgColor(
                trialInfo.overallStatus
              )} m-0 rounded w-fit py-1 px-4`}>
              <p className="text-whiteTextColor font-bold m-0 text-center">
                {refineStatusLabel(trialInfo.overallStatus)}
              </p>
            </div>

            <hr className="my-7" />

            <h3 className="mb-1">Details</h3>

            <LabelAndInfo label="Brief" value={trialInfo.briefSummary} />
            <LabelAndInfo label="Start Date" value={trialInfo.startDate} />
            <LabelAndInfo label="Primary Completion Date" value={trialInfo.primaryCompletionDate} />
            <LabelAndInfo label="Last Updated" value={trialInfo.lastUpdatePostDate} />
            <LabelAndInfo label="Description" value={trialInfo.detailedDescription} />

            {trialInfo.phases && (
              <LabelAndInfo label="Phase(s)" value={trialInfo.phases.split(",").join(", ")} />
            )}

            <p className="text-darkShadow font-semibold mb-3">Conditions:</p>

            {trialInfo.conditions && trialInfo.conditions.length > 0 && (
              <div className="flex items-stretch flex-wrap">
                {trialInfo.conditions.split(",").map((condition, index) => (
                  <div
                    key={index + condition}
                    className="my-2 mr-4 bg-thirdAccentColor rounded w-full lg:w-fit py-1 px-4 flex items-center justify-center">
                    <p className="text-whiteTextColor font-bold m-0 text-center">{condition}</p>
                  </div>
                ))}
              </div>
            )}

            <hr className="my-9" />

            <h3 className="mb-1">Eligibility</h3>

            <LabelAndInfo label="Eligbility Criteria" value={trialInfo.eligibilityCriteria} />
            <LabelAndInfo label="Healthy Volunteers" value={trialInfo.healthyVolunteers} />
            <LabelAndInfo label="Sex" value={trialInfo.sex} />
            <LabelAndInfo label="Minimum Age" value={trialInfo.minimumAge} />

            {trialInfo.stdAges && (
              <LabelAndInfo label="Standard Ages" value={trialInfo.stdAges.split(",").join(", ")} />
            )}

            <hr className="my-9" />

            <h3 className="mb-1">Intervention</h3>

            <LabelAndInfo label="Intervention Type" value={trialInfo.interventionType} />
            <LabelAndInfo label="Intervention Name" value={trialInfo.interventionName} />
            <LabelAndInfo
              label="Intervention Description"
              value={trialInfo.interventionDescription}
            />
            <LabelAndInfo label="Primary Outcome Measure" value={trialInfo.primaryOutcomeMeasure} />
            <LabelAndInfo
              label="Primary Outcome Time Frame"
              value={trialInfo.primaryOutcomeTimeFrame}
            />

            <div className="flex justify-start items-center mt-10">
              <Button
                className="mr-2 text-2xl"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(`https://clinicaltrials.gov/study/${trialInfo.nctId}`, "_blank");
                }}>
                View Full Trial
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
