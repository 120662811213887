import React from "react";
import "./home.css";
import { Link } from "react-router-dom";
import Button from "../../components/button/Button";
import MedicareOptions from "../../assets/medicareOptions.png";

export default function Compare() {
  return (
    <div className="compareSection">
      <div className="compareImage">
        <img src={MedicareOptions} alt="chart of medicare options from our user dashboard" />
      </div>
      <div className="compareContent">
        <div>
          <h5 className="compareSubheader">Compare and save</h5>
          <h3>How do you decide which health plan is best for you?</h3>
          <br />
          <p>
            It is nice to have choices - but making them can be tricky, especially about health
            insurance. We bring together the details you need for side-by-side comparisons. From
            costs to specialists in your area, get the data you need today.
          </p>
        </div>
        <hr />
        <div>
          <h3>Our plan rank technology.</h3>
          <br />
          <p>
            We realize price is a big deal when shopping for insurance. Here at HCDL we make sure
            our users are aware of all their options. Not only will this save you time, it will save
            you money as well.
          </p>
          <br />
          <br />
          <div className="ctaButtonDark">
            <Link to="/register">
              <Button className="text-2xl/[1.75rem]">Get Started</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
