import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { CgPill } from "react-icons/cg";
import { FaCheckCircle } from "react-icons/fa";
import Modal from "../modal/Modal";
import { makeFirstLetterCapital } from "../../utils/dataFormatHelpers";
import formatMoneyUtil from "../../utils/formatMoney";
import RxLinkForm from "../forms/rxLinkForm";
import Button from "../button/Button";

export default function RxHistorySavingsModal({
  openModal,
  setOpenModal,
  closeButtonClicked,
  modalBreakdownInfo
}) {
  const [rxLinkPhone, setRxLinkPhone] = useState("");

  // conditionally render savings title in the modal, switch case would not work here for conditional logic evaluations
  const renderSavingsTitle = (modalBreakdownInfoData) => {
    if (!modalBreakdownInfoData.genericBP && !modalBreakdownInfoData.brandBP) {
      return (
        <span className="text-successColor font-semibold">
          You are already paying the best price!
        </span>
      );
    }
    if (modalBreakdownInfoData.patientPaid > modalBreakdownInfoData.genericBP) {
      return (
        <span className="text-dangerColor font-semibold">
          You could be overpaying by{" "}
          {formatMoneyUtil.formatMoney(modalBreakdownInfoData.genericSavings)}
        </span>
      );
    }
    if (modalBreakdownInfoData.patientPaid > modalBreakdownInfoData.brandBP) {
      return (
        <span className="text-dangerColor font-semibold">
          You could be overpaying by{" "}
          {formatMoneyUtil.formatMoney(modalBreakdownInfoData.brandSavings)}
        </span>
      );
    }
    return (
      <span className="text-successColor font-semibold">
        You are already paying the best price!
      </span>
    );
  };

  return (
    <Modal
      open={openModal}
      setOpen={setOpenModal}
      onModalClose={closeButtonClicked}
      padding="0"
      minWidth="42rem"
      withCloseButton={false}>
      <div className="text-right text-3xl pt-2 pr-2">
        <IoClose className="cursor-pointer" onClick={closeButtonClicked} />
      </div>
      <div className="flex flex-col justify-between">
        <div className="flex px-5 items-center my-0">
          <p className="pr-8 flex items-center text-3xl font-semibold m-0">
            <CgPill className="pr-3" /> {makeFirstLetterCapital(modalBreakdownInfo.drugName)}
          </p>
          <p className="italic text-darkerGrayTextColor m-0">(NDC: {modalBreakdownInfo.drugNDC})</p>
        </div>
        <div className="max-w-2xl text-sm  m-2 p-2 border border-borderColor rounded-md border-solid">
          <p className="mt-2">Savings details: {renderSavingsTitle(modalBreakdownInfo)}</p>
          <p>
            You paid{" "}
            {modalBreakdownInfo.patientPaid
              ? formatMoneyUtil.formatMoney(modalBreakdownInfo.patientPaid)
              : "N/A"}{" "}
            out of pocket.
          </p>
          {!modalBreakdownInfo.brandBP ? (
            <p>
              <FaCheckCircle className="text-fourthAccentColor" /> We could not find a better brand
              price for {makeFirstLetterCapital(modalBreakdownInfo.drugName)}.
            </p>
          ) : (
            <p>
              <FaCheckCircle className="text-fourthAccentColor" /> We found{" "}
              {modalBreakdownInfo.brandDrugName} at {modalBreakdownInfo.brandSource} for{" "}
              {formatMoneyUtil.formatMoney(modalBreakdownInfo.brandBP)}.
            </p>
          )}
          {!modalBreakdownInfo.genericBP ? (
            <p>
              <FaCheckCircle className="text-fourthAccentColor" /> We could not find a better
              generic price for {makeFirstLetterCapital(modalBreakdownInfo.drugName)}.
            </p>
          ) : (
            <p>
              <FaCheckCircle className="text-fourthAccentColor" /> We found{" "}
              {modalBreakdownInfo.genericDrugName}, Generic for{" "}
              {makeFirstLetterCapital(modalBreakdownInfo.drugName)}, at{" "}
              {modalBreakdownInfo.genericSource} for{" "}
              {formatMoneyUtil.formatMoney(modalBreakdownInfo.genericBP)}.
            </p>
          )}
          <RxLinkForm rxLinkPhone={rxLinkPhone} setRxLinkPhone={setRxLinkPhone} />
          <p className="disclaimer my-0">
            By providing your contact information, you are creating an RxLink account and
            authorizing the sharing of your prescription data with RxLink. You will be redirected to
            the RxLink website.
          </p>
        </div>
        <div className="flex justify-end bg-mainTextColor">
          <Button className="m-2" invertedTheme onClick={closeButtonClicked}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
}
