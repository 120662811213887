// component for rxlink form to be used anywhere we need to send a user to rxlink
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import rxLink from "../../assets/rxLink.png";
import ReactGATracking from "../../clients/googleAnalyticsClient";
import Mixpanel from "../../clients/mixPanelClient";
import ReactPixelInitialize from "../../clients/pixelClient";
import rxLinkFetches from "../../useFetch/rxLinkFetch/rxLinkFetches";
import Auth from "../../utils/auth";
import pollingHelpers from "../../utils/pollingHelpers";
import Button from "../button/Button";
import Input from "../input/Input";

// Prop Chart:
// prop name              | default value                  | is required | permission required to update
// ------------------------------------------------------------------------------------------------------
// rxLinkPhone            | -                              | true        | -
// setRxLinkPhone         | -                              | true        | -
// direction              | col                            | false       | -
// withLabel              | true                           | false       | -
// withErrorText          | true                           | false       | -

export default function RxLinkForm({
  rxLinkPhone,
  setRxLinkPhone,
  direction = "col",
  withLabel = true,
  withErrorText = true
}) {
  const [loading, setLoading] = useState(false);
  const [redirectLink, setRedirectLink] = useState("");
  const [popUp, setPopUp] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  // rxLinkPhone and setRxLinkPhone are required to use this component
  useEffect(() => {
    if (rxLinkPhone === undefined || setRxLinkPhone === undefined) {
      throw new Error("rxLinkForm component requires rxLinkPhone and setRxLinkPhone props");
    }
  }, []);

  // this will make request for user to sign up or login to rxlink
  const submitPhoneAndNavigate = async (e) => {
    e.preventDefault();
    Mixpanel("RxLink Phone Submit");
    ReactGATracking("Form Submit", "RxLink Phone Submit");
    ReactPixelInitialize("RxLink Phone Submit", { value: "none" });
    setLoading(true);
    setErrorMessage("");
    const phoneNumberString = rxLinkPhone + "";
    if (phoneNumberString.length < 10) {
      toast.error("Please enter a valid 10 digit phone number");
      return;
    }

    try {
      const userToken = Auth.getToken();
      const pollingEventID = await rxLinkFetches.rxLinkFetch(userToken, phoneNumberString);
      const pollData = await pollingHelpers.initiatePolling(pollingEventID, userToken);
      if (pollData.status === "incomplete") {
        toast.error("To see drug savings, please link an insurer.");
        setErrorMessage("To see drug savings, please link an insurer.");
      } else if (pollData.status === "failed") {
        toast.error("Something went wrong generating savings, please try again later.");
        setErrorMessage("Something went wrong generating savings, please try again later.");
      } else if (pollData.status === "duplicate number") {
        toast.error(pollData.response);
        setErrorMessage(pollData.response);
      }
      setRedirectLink(pollData.response.data.link);

      window.open(pollData.response.data.link, "_blank");
      setPopUp(true);
    } catch (err) {
      toast.error("Something went wrong, please try again later.");
    }
    setLoading(false);
  };

  const redirectToRxLink = () => {
    Mixpanel("Redirect to RxLink");
    ReactGATracking("Redirect", "Redirect to RxLink");
    ReactPixelInitialize("Redirect to RxLink", { value: "none" });
    // redirect to rxLink in new tab
    window.open(redirectLink, "_blank");
    setPopUp(true);
  };

  const handlePhoneNumberChange = (e) => {
    const input = e.target.value;

    // Use a regular expression to remove any non-numeric characters
    const cleanedInput = input.replace(/[^0-9]/g, "");

    // Update the phoneNumber state with the cleaned input
    setRxLinkPhone(cleanedInput);
  };

  return (
    <div className="max-w-[44rem] px-8">
      {popUp ? (
        <div>
          {/* If user has pop up blocker on, present them this message to allow them to move forward */}
          <p>
            You have been redirected to RxLink. If the new window/tab does not open, please enable
            pop-ups for this site and try again.
          </p>
          <Button fullWidth type="button" onClick={redirectToRxLink} theme="success">
            Continue
          </Button>
        </div>
      ) : (
        <>
          {withLabel ? (
            <p className="mt-1 mb-2">
              Enter your phone number below to get{" "}
              <img src={rxLink} alt="rxLink logo" className="h-5" /> savings coupons texted to your
              phone.
            </p>
          ) : null}
          <form
            className={`flex m-0 ${direction === "col" ? "flex-col" : "flex-col lg:flex-row"}`}
            onSubmit={(e) => submitPhoneAndNavigate(e)}>
            <div>
              <Input
                value={rxLinkPhone}
                onChange={handlePhoneNumberChange}
                maxLength={10}
                setValue={setRxLinkPhone}
                label="Phone number:"
                placeholder="5555555555"
                type="tel"
                required
                inputClasses="text-base"
              />
            </div>
            {errorMessage && withErrorText ? (
              <p className="text-dangerColor">{errorMessage}</p>
            ) : null}
            {loading ? (
              <div className="flex justify-center ml-5">
                <div className="spinner" />
              </div>
            ) : (
              <div>
                <Button
                  className={`${direction === "row" ? "mt-10 ml-1 h-[3.25rem]" : "mt-3"}`}
                  fullWidth={direction !== "row"}
                  type="submit"
                  theme="success">
                  Get Coupons
                </Button>
              </div>
            )}
          </form>
        </>
      )}
    </div>
  );
}
