// these helper functions are for specifically fetching clinical data
export default {
  // this function will get patient information from the patientInfo endpoint
  getPatientInfo: async (JWTToken) => {
    const response = await fetch(`/api/patientInfo`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + JWTToken
      }
    });
    try {
      if (response.ok) {
        const data = await response.json();
        return data;
      }
      console.error("Error fetching patient info");
      return {};
    } catch (err) {
      console.error(err);
      console.error("Error fetching patient info");
      return {};
    }
  },

  // this function will get patient information from the patientInfo endpoint
  getPatientEncounters: async (JWTToken) => {
    const response = await fetch(`/api/encounters`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + JWTToken
      }
    });
    try {
      if (response.ok) {
        const data = await response.json();
        return data.finalEncounters;
      }
      console.error("Error fetching encounters");
      return [];
    } catch (err) {
      console.error(err);
      console.error("Error fetching encounters");
      return [];
    }
  },

  // this function will help us get the user's first name
  getPatientFirstName: async (JWTToken) => {
    const response = await fetch(`/api/user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + JWTToken
      }
    });
    try {
      if (response.ok) {
        const data = await response.json();
        return data.firstName;
      }
      console.error("Error fetching user first name");
      return "";
    } catch (err) {
      console.error(err);
      console.error("Error fetching user first name");
      return "";
    }
  },

  // this function will help us get the explanation of benefits
  getPatientEOB: async (JWTToken) => {
    try {
      const response = await fetch(`/api/explanationOfBenefits`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + JWTToken
        }
      });
      if (response.ok) {
        const data = await response.json();
        return data.EOB;
      }
      console.error("Error fetching explanation of benefits");
      return [];
    } catch (err) {
      console.error(err);
      console.error("Error fetching explanation of benefits");
      return [];
    }
  },

  // this function will help us get the user's drug list
  getPatientDrugs: async (JWTToken) => {
    try {
      const response = await fetch("/api/userDrugs", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + JWTToken
        }
      });
      if (response.ok) {
        const data = await response.json();
        return data.queryRes;
      }
      console.error("Error fetching user drugs");
      return [];
    } catch (err) {
      console.error(err);
      console.error("Error fetching user drugs");
      return [];
    }
  },

  retrieveUserInfo: async (JWT) => {
    const response = await fetch("/api/patientInfo", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${JWT}`
      }
    });
    try {
      if (response.ok) {
        const data = await response.json();
        return !!data.patientInfoEntry.length;
      }
      console.error("error fetching user info");
      return false;
    } catch (err) {
      console.error(err);
      console.error("error fetching user info");
      return false;
    }
  },

  getUsersCurrentPlan: async (JWT) => {
    try {
      const response = await fetch("/api/currentPlan/getUsersCurrentPlan", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${JWT}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        if (data.userCurrentPlan.length) {
          return data.userCurrentPlan[0];
        }

        return null;
      }
    } catch (err) {
      console.error(err.toString());
      console.error("Error fetching user's current plan");
      return null;
    }
  }
};
