import React, { useState } from "react";
import { RxCaretDown, RxCaretUp } from "react-icons/rx";
import { Link } from "react-router-dom";
import ReactGATracking from "../../clients/googleAnalyticsClient";
import Mixpanel from "../../clients/mixPanelClient";
import ReactPixelInitialize from "../../clients/pixelClient";
import Button from "../button/Button";

// Prop Chart:
// prop name           | default value        | is required | permission required to update
// ------------------------------------------------------------------------------------------------------
// title               | ""                   | false       | -
// subtitle            | ""                   | false       | -
// viewAll             | true                 | false       | -
// viewAllCount        | ""                   | false       | -
// children            | ""                   | false       | -
// className           | ""                   | false       | -

export default function DashboardExhibitContainer({
  title,
  subtitle,
  viewAll = true,
  viewAllCount,
  children,
  className,
  viewAllParam
}) {
  const [isMaximized, setIsMaximized] = useState(true);
  const [toggleButton, setToggleButton] = useState(<RxCaretDown size="50" />);

  const changeToggleButton = () => {
    if (isMaximized) {
      setIsMaximized(false);
      setToggleButton(<RxCaretUp size="50" />);
    } else {
      setIsMaximized(true);
      setToggleButton(<RxCaretDown size="50" />);
    }
  };

  const handleViewAllClick = () => {
    Mixpanel(`View All ${viewAllParam}`);
    ReactGATracking("Page View", `View All ${viewAllParam}`);
    ReactPixelInitialize(`View All ${viewAllParam}`, { value: viewAllParam });
  };
  return (
    <div
      className={`${className} min-w-[calc(100%-5rem)] bg-whiteTextColor m-2 p-4 sm:px-6 lg:px-8 ring-1 ring-whiteAccentColor rounded-md`}>
      <div className="flex justify-between p-2">
        <div className="sm:flex-auto">
          <div className="flex">
            <h3 className="m-0 text-left font-semibold">{title}</h3>
            {viewAll && viewAllCount > 0 && (
              <div className=" mt-4 whitespace-nowrap px-5">
                <Link
                  onClick={handleViewAllClick}
                  to={viewAllParam}
                  className="text-base mtlg:text-lg cursor-pointer font-medium">
                  View all ({viewAllCount})
                </Link>
              </div>
            )}

            <Button
              theme="minMax"
              className=" mb-0  -mt-4 ml-auto"
              onClick={() => {
                changeToggleButton();
              }}>
              {toggleButton}
            </Button>
          </div>
          <div className="mt-2 text-lg">{subtitle}</div>
        </div>
      </div>
      {isMaximized ? children : null}
    </div>
  );
}
