// eslint-disable-next-line import/no-extraneous-dependencies
import ReactGA from "react-ga4";
import getCookie from "../utils/cookieHelpers";

export default function ReactGATracking(itemCategory, itemAction) {
  const consentCookie = getCookie("consentCookie");
  if (consentCookie !== "rejectAll") {
    ReactGA.initialize(`${process.env.REACT_APP_GA_TOKEN}`, { debug: true });
    ReactGA.event({
      category: itemCategory,
      action: itemAction
    });
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }
}
