import React from "react";

export default {
  privacyTitle: "Healthcare Download Privacy Policy",
  lastUpdated: "Last Updated: February 29, 2024",
  effectiveDate: "Effective Date: July 1, 2022",

  // Overview
  overviewTitle: "Overview",
  overviewParagraph1:
    "Healthcare Download is a free service provided by HCDL Inc. (“HCDL”) that allows you to access multiple patient access and service accounts that you’ve created at healthcare organizations. You can sign in just once to Healthcare Download to see consolidated information from all of your linked accounts. Healthcare Download provides a variety of analytic tools to help you stay on top of your interactions with healthcare entities like insurers and providers.",
  overviewParagraph2:
    "In order to provide you with access to Healthcare Download (the “Services”) and the ability to use the Services, HCDL may collect and process your personal information as described in this Healthcare Download Privacy Policy (“Privacy Policy”). This Privacy Policy is designed to inform you about how HCDL collects and uses information you provide when you create an account with Healthcare Download (“Service Account”), visit the HCDL-owned web sites through which HCDL provides the Services (“Service Portal”), and use the Services. HCDL may update this Privacy Policy, or other privacy notices established for other HCDL websites, at any time, and future updates to the Privacy Policy will be effective as soon as they are published. If you are interested, you should check back from time to time and make sure that you have reviewed the most current version of this Privacy Policy.",

  // Section 1
  sec1Title: "Information You Provide to Healthcare Download",

  // Information That You Give Us
  infoTitle: "Information That You Give Us",
  infoParagraph1:
    "When you create a Service Account, you are asked to provide certain personal information, including your name, date of birth, and a valid email address. You will select a username and password for the Service Account and provide other information (such as answers to security questions and a security phrase). We use the information you provide to us to confirm your eligibility for a Service Account, to protect against unauthorized access to your Service Account, to provide the Services to you, to communicate with you, and to provide assistance or technical support in connection with your use of the Services. For example, when we send you emails, we will include the security phrase that you provided. Note that if you receive an email purporting to be from Healthcare Download that does not contain the security phrase you set on your Service Account, you should not trust that the email was sent by Healthcare Download.",
  infoParagraph2:
    "Additionally, you will need to provide the username and password you use for each healthcare account that you wish to link to your Services Account. We do not store or retain any of your healthcare Account usernames or passwords. We use the information you provide to securely link your healthcare Accounts to Healthcare Download and to provide the Services to you.",

  // Our Website and Servers, Your Use of Browsers
  websiteTitle: "Our Website and Servers, Your Use of Browsers",
  websiteParagraph1:
    "We also collect and record certain information from your browser each time you connect to our Service Portal, such as:",
  websiteList1: "IP address;",
  websiteList2: "Browser type;",
  websiteList3: "Preferred language;",
  websiteList4: "The date, time, and duration of your connection; and",
  websiteList5: "The actions that you perform while on the Service Portal.",
  websiteParagraph2:
    "We use this information in order to provide the Services, monitor the performance of the Services, and offer any technical support or assistance you might request in connection with your use of the Services or our websites.",

  // Do-not-track
  dntTitle: "Do-Not-Track",
  dntParagraph1:
    "Some web browsers and operating systems include a Do-Not-Track (DNT) setting that you can activate to signal your preference not to have information about your online activities monitored. There is currently no uniform standard for recognizing and implementing DNT signals. As a result, the Services do not respond to DNT signals. If a standard for recognizing DNT signals is adopted in the future and we follow that standard, we will inform you about our approach in an update to this Privacy Policy.",

  // Cookies
  cookiesTitle: "Cookies",
  cookiesParagraph1:
    "We use cookies to collect information about access to and use of the Services and the Service Portal. The Service Portal plants a session cookie in your browser that logs data in order to maintain your logged in state, track which Service Portal content you view, and analyze and improve the usage of the Service Portal and the Services. Our collection of this information may include:",
  cookiesList1: "Your IP address;",
  cookiesList2: "The pages of our site that you visit;",
  cookiesList3: "The time and date of your visit;",
  cookiesList4: "The time you spend on certain pages on our site; and",
  cookiesList5: "Various other statistics.",
  cookiesParagraph2:
    "You may adjust cookie usage in your browser settings. Adjusting your cookie settings may prevent you from accessing the Service Portal and/or utilizing the functionality of the Services.",

  // How Do We Use Your Information?
  yourInfoTitle: "How Do We Use Your Information?",
  yourInfoParagraph1:
    "The information that you provide us when you use the Services is retained and processed for as long as you use the Services and after you cease using the Services, as described in this Privacy Policy. We will use your information for purposes such as:",
  yourInfoList1: "To provide the Services to you;",
  yourInfoList2: "To communicate with you;",
  yourInfoList3:
    "To provide assistance or technical support in connection with your use of the Services;",
  yourInfoList4: "To audit, monitor, and further develop the Services; and",
  yourInfoList5:
    "To investigate violations of the Healthcare Download Terms of Service and protect HCDL.",
  yourInfoParagraph2:
    "By creating a Service Account, you consent to our use of your information in accordance with this Privacy Policy. HCDL will not sell or license any information that it may collect from you from using the Services.",

  // Who Has Access to Your Information?
  accessTitle: "Who Has Access to Your Information?",
  accessParagraph1:
    "When you provide your personal information directly to HCDL via the Service Portal, your information may be shared with and accessible to HCDL staff, including with staff who provide technical support for the Services. In addition, HCDL may at times engage other companies or individuals to perform certain activities on our behalf and related to our provision of the Services, such as assistance in correcting hardware problems, off-site storage of information for disaster recovery, web site hosting, or technical assistance regarding operating systems, web browsers, or other non-HCDL software with which the Services might interact. HCDL will provide such third parties access to your personal information (i) when such access is intended to accomplish the activity for which we have engaged the third party; and (ii) when the third party has agreed to use the information for activity for which they've been engaged and protect the confidentiality and security of the information. We will make good faith efforts to provide you access to your personal information through the Service Portal, including through features available to you on the Service Portal. While the Services allow you to delete documents that you have pulled, pushed, or uploaded, you are not able to correct or delete inaccuracies in your HCDL Accounts in the Service Portal. To make such corrections or deletions, you should work directly with the healthcare organizations through which you have healthcare Accounts.",

  // How We Protect Your Information
  protectTitle: "How We Protect Your Information",
  protectParagraph1:
    "HCDL employs a wide variety of administrative, physical, and technical safeguards to protect the confidentiality, integrity, and availability of your personal information. When you provide your information to us, it is encrypted and transmitted in a secure way. You can verify this by looking for a closed lock icon at the top or bottom of your web browser or looking for “https” at the beginning of the URL address of the web page if you navigated to the Service Portal from a web browser. We have internal policies and processes directed towards limiting access to your information to those members of the HCDL team and others who need to know such information to perform their jobs and develop or improve our websites, products, and services. Please remember that no method of transmission over the Internet or method of storage can keep your data 100% secure against unauthorized access, use, or disclosure. Please note, however, that when the Services re-direct you to web sites operated by other organizations (such as a healthcare organization at which you have an active healthcare Account), you no longer are connected to our Service Portal. At that point, the nature of your connection is governed and controlled by the technology adopted and put into place by the organization operating the other website.",

  // How you control the sharing of your personal information and the limited circumstances in which we may disclose it to others
  controlSharingTitle:
    "How you control the sharing of your personal information and the limited circumstances in which we may disclose it to others",
  controlSharingParagraph1:
    "There are very few instances in which your personal information ever will be disclosed by us other than as directed by you through your use of the Services. We may disclose your personal information to:",
  controlSharingList1:
    "Comply with any applicable law, legal process served on us, or request of a law  enforcement or government regulatory agency;",
  controlSharingList2:
    "Protect the personal safety or health of the public or users of the Services; and",
  controlSharingList3: "Protect our rights and property and address fraud or security breaches.",
  controlSharingParagraph2:
    "Data sharing will never effect individuals other than yourself, unless you expressly direct HCDL to share data with someone other than you. For example genetic disorders will never be disclosed to your family members by HCDL unless you share your password with them.",

  // How Long Does HCDL Keep Your Information?
  keepTitle: "How Long Does HCDL Keep Your Information?",
  keepParagraph1:
    "HCDL will retain your information for as long as you have a Service Account. By creating a Service Account, you agree to allow us to retain your information in accordance with this Privacy Policy. You can choose to close a Service Account at any time. If you choose to do so, we will offer you the opportunity to have us retain your personal information and Service Account information for a period during which you can easily re-activate the account. If you do not opt for the grace period, we will deactivate your Service Account and delete all your personal information from HCDL's servers. If you do opt for the grace period, then the deletion of your personal information from HCDL's servers will occur after the grace period. Please note that closing a Service Account affects only your personal information that is stored on HCDL's servers. It does not affect, alter, or delete any personal information that is stored or maintained on other systems, such as those of your healthcare providers or the organizations at which you have healthcare Accounts.",

  // Ways in which you can Protect Your Personal Information
  waysYouCanProtectTitle: "Ways in which you can further protect your personal information",
  waysYouCanProtectParagraph1:
    "You should be careful with your personal information, and there are steps you can take to prevent unauthorized access to or disclosure of the information in your Service Account. For example, never share your username and password with anybody, immediately change your password if you believe any unauthorized access to your Service Account has occurred, and install appropriate security products on the computers from which you access your Service Account.",

  // Your California Privacy Rights
  privacyRightsTitle: "Your California Privacy Rights",
  privacyRightsParagraph1: (
    <>
      If you are a California resident, California law may provide you with additional rights
      regarding our use of your personal information. To learn more about your California privacy
      rights, visit the{" "}
      <a href="https://oag.ca.gov/privacy/ccpa" target="_blank">
        California Consumer Privacy Act (CCPA) website
      </a>
      .
    </>
  ),

  // Contact HCDL
  contactTitle: "Contact HCDL",
  contactParagraph1:
    "If you have questions or concerns about Healthcare Download or this Privacy Policy, please contact HCDL at info@healthcare-download.com. In any correspondence, please include the website or reason that led you to contact us."
};
