export default {
  // function to request a password recovery email
  requestPassRecovEmail: async (email) => {
    try {
      const response = await fetch("/api/verifyEmailandGenStr", {
        method: "POST",
        body: JSON.stringify({
          email
        }),
        headers: {
          "Content-Type": "application/json"
        }
      });
      const data = await response.json();
      if (response.ok && data.message === "Operation Complete") {
        return "success";
      }
      return "failed";
    } catch (err) {
      console.error(err);
      return "failed";
    }
  },

  // function to request code verification
  verifyRandomCode: async (email, randomString) => {
    try {
      const response = await fetch("/api/verifyRandomString", {
        method: "POST",
        body: JSON.stringify({
          email,
          randomString
        }),
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (response.ok) {
        const data = await response.json();
        return data.message === "Code Matches" ? data.tempToken : "failed";
      }
      const error = await response.json();
      return error.message === "Code Does Not Match" ? "Incorrect Code" : "failed";
    } catch (err) {
      console.error(err);
      return "failed";
    }
  }
};
