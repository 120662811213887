/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";
import "../../tailwind.css";

export default {
  // Drug history table tooltips
  rxHistoryTitle: "These are all the prescriptions that the insurer has a record of you receiving.",
  drugHeader:
    "This is the generic name of your prescription and the national drug code that the FDA uses to identify the drug.",
  costDetailsHeader:
    "A breakdown of how much you paid, how much your insurance company paid, and the total cost",
  priceComparisonHeader:
    "A breakdown of the best price for brand name drugs, generic drugs, and where to find the best price",
  bestPriceInfo: (
    <>
      <>Find coupons to help you save money on your prescriptions with </>
      <span className="underline">RxLink.</span>
    </>
  ),
  // Rx Interactions tooltips
  rxInteractionsTitle:
    "These are all of the interactions between drugs that you may be taking based off of information provided by your insurer.",
  rxInteractionsDrugs: "The two drugs that may share an interaction.",
  rxInteractionSeverity:
    "The severity level of the interactions between the two drugs you may be taking. These range from minor, to major, to unknown.",
  rxInteractionDescription:
    "A brief description of the interaction between the two drugs as well as a link to get more information.",

  // Health history table tooltips
  healthHistoryTitle:
    "Each health event in your insurer's electronic health record, including patient, hospital, and other services.",
  visitDetailsHeader:
    "Type of service and date that the service was performed. For in-patient service, this is the admission date.",
  renderingProviderHeader:
    "Medical professional who performed the service for you. This is sometimes different from the provider that billed you for that service.",
  diagnosisHeader: "This is the diagnosis listed for the service that day.",
  procedureHeader: "This is the primary procedure listed for the service.",
  costHeader: "This is the amount you paid.",

  // Plan rank graph tooltips
  planRankGraphTitle:
    "This graph displays the expected cost for each available insurance plan in your area based on healthcare data we have collected.",

  // Plan rank table tooltips
  planRankTableTitle:
    "This table displays the expected cost for each available insurance plan in your area based on healthcare data we have collected.",
  planHeader: "The health insurance plans available in your area.",
  carrierHeader: "The insurance company offering the health plan.",
  premiumHeader:
    "Your annual premium cost does not include any social security deduction in Medicare. A negative premium represents a refund.",
  pharmacyHeader:
    "Your annual expected pharmacy copays. Your projected cost of your drugs for the following year.",
  medicalHeader:
    "The projected cost of your copay, coinsurance, and deductibles for the following year.",
  totalHeader: "Total estimated cost for your expected service level at each health plan.",
  costCompare: "Compare total estimated cost for these insurance companies"
};
