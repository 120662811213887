/*
Date Formats:
--------------------------------------------------------------------------------------------------------------
| Format                                                  | Description                                      |
--------------------------------------------------------------------------------------------------------------
| "yyyy-MM-dd'T'HH:mm:ss"                                 | ISO 8601 format without timezone offset          |
| "yyyy-MM-dd'T'HH:mm:ss'Z'"                              | ISO 8601 format with UTC timezone                |
| "yyyy/MM/dd HH:mm:ss"                                   | Custom format with slashes and space             |
| "MM/dd/yyyy hh:mm:ss a"                                 | Custom format with slashes, space, and AM/PM     |
| "MMM d, yyyy hh:mm:ss a"                                | Custom format with abbreviated month name        |
| "MMMM d, yyyy hh:mm:ss a"                               | Custom format with full month name               |
| "yyyy-MM-dd'T'HH:mm:ssXXX"                              | ISO 8601 format with timezone offset             |
| "yyyy-MM-dd'T'HH:mm:ss.SSSXXX"                          | ISO 8601 format with timezone offset and ms      |
| "yyyy/MM/dd"                                            | Custom date format with slashes                  |
| "MM-dd-yyyy"                                            | Custom date format with dashes                   |
| "yyyy-MM-dd"                                            | ISO 8601 format                                  |
| "yyyy-MM-ddXXX"                                         | ISO 8601 format with timezone offset and no time |
--------------------------------------------------------------------------------------------------------------
*/

// util to format dates to a more readable format for the user in the form of "mm-dd-yyyy"
function formatDate(dateString) {
  const date = new Date(dateString);

  // Adjust for timezone offset if there is one
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

  // Check if the date is valid
  if (!Number.isNaN(utcDate.getTime())) {
    const month = String(utcDate.getMonth() + 1).padStart(2, "0");
    const day = String(utcDate.getDate()).padStart(2, "0");
    const year = utcDate.getFullYear();
    return `${month}-${day}-${year}`;
  }

  // If we can't format the date, at least return the date string as a fallback
  return dateString;
}

export default {
  formatDate
};
