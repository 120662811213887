// function to get drug interactions from server or return empty array
export default {
  getDrugInteractions: async (JWTToken) => {
    const response = await fetch(`/api/drugInteractions`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + JWTToken
      }
    });
    try {
      if (response.ok) {
        const data = await response.json();
        return data;
      }
      console.error("Error fetching drug interactions info");
      return [];
    } catch (err) {
      console.error(err);
      console.error("Error fetching drug interactions info");
      return [];
    }
  }
};
