// function to add commas to numeric values, i.e. $1200 -> $1,200
function insertCommas(numberString) {
  let result = "";
  let counter = 0;

  for (let i = numberString.length - 1; i >= 0; i--) {
    if (counter === 3 && i !== numberString.length - 1) {
      result = "," + result;
      counter = 0;
    }
    result = numberString[i] + result;
    counter++;
  }

  return result;
}
const extractMonthlyPayment = (amount) => {
  const rawAmount = amount.replace(/[, $]/g, "");

  if (Number.isNaN(rawAmount)) {
    return null;
  }

  const monthlyPayment = Math.round(rawAmount / 12);

  return `$${monthlyPayment}`;
};

// formats money with no cents
function formatMoney(amount) {
  // if the amount is of type number, convert to string
  amount += "";

  // if the starting character is the dollar sign, remove it
  if (amount[0] === "$") {
    amount = amount.slice(1);
  }

  // Split the amount into dollars and cents
  const [splitDollars] = amount.split(".");

  // Insert commas into dollars
  const finalDollars = insertCommas(splitDollars);

  // return the amount as a whole number string
  return `$${finalDollars}`;
}

// format the money with cents
function formatMoneyWithCents(amount) {
  // Remove any existing commas and dollar sign
  const rawAmount = amount.replace(/[, $]/g, "");

  // split the amount into dollars and cents
  const [splitDollars, splitCents] = rawAmount.split(".");

  // if splitDollars is 0 and split cents is 00 OR split cents is undefined then return 0
  if (splitDollars === "0" && (splitCents === "00" || splitCents === "0" || !splitCents)) {
    return "$0";
  }

  // insert commas into dollars
  const finalDollars = insertCommas(splitDollars);

  // return splitDollars and splitCents with a dollar sign (if there are no cents) and a period (if there are cents)
  return splitCents ? `$${finalDollars}.${splitCents.substring(0, 2)}` : `$${finalDollars}.00`;
}

const getYearlyTotal = (amount) => {
  const rawAmount = amount.replace(/[, $]/g, "");

  if (Number.isNaN(rawAmount)) {
    return null;
  }

  const yearlyPayment = Math.round(rawAmount * 12);

  return `$${yearlyPayment}`;
};

export default {
  extractMonthlyPayment,
  formatMoney,
  formatMoneyWithCents,
  getYearlyTotal
};
