/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { TbClockCode } from "react-icons/tb";
import loginServices from "./loginServices";
import HCDLLogo from "../../assets/logo.png";
import Input from "../input/Input";
import Button from "../button/Button";

export default function PassRecovRandCodeForm({
  setLoginModalState,
  closeButtonClicked,
  userEmail,
  setTempToken
}) {
  const [showSpinner, setShowSpinner] = useState(false);
  const [userCode, setUserCode] = useState("");
  const [errorText, setErrorText] = useState("");

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // begin spinner
    setShowSpinner(true);
    setErrorText("");

    // attempt a request to server to send password recovery email
    try {
      const response = await loginServices.verifyRandomCode(
        userEmail,
        userCode.trim().toUpperCase()
      );
      switch (response) {
        case "Incorrect Code":
          setErrorText("Incorrect Code");
          break;
        case "failed":
        case undefined:
        case null:
          setErrorText("There was an issue with your request, please try again later.");
          break;
        default: // success
          setTempToken(response);
          setLoginModalState("passRecovPassUpdate");
          break;
      }
    } catch (err) {
      console.error(err);
      setErrorText("There was an issue with your request, please try again later.");
    }

    // end spinner
    setShowSpinner(false);
  };

  return (
    <form
      className="pt-[2.5rem] pb-[1.5rem] m-0 px-[2rem] sm:px-[2rem] md:px-[5rem]"
      onSubmit={(e) => handleFormSubmit(e)}>
      <div className="text-right text-3xl">
        <IoClose className="cursor-pointer" onClick={closeButtonClicked} />
      </div>
      <div className="flex justify-center items-center mt-0 flex-col">
        <div className="flex justify-center items-center">
          <img className="w-[4rem] me-5" alt="HCDL logo" src={HCDLLogo} />
          <h2 className="text-[1.5rem] sm:text-[2rem]">Healthcare Download</h2>
        </div>
        <p className="mt-1">Enter Code Sent To Email</p>
      </div>
      <Input
        label="Code"
        value={userCode}
        setValue={setUserCode}
        type="text"
        placeholder="Enter Code"
        inlineStartIcon={TbClockCode}
        wrapperClasses="mt-2"
        backgroundColor="var(--medium-highlight-color:)"
        required
        inputClasses="text-base"
      />
      <p className="text-dangerColor text-base mt-2 mb-0">{errorText}</p>
      {showSpinner ? (
        <>
          <div className="flex justify-center min-w-full">
            <div className="spinner" />
          </div>
        </>
      ) : (
        <>
          <Button fullWidth className="mt-5" type="submit">
            Verify Code
          </Button>
          <div className="flex justify-center items-center mt-1">
            <p className="text-base">
              <span
                className="text-primary cursor-pointer underline text-center"
                onClick={() => {
                  setLoginModalState("login");
                }}>
                Return to login
              </span>
            </p>
          </div>
        </>
      )}
    </form>
  );
}
