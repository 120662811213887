export default {
  // this boolean will determine if the getPatientInformation function is available
  getPatientInformation: true,

  // this boolean will determine if the getPatientConditions function is available
  getPatientCondition: true,

  // this boolean will determine if the getPatientEncounters function is available
  getPatientEncounter: true,

  // this boolean will determine if the getPatientEOB function is available
  getPatientEOB: true
};
