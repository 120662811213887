import React, { useEffect } from "react";
import Jumbotron from "./Jumbotron";
import CuresActCard from "./CuresActCard";
import HowItWorks from "./HowItWorks";
import Compare from "./Compare";
import Security from "./Security";

export default function Home() {
  // ping the server to awake heroku
  useEffect(() => {
    const pingServer = async () => {
      try {
        await fetch("/api/pingServer");
      } catch (error) {
        console.error("Error during fetch operation", error);
      }
    };
    pingServer();
  }, []);

  return (
    <div className="homePage">
      <Jumbotron />
      <HowItWorks />
      <Compare />
      <Security />
      <CuresActCard />
    </div>
  );
}
