import React from "react";
import ReactGATracking from "../../clients/googleAnalyticsClient";
import Mixpanel from "../../clients/mixPanelClient";
import ReactPixelInitialize from "../../clients/pixelClient";
import Button from "../../components/button/Button";

export default function InsurerCardButton({ insurer, switchModal, navigateToLink }) {
  // Note: despite there being an available h-40 class to use for 10rem, it doesn't work so we use h-[10rem] instead
  return (
    <Button
      className={`${insurer.comingSoon ? "comingSoon" : ""} h-[10rem] mx-1 lg:h-48 lg:mx-2.5`}
      theme="insuranceSelect"
      onClick={(e) => {
        Mixpanel(`${insurer.insurer.toString()} Selected`);
        ReactGATracking("Button Click", insurer.insurer.toString());
        ReactPixelInitialize("Insurance Selected", { value: `${insurer.insurer.toString()}` });
        if (!insurer.comingSoon) {
          navigateToLink(
            e,
            insurer.insurer,
            insurer.subset,
            insurer.name,
            insurer.associatedInsurer,
            false
          );
        } else {
          switchModal(e);
        }
      }}>
      <img src={insurer.Logo} className="insuranceButtonImg" alt={insurer.alt} />
      {insurer.sandbox ? <p className="sandboxText">Sandbox</p> : null}
    </Button>
  );
}
