import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import smallLogo from "../../assets/logo.png";
import exchangeCodeForToken from "./intermediaryExternalEnrollmentServices";
import Button from "../../components/button/Button";

export default function IntermediaryExternalEnrollment() {
  const [error, setError] = useState(false);
  const [partner, setPartner] = useState("");

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    (async function () {
      const code = searchParams.get("code");
      const partnerURL = searchParams.get("partner");
      if (partnerURL) {
        setPartner(partnerURL.substring(0, 1).toUpperCase() + partnerURL.substring(1));
      }
      if (!code) {
        setError(true);
      }

      const tokenResponse = await exchangeCodeForToken(code);
      if (tokenResponse.error) {
        setError(true);
        return;
      }

      if (tokenResponse.token) {
        localStorage.setItem("userToken", tokenResponse.token);
        navigate("/insuranceSelect");
      }
    })();
  }, []);

  return (
    <div className="interm-page">
      <img src={smallLogo} alt="Healthcare download logo" className="interm-page-logo" />
      <div className="interm-page-heading">
        <h1>Navigating to Healthcare Download</h1>
      </div>
      {error ? (
        <>
          <p className="text-center text-dangerColor">
            There was an issue redirecting you to Healthcare Download. Please try again later.
          </p>
          <div className="flex flex-col justify-center items-center w-4/5 lg:w-1/5 mt-8">
            <Button className="my-2 text-base min-w-full" onClick={() => navigate(-1)}>
              Return{partner ? ` to ${partner}` : ""}
            </Button>
          </div>
        </>
      ) : (
        <>
          <p>You Will Be Redirected Shortly</p>
          <div className="min-w-full flex justify-center">
            <div className="spinner" />
          </div>
        </>
      )}
    </div>
  );
}
