/* eslint-disable import/order */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { FiRepeat } from "react-icons/fi";
import "../../tailwind.css";
import Button from "../button/Button";
import "../loginModal/modalstyles.css";
import Modal from "../modal/Modal";
import { IoClose } from "react-icons/io5";
import patientFetches from "../../useFetch/userFetch/patientFetches";
import auth from "../../utils/auth";
import { useNavigate } from "react-router-dom";
import userProfileFetches from "../../useFetch/userFetch/userProfileFetches";
import { useBreakpoints } from "react-breakpoints-hook";
import Input from "../input/Input";
import generateLead from "./brokerContactModalServices";
import toast from "react-hot-toast";

export default function redirectWarning({ redirectModal, setRedirectModal, planNameDetails }) {
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [leadGenerated, setLeadGenerated] = useState(false);
  const { sm } = useBreakpoints({
    sm: { min: 0, max: 640 }
  });

  const navigate = useNavigate();

  const closeButtonClicked = () => {
    setRedirectModal(false);
  };

  useEffect(() => {
    if (!sm) {
      (async function () {
        if (!auth.loggedIn()) {
          navigate("/");
        }
        const userToken = auth.getToken();
        const [patientInfo, userInfo] = await Promise.all([
          patientFetches.getPatientInfo(userToken),
          userProfileFetches.retrieveUserInfo(userToken)
        ]);
        setUserPhoneNumber(
          userInfo?.phoneNumber || patientInfo?.patientInfoEntry?.[0]?.phone_number || ""
        );
      })();
    }
  }, []);

  if (!planNameDetails) {
    return null;
  }

  const submitLead = async (e) => {
    e.preventDefault();
    setLoading(true);
    const userToken = auth.getToken();
    const response = await generateLead(userToken, userPhoneNumber);
    if (response.error) {
      toast.error("Something went wrong. Please try again later.");
      return;
    }
    toast.success("A broker will reach out to you shortly!");
    setLeadGenerated(true);
    setLoading(false);
  };

  const callBroker = () => {
    window.location.href = "tel:+18003781880";
  };

  return (
    <Modal
      open={redirectModal}
      setOpen={setRedirectModal}
      onModalClose={closeButtonClicked}
      padding="0"
      minWidth="42rem"
      withCloseButton={false}>
      <div className="text-right text-3xl pt-2 pr-2">
        <IoClose className="cursor-pointer" onClick={closeButtonClicked} />
      </div>
      <div className="flex flex-col justify-between">
        <div className="flex px-5 items-center my-0">
          <p className="pr-8 flex items-center text-3xl font-semibold m-2">
            <FiRepeat className="pr-3 pt-1 text-thirdAccentColor text-4xl" />
            Switch Plans Today!
          </p>
        </div>
        <div className="max-w-2xl text-sm m-6 p-6">
          <p className="mt-2 font-bold">
            Plan Name: <span className="font-normal">{planNameDetails.planName}</span>{" "}
          </p>
          <p className="font-bold">
            Carrier: <span className="font-normal">{planNameDetails.planCarrier}</span>
          </p>
          <p className="font-bold">
            Plan Type: <span className="font-normal">{planNameDetails.planType}</span>
          </p>
          <hr className="h-px my-8 bg-lightGrayColor border-0" />
          {sm ? (
            <>
              <p className="mt-2 mb-0 leading-10 text-center text-3xl">
                Call our broker at{" "}
                <a href="tel:+18003781880" className="font-semibold text-3xl underline">
                  +1 (800) 378-1880
                </a>{" "}
                or email us at <span className="font-semibold">info@healthcare-download.com</span>
              </p>
              <Button className="min-w-full text-base mt-4" onClick={callBroker}>
                {" "}
                Call Broker{" "}
              </Button>
            </>
          ) : (
            <form className="m-0" onSubmit={(e) => submitLead(e)}>
              {leadGenerated ? (
                <p className="mt-2 mb-0 leading-10 text-center text-3xl font-semibold">
                  Your request has been submitted. A broker will reach out to you shortly!
                </p>
              ) : (
                <>
                  <p className="mt-2 mb-0 leading-10 text-center text-3xl font-semibold">
                    Enter your phone number below and a broker will reach out to help you switch
                    plans.
                  </p>
                  <Input
                    value={userPhoneNumber}
                    setValue={setUserPhoneNumber}
                    label="Phone Number"
                    type="tel"
                    placeholder="Enter your phone number"
                    required
                    wrapperClasses="mt-8"
                    inputClasses="text-base"
                  />
                  <Button className="mt-2 min-w-full text-base" type="submit" disabled={loading}>
                    {" "}
                    Switch Plans!{" "}
                  </Button>
                </>
              )}
            </form>
          )}
        </div>
        <div className="flex justify-end bg-mainTextColor">
          <Button className="m-2" invertedTheme onClick={closeButtonClicked}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
}
