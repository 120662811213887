import React from "react";
import "./home.css";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import Button from "../../components/button/Button";
import Andrea from "../../assets/pexels-andrea-piacquadio.jpg";
import Humana from "../../assets/Humana_logo.png";
import Medicare from "../../assets/medicareDotGov.svg";
import Aetna from "../../assets/aetnaLogo.png";
import Cigna from "../../assets/cigna_logo.png";
import Kaiser from "../../assets/kaiser.png";

export default function Jumbotron() {
  return (
    <div className="homeHeader">
      <div className="absolute-container" />
      <div className="homeWidthContainer">
        <div className="homeHeaderContent">
          <div className="homeHeaderTextContainer">
            <h1>24/7 Access To All of Your Personal Healthcare Data</h1>
            <br />
            <p>
              From costs to specialists in your area, Healthcare Download brings together the
              details you need for side-by-side health plan comparisons, so your first choice is the
              best choice. Click below to get started today.
            </p>
            <br />
            <div className="buttonsContainer">
              <Link to="/register">
                <Button className="text-2xl/[1.75rem]">Get Started</Button>
              </Link>
              <Link to="/about" className="arrowLink">
                &nbsp; Who We Are &nbsp; <FaLongArrowAltRight />
              </Link>
            </div>
          </div>
          <div>
            <img
              className="homeHeaderImage"
              src={Andrea}
              alt="man with green coat smiles with glasses in hand"
            />
          </div>
        </div>
        <div className="partnerContainer">
          <p style={{ textAlign: "center" }}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Partnered with some of the nation's largest insurance companies
          </p>
          <div className="partnersImageContainer">
            <img className="w-[8rem]" src={Humana} alt="humana logo" />
            <img className="w-[8rem]" src={Kaiser} alt="Kaiser logo" />
            <img className="w-[12rem]" src={Medicare} alt="Medicare logo" />
            <img className="w-[8rem]" src={Cigna} alt="Cigna logo" />
            <img className="w-[8rem]" src={Aetna} alt="aetna logo" />
          </div>
        </div>
      </div>
    </div>
  );
}
