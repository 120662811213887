import React from "react";
import { FiRepeat } from "react-icons/fi";
import Modal from "../../components/modal/Modal";
import Button from "../../components/button/Button";

export default function InsuranceRedirectWarningModal({
  open,
  setOpen,
  clickedInsurer,
  setClickedInsurer,
  associatedInsurer,
  setAssociatedInsurer,
  clickedInsurerServerName,
  setClickedInsurerServerName,
  subset,
  navigateToLink
}) {
  return (
    <Modal open={open} setOpen={setOpen} width="40%">
      <section className="flex items-center">
        <FiRepeat className="pr-3 pt-1 text-thirdAccentColor text-4xl" />
        <p className="inline font-bold">Redirecting...</p>
      </section>
      <section className="pl-12">
        <p className="text-xl font-semibold mb-5">
          Please note, {clickedInsurer} is either a subsidiary of, or has an underwriting
          relationship with {associatedInsurer}. For this reason, we will be redirecting you to{" "}
          {associatedInsurer}&apos;s site to log in.
        </p>
      </section>
      <section className="flex justify-end mt-16">
        <Button
          className="text-base mr-5"
          theme="danger"
          onClick={() => {
            setClickedInsurer("");
            setAssociatedInsurer("");
            setClickedInsurerServerName("");
            setOpen(false);
          }}>
          {" "}
          Cancel{" "}
        </Button>
        <Button
          className="text-base"
          onClick={(e) => {
            setClickedInsurer("");
            setAssociatedInsurer("");
            setClickedInsurerServerName("");
            setOpen(false);
            navigateToLink(
              e,
              clickedInsurerServerName,
              subset,
              clickedInsurer,
              associatedInsurer,
              true
            );
          }}>
          {" "}
          Continue{" "}
        </Button>
      </section>
    </Modal>
  );
}
