import React from "react";

import Humana from "../../../assets/Humana_logo.png";
import Kaiser from "../../../assets/kaiser.png";
import Medicare from "../../../assets/medicareDotGov.svg";
import Cigna from "../../../assets/cigna_logo.png";
import Aetna from "../../../assets/aetnaLogo.png";

export default function InsurancePartnersSection() {
  return (
    <div className="insurancePartnerContainer">
      <p>We have partnered with some of the top health insurance companies in the nation.</p>
      <div className="insurancePartnerImageContainer mb-3">
        <img src={Humana} height={35} alt="Humana Healthcare Logo" />
        <img src={Kaiser} height={60} alt="Kaiser Insurance Logo" />
        <img src={Medicare} height={35} alt="Medicare Healthcare Logo" />
        <img src={Cigna} height={60} alt="Cigna Healthcare Logo" />
        <img src={Aetna} height={40} alt="Aetna Healthcare Logo" />
      </div>
    </div>
  );
}
