/* eslint-disable react/prop-types */
import React, { useState } from "react";
import "./curesAct.css";
import {
  BsDiagram3,
  BsClipboardHeart,
  BsBinoculars,
  BsSignpost2,
  BsJournalMedical,
  BsDatabaseDown,
  BsChatSquareText,
  BsPatchQuestion
} from "react-icons/bs";
import { BiMoneyWithdraw } from "react-icons/bi";
import { ImStatsBars } from "react-icons/im";
import { HiBeaker } from "react-icons/hi";
import Button from "../../components/button/Button";

// this object is used to store the data for the middle section of the page
const middleSectionData = [
  {
    title: "Overview",
    text: "The 21st Century Cures Act is a U.S. federal law enacted in December 2016 with the goal of accelerating the discovery, development, and delivery of new medical treatments and improving healthcare.",
    imgSrc: <BsChatSquareText size={200} className="cures-overview-icon" />,
    imgAlt: "icon of speech bubble with question mark extending out of it"
  },
  {
    title: "Implications",
    text: "The 21st Century Cures Act enhances privacy and security provisions to protect health information from unauthorized access, use, and disclosure. It establishes guidelines for addressing cybersecurity risks in medical devices and promotes the voluntary sharing of cybersecurity information to mitigate potential threats.",
    imgSrc: <ImStatsBars size={200} className="cures-overview-icon" />,
    imgAlt: "magnifying glass with statistics inside of it"
  },
  {
    title: "Security",
    text: "The act updates regulations concerning the privacy and security of patient records for substance use disorder treatment, ensuring the confidentiality and protection of sensitive information in this context.",
    imgSrc: <BsPatchQuestion size={200} className="cures-overview-icon" />,
    imgAlt: "icon of a padlock"
  }
];

// cardData is used to store the data for the flippable cards
const cardData = [
  {
    frontImg: <BsDiagram3 size={140} className="cures-card-front-img" />,
    imgAltText: "diagram of a flow chart",
    title: "Breakthrough Design",
    backText:
      'Establishes the "Breakthrough Therapy" designation to expedite the development and review of new drugs and therapies for serious or life-threatening conditions.'
  },
  {
    frontImg: <BsClipboardHeart size={140} className="cures-card-front-img" />,
    imgAltText: "icon of a clipboard with a check mark on it",
    title: "Trials and Evidence",
    backText:
      "Supports the development of innovative clinical trial designs and the use of real-world evidence to facilitate drug development and approval processes."
  },
  {
    frontImg: <BsBinoculars size={140} className="cures-card-front-img" />,
    imgAltText: "icon of a businessman",
    title: "Patient Perspective",
    backText:
      "Enhances the use of patient preferences and real-world evidence in medical product approval processes."
  },
  {
    frontImg: <BsSignpost2 size={140} className="cures-card-front-img" />,
    imgAltText: "icon of a sign post with three arrows pointing in different directions",
    title: "Streamlined Pathway",
    backText:
      "Streamlines the regulatory pathway for medical devices, including expedited review of breakthrough devices and increased reliance on post-market data."
  },
  {
    frontImg: <BsJournalMedical size={140} className="cures-card-front-img" />,
    imgAltText: "icon of a patient",
    title: "Patient Preferences",
    backText:
      "Enhances the use of patient preferences and real-world evidence in medical device approval processes."
  },
  {
    frontImg: <BiMoneyWithdraw size={140} className="cures-card-front-img" />,
    imgAltText: "icon of a hand holding up two coins",
    title: "Innovation Fund",
    backText:
      'Establishes the "NIH Innovation Fund" to support high-risk, high-reward research projects.'
  },
  {
    frontImg: <HiBeaker size={140} className="cures-card-front-img" />,
    imgAltText: "icon of one triangular beaker and another beaker with a round bottom",
    title: "Scientific Collaboration",
    backText:
      "Facilitates the development of regenerative medicine by promoting scientific collaboration, reducing administrative burden, and fostering innovation."
  },
  {
    frontImg: <BsDatabaseDown size={140} className="cures-card-front-img" />,
    imgAltText: "icon of two people sharing a file",
    title: "Data and Sharing",
    backText:
      "Promotes data sharing and interoperability among research institutions and encourages the use of standardized data elements."
  }
];

export default function curesAct() {
  return (
    <>
      {/* This holds the title and background image */}
      <div className="cures-bg-img-wrapper">
        <h1>
          21st Century <br /> Cures Act
        </h1>
      </div>

      {/* This holds the middle section of the page */}
      <div className="cures-overview-wrapper">
        {middleSectionData.map((entry, index) => (
          <MiddleSectionEntry
            key={entry[index]}
            title={entry.title}
            text={entry.text}
            imgSrc={entry.imgSrc}
            imgAlt={entry.imgAlt}
          />
        ))}
      </div>

      {/* This holds the flippable cards */}
      <h1 className="keyPoints">Key Points of Interest</h1>
      <div className="cures-poi-row">
        {cardData.map((card, index) => (
          <CuresCard key={card[index]} card={card} />
        ))}
      </div>
    </>
  );
}

// this component is used to create the middle section of the page
// eslint-disable-next-line react/prop-types
function MiddleSectionEntry({ title, text, imgSrc }) {
  return (
    <div className="cures-overview-section">
      <div className="curesOverviewHead">
        {imgSrc}
        <h1>{title}</h1>
      </div>
      <p>{text}</p>
    </div>
  );
}

// this compoment is used to create the flippable cards
function CuresCard({ card }) {
  // flipped is used to determine if the card is flipped or not
  const [flipped, setFlipped] = useState(false);

  // handleCardClick is used to flip the card
  const handleCardClick = () => {
    setFlipped(!flipped);
  };

  return (
    <div className={`cures-card ${flipped ? "cures-card-flipped" : ""}`} role="button">
      <div className="cures-card-front">
        <h2>{card.title}</h2>
        {card.frontImg}
        {card.imgAlt}
        <Button className="my-0 text-2xl" onClick={handleCardClick} onKeyDown={handleCardClick}>
          Flip Card
        </Button>
      </div>
      <div className="cures-card-back">
        <h2>{card.title}</h2>
        <p
          className="pb-5
            ">
          {card.backText}
        </p>
        <Button className="my-0 text-2xl" onClick={handleCardClick} onKeyDown={handleCardClick}>
          Flip Card
        </Button>
      </div>
    </div>
  );
}
