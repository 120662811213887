import React, { useState } from "react";
import rxLink from "../../assets/rxLink.png";
import formatMoneyUtil from "../../utils/formatMoney";
import RxLinkForm from "../forms/rxLinkForm";
import DashboardExhibitContainer from "./dashboardExhibitContainer";

export default function RxSavings2({ totalSavings }) {
  const [rxLinkPhone, setRxLinkPhone] = useState("");

  return (
    <DashboardExhibitContainer
      title={<div className="whitespace-nowrap">Rx Savings</div>}
      subtitle={
        totalSavings > 0 ? (
          <div>
            You could save{" "}
            <span className="text-successColor font-semibold">
              {formatMoneyUtil.formatMoney(totalSavings)}
            </span>{" "}
            using Rx savings next time you fill.
          </div>
        ) : (
          <div>
            We could not find any savings for you at the moment, but please feel free to create an
            RxLink account to get your savings.
          </div>
        )
      }>
      <div className="flex justify-center flex-col text-mainTextColor text-center mt-0">
        <p className="md:px-10 mt-1 font-bold">
          To have prescription savings sent to your phone just enter your phone number below and
          follow the instructions.
        </p>
        <p className="md:px-10 mt-0 text-xl">
          We have partnered with <img src={rxLink} className="h-4" alt="logo that says RxLink" /> to
          bring the savings directly to you!{" "}
        </p>
        <div className="mx-auto justify-center items-center">
          <RxLinkForm
            rxLinkPhone={rxLinkPhone}
            setRxLinkPhone={setRxLinkPhone}
            withLabel={false}
            withErrorText={false}
            direction="row"
          />
        </div>
        <p className="disclaimer">
          By providing your contact information, you are creating an RxLink account and authorizing
          the sharing of your prescription data with RxLink. You will be redirected to the RxLink
          website.
        </p>
      </div>
    </DashboardExhibitContainer>
  );
}
