import React from "react";

export default function Card({ children, className }) {
  return (
    <div
      className={`${className} bg-whiteTextColor m-2 p-4 sm:px-6 lg:px-8 ring-1 ring-whiteAccentColor rounded-md`}>
      <div className="flex justify-between p-2">
        <div className="sm:flex-auto">{children}</div>
      </div>
    </div>
  );
}
