import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactGATracking from "../../clients/googleAnalyticsClient";
import Mixpanel from "../../clients/mixPanelClient";
import ReactPixelInitialize from "../../clients/pixelClient";
import clinicalTrialFetches from "../../useFetch/clinicalTrialFetch/clinicalTrialFetches";
import Auth from "../../utils/auth";
import { makeFirstLetterCapital } from "../../utils/dataFormatHelpers";
import Button from "../button/Button";
import ClinicalTrialsDashboardCard from "../cards/clinicalTrialDashboardCard";
import LoadingSkeleton from "../loadingSkeleton/loadingSkeleton";
import Table from "../table/table";
import DashboardExhibitContainer from "./dashboardExhibitContainer";

export default function ClinicalTrials2({ setFetchStatus, dashboardView }) {
  const navigate = useNavigate();
  const [status, setStatus] = useState("loading");
  const [userTrials, setUserTrials] = useState([]);
  const [renderedTrials, setRenderedTrials] = useState([]);
  const [loadCount, setLoadCount] = useState(5);
  const { email } = Auth.getUser().data;

  const loadMore = () => {
    // Increase the count by 5 on each click
    setLoadCount((prevCount) => prevCount + 5);
    Mixpanel("Clinical Trials Load More");
    ReactGATracking("Button Click", "Clinical Trials Load More");
    ReactPixelInitialize("Clinical Trials Load More", { value: email });
  };

  useEffect(() => {
    (async () => {
      try {
        const userToken = Auth.getToken();
        const fetchedTrialsPollingID = await clinicalTrialFetches.getUserClinicalTrials(userToken);
        const fetchedTrials = await clinicalTrialFetches.pollClinicalTrials(
          fetchedTrialsPollingID,
          userToken
        );
        if (fetchedTrials.length === 0) {
          setStatus("missing");
          return;
        }

        // this block of logic will ensure recruiting trials are displayed first
        const recruitingTrials = [];
        const nonRecruitingTrials = [];
        for (let i = 0; i < fetchedTrials.length; i++) {
          if (fetchedTrials[i].overallStatus === "RECRUITING") {
            recruitingTrials.push(fetchedTrials[i]);
          } else {
            nonRecruitingTrials.push(fetchedTrials[i]);
          }
        }

        setUserTrials([...recruitingTrials, ...nonRecruitingTrials]);
        setRenderedTrials([...recruitingTrials, ...nonRecruitingTrials].slice(0, loadCount));
        setFetchStatus((prev) => ({ ...prev, clinicalTrials: "success" }));
        setStatus("complete");
      } catch (err) {
        setFetchStatus((prev) => ({ ...prev, clinicalTrials: "failed" }));
        setStatus("failed");
      }
    })();
  }, []);

  // this updates the renderedTrials array when the loadCount changes to render more trials
  useEffect(() => {
    setRenderedTrials(userTrials.slice(0, loadCount));
  }, [loadCount]);

  const navigateToClinicalTrialsMore = (userTrial) => {
    // create a search set of params to pass to the clinicalTrialsMore page for each key and value in the userTrial object
    Mixpanel("Clinical Trials Details Page");
    ReactGATracking("Page View", "Clinical Trials Details Page");
    ReactPixelInitialize("Clinical Trials Details Page", { value: email });
    const searchParams = new URLSearchParams();
    Object.keys(userTrial).forEach((key) => {
      searchParams.append(key, userTrial[key]);
    });

    navigate(`/clinicalTrialsMore?${searchParams.toString()}`);
  };

  const handleNavigateToOfficialTrialSite = (event, userTrial) => {
    event.preventDefault();
    Mixpanel("Clinical Trials Official Site");
    ReactGATracking("Redirect", "Clinical Trials Official Site");
    ReactPixelInitialize("Clinical Trials Official Site", { value: "none" });
    window.open(`https://clinicaltrials.gov/study/${userTrial.nctId}`, "_blank");
  };

  const renderTrialStatus = (trialStatus) => {
    const refinedStatus = makeFirstLetterCapital(trialStatus.split("_").join(" "));
    let className = "clinicalTrialStatus mt-2 ";
    switch (refinedStatus) {
      case "Recruiting":
        className += "text-successColor";
        break;
      case "Not Yet Recruiting":
        className += "text-thirdAccentColor";
        break;
      case "Completed":
      case "Terminated":
      case "Withdrawn":
      case "Active Not Recruiting":
        className += "text-dangerColor";
        break;
      default:
        className += "text-mainTextColor";
    }

    return <span className={className}>{refinedStatus}</span>;
  };

  const renderTableData = () => {
    if (status === "loading") {
      const loadingSkeletonArray = new Array(3);
      loadingSkeletonArray.fill([
        <>
          <LoadingSkeleton skeletonRenderCount={2} className="mt-6" key="1" />
          <br />
          <LoadingSkeleton height={2} backgroundColor="var(--main-text-color)" key="2" />
        </>,
        <LoadingSkeleton maxWidth="30" className="mt-6" key="3" />,
        <LoadingSkeleton className="mt-6" key="4" />,
        <LoadingSkeleton maxWidth="30" className="mt-6" key="5" />,
        <LoadingSkeleton height={2} maxWidth="8" backgroundColor="var(--main-text-color)" key="2" />
      ]);
      return loadingSkeletonArray;
    }
    if (status === "complete") {
      return renderedTrials.map((userTrial, index) => [
        <div className="flex flex-col items-start max-w-[90%]" key={userTrial.nctId + index}>
          <span className="clinicalTrialTitle">{makeFirstLetterCapital(userTrial.briefTitle)}</span>
          <span className="text-mediumGrayTextColor">ID: {userTrial.nctId}</span>
          <Button
            className="mt-3 text-[0.9rem]"
            onClick={() => navigateToClinicalTrialsMore(userTrial)}>
            + &nbsp; Learn More
          </Button>
        </div>,
        userTrial.organization,
        renderTrialStatus(userTrial.overallStatus),
        userTrial.conditions.join(", "),
        <Button
          key={userTrial.nctId + index}
          className="text-base"
          onClick={(event) => {
            handleNavigateToOfficialTrialSite(event, userTrial);
          }}>
          View
        </Button>
      ]);
    }
    return null;
  };

  return (
    <DashboardExhibitContainer
      title="Clinical Trials"
      subtitle="Our application analyzes your health history to provide personalized clinical trial suggestions from ClinicalTrials.gov."
      viewAll={dashboardView}
      viewAllCount={userTrials.length}
      viewAllParam="/clinicalTrials">
      {dashboardView ? (
        <ClinicalTrialsDashboardCard data={userTrials} status={status} />
      ) : (
        <Table
          tableDivClass="universalTableDivClass"
          tableClass={`${
            userTrials.length || status === "loading" ? "" : "noDataHeaders"
          } universalTable clinicalTrialsMobile`}
          headers={[
            "Title",
            "Organization",
            "Status",
            "Conditions",
            <div key="placeholder" className="text-nowrap">
              Full Trial
            </div>
          ]}
          data={renderTableData()}
        />
      )}
      {renderedTrials.length < userTrials.length && !dashboardView && (
        <Button
          className="rounded-b-md rounded-t-none text-base border-none"
          fullWidth
          onClick={loadMore}>
          Load more...
        </Button>
      )}
      {status === "missing" && (
        <p className="m-0 p-2">
          No Clinical Trials found. Add data by{" "}
          <Link to="/insuranceSelect">linking your insurer.</Link>
        </p>
      )}
      {status === "failed" && (
        <p className="m-0 p-2">
          Something went wrong with fetching your Clinical Trials. Please try again later.
        </p>
      )}
    </DashboardExhibitContainer>
  );
}
