import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../button/Button";

// Prop Chart:
// prop name           | default value        | is required | permission required to update
// ------------------------------------------------------------------------------------------------------
// withBackButton      | true                 | false       | -
// withIcon            | true                 | false       | -
// icon                | ""                   | false       | -
// title               | ""                   | false       | -
// children            | ""                   | false       | -

export default function DashboardHeader({
  withBackButton = true,
  withIcon = true,
  icon,
  title,
  children
}) {
  const navigate = useNavigate();
  const returnToDashboard = () => {
    navigate("/app/dashboard");
  };
  return (
    <div className="relative isolate overflow-hidden bg-secondAccentColor p-4 lg:p-6 pb-10 lg:pb-20 ">
      <div className={`${withBackButton ? "pt-16" : ""}`}>
        {withBackButton && (
          <Button invertedTheme onClick={returnToDashboard} className="sm:text-base">
            Back
          </Button>
        )}
      </div>
      <div
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-2xl"
        aria-hidden="true">
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-opacityBlackColor to-secondColorOpacity"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"
          }}
        />
      </div>
      <div
        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-2xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
        aria-hidden="true">
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-opacityBlackColor to-secondColorOpacity"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"
          }}
        />
      </div>
      <div className="mx-auto max-w-7xl text-center">
        <h2
          className={`${
            withIcon ? "sm:flex items-center justify-center" : ""
          } text-4xl font-medium text-grayColor sm:text-6xl`}>
          {withIcon && icon} {title}
        </h2>
        {children}
      </div>
    </div>
  );
}
