import React from "react";
import { useNavigate } from "react-router-dom";
import {
  renderingProviderCase,
  makeFirstLetterCapital,
  formatAllCapsSentences
} from "../../utils/dataFormatHelpers";
import formatDateUtil from "../../utils/formatDate";
import LoadingSkeleton from "../loadingSkeleton/loadingSkeleton";
import formatMoney from "../../utils/formatMoney";
import Button from "../button/Button";

// This creates the card content inside the health history dashboard card
function HealthHistoryCardContent({ label, dataField }) {
  return (
    <p className="mt-1 text-lg text-darkGrayColor">
      <span className="font-medium text-mainTextColor">{label}</span> {dataField}
    </p>
  );
}

function RenderTitle({ service }) {
  let titleContent = "";
  if (!service.rendering_provider && service.diagnoses.length === 0 && service.type) {
    // ^ if there's no provider or diagnoses but there is a service type

    titleContent = `Service Type: ${makeFirstLetterCapital(service.type)}`;
  } else if (
    service.rendering_provider &&
    service.diagnoses.length > 0 &&
    service.diagnoses[0].diagnosis_display
  ) {
    // ^ if there's a provider and diagnoses

    titleContent = `${renderingProviderCase(
      service.rendering_provider
    )} for ${makeFirstLetterCapital(service.diagnoses[0].diagnosis_display.substring(0, 51))}${
      service.diagnoses[0].diagnosis_display.length > 50 && "..."
    }`;
  } else if (service.rendering_provider && service.diagnoses.length === 0) {
    // ^ if there's a provider but no diagnoses

    titleContent = `${renderingProviderCase(service.rendering_provider)}`;
  } else if (
    !service.rendering_provider &&
    service.diagnoses.length > 0 &&
    service.diagnoses[0].diagnosis_display
  ) {
    // ^ if there's no provider but there are diagnoses

    titleContent = `Visit for ${makeFirstLetterCapital(
      service.diagnoses[0].diagnosis_display.substring(0, 51)
    )}${service.diagnoses[0].diagnosis_display.length > 50 && "..."}`;
  } else {
    // ^ if there's no provider, diagnoses, or service type

    titleContent = "Service Information Not Available";
  }

  return <h3 className="text-lg font-medium text-whiteTextColor">{titleContent}</h3>;
}

// This renders 3 health history cards to the dashboard
export default function HealthHistoryDashboardCard({ data, status }) {
  const navigate = useNavigate();

  if (status === "loading") {
    const loadingSkeletonArray = new Array(3).fill(null).map((_, index) => (
      <div
        key={index}
        className="mb-2 ring-1 ring-whiteAccentColor rounded-md bg-whiteTextColor shadow animate-pulse">
        <div className="bg-mainTextColor rounded-t-md">
          <LoadingSkeleton backgroundColor="var(--main-text-color)" height="2.5" />
        </div>
        <div className="p-2">
          <LoadingSkeleton skeletonRenderCount={3} className="m-6" maxWidth="40" />
        </div>
      </div>
    ));
    return loadingSkeletonArray;
  }

  if (status === "failed" || status === "missing") {
    return;
  }

  const handleFullViewClick = (serviceID) => {
    const url = `/healthHistory?serviceID=${serviceID}`;
    navigate(url);
  };

  return data.slice(0, 3).map((service) => {
    const healthHistoryCardContentArray = [
      {
        label: "Date of Service:",
        dataField: formatDateUtil.formatDate(service.date)
      },
      {
        label: "Type of Service:",
        dataField: makeFirstLetterCapital(service.type)
      },
      {
        label: "Rendering Provider:",
        dataField: service.rendering_provider
          ? renderingProviderCase(service.rendering_provider)
          : "N/A"
      },
      {
        label: "Primary Diagnosis:",
        dataField: service.diagnoses[0]?.diagnosis_display
          ? formatAllCapsSentences(service.diagnoses[0].diagnosis_display)
          : "N/A"
      },
      {
        label: "Primary Procedure:",
        dataField: service.procedures[0]?.procedure_display
          ? formatAllCapsSentences(service.procedures[0].procedure_display)
          : "N/A"
      }
    ];
    return (
      <div
        key={service.id}
        className="mb-2 ring-1 ring-whiteAccentColor rounded-md bg-whiteTextColor">
        <div className="bg-mainTextColor px-4 rounded-t-md flex items-center justify-between">
          <RenderTitle service={service} />
          <span className="inline-flex flex-shrink-0 items-center rounded-md bg-grayColor p-1 text-base font-medium text-successColor ring-1 ring-inset ring-successOpacityColor">
            Patient Paid:{" "}
            {service.payment_amount
              ? `${formatMoney.formatMoneyWithCents(service.payment_amount)}`
              : "N/A"}
          </span>
        </div>
        <div className="flex flex-col items-start justify-between p-4">
          <div className="flex-1">
            {healthHistoryCardContentArray.map((item, index) => (
              <HealthHistoryCardContent key={index} label={item.label} dataField={item.dataField} />
            ))}
          </div>
          {(service.diagnoses.length > 0 || service.procedures.length > 0) && (
            <Button onClick={() => handleFullViewClick(service.id)}>+ Full View</Button>
          )}
        </div>
      </div>
    );
  });
}
