import React from "react";
import { useBreakpoints } from "react-breakpoints-hook";
import Carousel, { CarouselItem } from "../carousels/Carousel";
import LightGreenCard from "./cards/LightGreenCard";
import icon1 from "../../assets/icons/whiteHealthRecordsIcon.png";
import icon2 from "../../assets/icons/neutralDiagnosesIcon.png";
import icon3 from "../../assets/icons/neutralExpensesIcon.png";
import icon4 from "../../assets/icons/neutralComparisonIcon.png";

export default function PersonalHealthSection() {
  const { sm } = useBreakpoints({
    sm: { min: 0, max: 960 }
  });

  return (
    <div className="card-container">
      {sm ? (
        <>
          <h2>Personal Health Dashboard</h2>
          <div className="infoSection">
            <Carousel>
              <CarouselItem>
                <LightGreenCard
                  left
                  icon={icon1}
                  title="5 years of medical history."
                  textContent={
                    "Can't remember the name of every doctor you've seen in the last 5 years? Just look them up."
                  }
                />
              </CarouselItem>
              <CarouselItem>
                <LightGreenCard
                  left={false}
                  icon={icon2}
                  title={"Every diagnosis you've had and when you had it."}
                  textContent={`Can't remember the type of arthritis you were diagnosed for? Just look it up.`}
                />
              </CarouselItem>
              <CarouselItem>
                <LightGreenCard
                  left
                  icon={icon3}
                  title="Review your healthcare expenses."
                  textContent="By looking at your out of pockets expenses you can easily deduct taxes or reimburse these expenses if you have an HSA."
                />
              </CarouselItem>
              <CarouselItem>
                <LightGreenCard
                  left={false}
                  icon={icon4}
                  title="Compare your health plan to others. "
                  textContent="We can check all available plans in your area to see if you could have saved money with a different plan."
                />
              </CarouselItem>
            </Carousel>
          </div>
        </>
      ) : (
        <>
          <h2>Personal Health Dashboard</h2>
          <div className="infoSection">
            <LightGreenCard
              left
              icon={icon1}
              title="5 years of medical history."
              textContent={
                "Can't remember the name of every doctor you've seen in the last 5 years? Just look them up."
              }
            />
            <LightGreenCard
              left={false}
              icon={icon2}
              title={"Every diagnosis you've had and when you had it."}
              textContent={
                "Can't remember the type of arthritis you were diagnosed for? Just look it up."
              }
            />
            <LightGreenCard
              left
              icon={icon3}
              title="Review your healthcare expenses."
              textContent="Your insurer has detailed records about the copays, coinsurance, and out of pocket spending you were required to pay."
            />
            <LightGreenCard
              left={false}
              icon={icon4}
              title="Compare your health plan to others available. "
              textContent="Could you have saved money last year with a different plan?"
            />
          </div>
        </>
      )}
    </div>
  );
}
