import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { RiHealthBookLine } from "react-icons/ri";
import { FaCar } from "react-icons/fa6";
import Auth from "../../utils/auth";
import planDetailsServices from "./planDetailsServices";

function RenderBenefits({ enhancedBenefits, rideShareBenefits, noBenefits }) {
  if (noBenefits) {
    return (
      <div className="min-w-4/5 flex justify-center mb-4">
        <p className="text-darkGrayColor text-2xl italic">
          *No enhanced or rideshare benefits available for this plan at this time*
        </p>
      </div>
    );
  }

  return (
    <div className="min-w-4/5 flex justify-center items-center mb-4 flex-col md:flex-row md:items-stretch">
      <div className="my-2 ring-1 ring-whiteAccentColor rounded-md bg-mediumHighlightColor p-4 mx-4 w-11/12 md:w-5/12">
        <h3 className="text-2xl text-center flex items-center justify-center sm:justify-start min-w-full font-semibold underline">
          <div className="hidden sm:flex sm:items-center mr-2">
            <RiHealthBookLine />
          </div>
          Enhanced Benefits
        </h3>
        {enhancedBenefits && enhancedBenefits.length > 0 ? (
          <ul>
            {enhancedBenefits.map((benefit, index) =>
              benefit ? (
                <li key={index} className="text-base">
                  {benefit ? benefit.replace("*", "") : benefit}
                </li>
              ) : null
            )}
          </ul>
        ) : (
          <p className="text-darkGrayColor text-base italic">No Enhanced Benefits available</p>
        )}
      </div>
      <div className="my-2 ring-1 ring-whiteAccentColor rounded-md bg-mediumHighlightColor p-4 mx-4 w-11/12 md:w-5/12">
        <h3 className="text-2xl text-center flex items-center justify-center sm:justify-start min-w-full font-semibold underline">
          <div className="hidden sm:flex sm:items-center mr-2">
            <FaCar />
          </div>
          Rideshare Benefits
        </h3>
        {rideShareBenefits && rideShareBenefits.length > 0 ? (
          <ul>
            <li className="text-base">{rideShareBenefits}</li>
          </ul>
        ) : (
          <p className="text-darkGrayColor text-base italic">*No rideshare benefits available*</p>
        )}
      </div>
    </div>
  );
}

export default function PlanBenefitsDisplay({ hNumber, planIdentifier, planName }) {
  const [loading, setLoading] = useState(true);
  const [enhancedBenefits, setEnhancedBenefits] = useState([]);
  const [rideShareBenefits, setRideShareBenefits] = useState("");
  const [noBenefits, setNoBenefits] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!hNumber || !planIdentifier) {
      return;
    }
    const loggedIn = Auth.loggedIn();
    if (!loggedIn) {
      navigate("/");
    }

    (async function () {
      try {
        const token = Auth.getToken();
        const planBenefits = await planDetailsServices.getPlanBenefits(
          token,
          hNumber,
          planIdentifier
        );

        // if there was an error getting plan benefits, set loading to false
        if (!planBenefits) {
          toast.error("Couldn't get plan benefits. Please try again later.");
          setNoBenefits(true);
          setLoading(false);
          return;
        }

        // if there are no benefits, set noBenefits to true
        if (
          planBenefits &&
          planBenefits.enhancedBenefits &&
          planBenefits.enhancedBenefits.pbp_b14c_bendesc_ehc &&
          planBenefits.enhancedBenefits.pbp_b14c_bendesc_ehc.length === 0 &&
          planBenefits.rideshareBenefits &&
          planBenefits.rideshareBenefits.pbp_b10b_bendesc_mt_pal_d === ""
        ) {
          setNoBenefits(true);
          setLoading(false);
          return;
        }

        // set enhanced benefits and rideshare benefits
        setEnhancedBenefits(planBenefits.enhancedBenefits?.pbp_b14c_bendesc_ehc || []);
        setRideShareBenefits(planBenefits.rideshareBenefits.pbp_b10b_bendesc_mt_pal_d || "");
        setLoading(false);
      } catch (err) {
        toast.error("Couldn't get plan benefits. Please try again later.");
        setLoading(false);
      }
    })();
  }, [hNumber, planIdentifier]);

  return (
    <div className="min-w-full mb-16">
      <div className="my-2 ring-1 ring-whiteAccentColor rounded-md bg-thirdAccentColorHighlight p-4">
        <div className="text-center mb-2">
          <h3 className="m-0 text-3xl">Benefits Included With This Plan</h3>
          <h4 className="m-0 text-2xl text-lightGrayColor">{planName}</h4>
        </div>

        <hr className="my-8" />

        {loading ? (
          <div className="flex justify-center items-center">
            <div className="spinner" />
          </div>
        ) : (
          <RenderBenefits
            enhancedBenefits={enhancedBenefits}
            rideShareBenefits={rideShareBenefits}
            noBenefits={noBenefits}
          />
        )}
      </div>
    </div>
  );
}
