/* eslint-disable no-await-in-loop */
export default {
  // this function will help to keep checking poll status until not pending
  initiatePolling: async (pollingEventID, JWTtoken) => {
    let pollStatus = "pending";
    let returnData = null;
    while (pollStatus === "pending") {
      const response = await fetch(`/api/pollingEvent/${pollingEventID}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${JWTtoken}`
        }
      });
      const data = await response.json();
      pollStatus = data.status;
      returnData = data;

      // wait for the poll interval specified by the poll event before checking again
      // eslint-disable-next-line no-promise-executor-return
      if (pollStatus === "pending") {
        await new Promise((resolve) => {
          setTimeout(resolve, data.pollInterval);
        });
      }
    }
    return returnData;
  }
};
