import React, { useEffect, useState } from "react";
import { useBreakpoints } from "react-breakpoints-hook";
import { AiOutlineDollar } from "react-icons/ai";
import { BsPersonAdd } from "react-icons/bs";
import { CgPill } from "react-icons/cg";
import { IoMdSearch } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import ReactGATracking from "../../clients/googleAnalyticsClient";
import Mixpanel from "../../clients/mixPanelClient";
import ReactPixelInitialize from "../../clients/pixelClient";
import BrokerContactModal from "../../components/brokerContactModal/brokerContactModal";
import Button from "../../components/button/Button";
import Table from "../../components/table/table";
import auth from "../../utils/auth";
import "./planDetails.css";
import planningDetailsServices from "./planDetailsServices";
import PlanBenefitsDisplay from "./planBenefitsDisplay";

function PlanDetailInput({ icon, placeholder, label, searchType, carrier }) {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableInfo, setTableInfo] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const userToken = auth.getToken();

  const handleError = (errorMessageParam) => {
    switch (errorMessageParam) {
      case "Could Not Find Drug With This Name":
      case "No Providers Found":
      case "No Drug Formulary Found":
        setErrorMessage(errorMessageParam);
        break;
      case "No Client Declaration For This Carrier":
        setErrorMessage(
          "This service is not available for this carrier at this time. We apologize for any inconvenience."
        );
        break;
      default:
        setErrorMessage("Something went wrong, please try again later");
        break;
    }

    setLoading(false);
  };

  const formSubmit = async (e) => {
    // reset all state
    e.preventDefault();
    setTableInfo([]);
    setTableHeaders([]);
    setLoading(true);
    setErrorMessage("");

    try {
      // fetch provider search
      const pollingEventID = await planningDetailsServices.searchProviderOrDrug(
        userToken,
        inputValue,
        carrier,
        searchType
      );

      if (!pollingEventID) {
        return handleError("error polling");
      }

      // poll for provider search
      const response = await planningDetailsServices.pollProviderOrDrug(pollingEventID, userToken);

      // handle response if ok or not
      if (response.status === "success") {
        setTableInfo(response.response.tableData);
        setTableHeaders(response.response.tableHeaders);
      } else {
        handleError(response.status);
      }

      // reset loading
      setLoading(false);
    } catch (err) {
      handleError("Something went wrong, please try again later");
    }
  };

  return (
    <div className="planDetailsInput">
      <h3 className="text-2xl text-center flex items-center justify-center sm:justify-start min-w-ful my-4 font-semibold">
        {" "}
        {icon} &nbsp; {label}
      </h3>
      <form className="inputWrapper" onSubmit={formSubmit}>
        <IoMdSearch className="searchIcon" />
        <input
          required
          placeholder={placeholder}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <Button className="m-1 absolute right-0 top-0 " type="submit">
          Search
        </Button>
      </form>
      {(tableInfo.length > 0 || errorMessage !== "" || loading) && (
        <div className="responseWrapper">
          {loading && <div className="spinner" />}
          {tableInfo.length > 0 && <Table headers={tableHeaders} data={tableInfo} />}
          {errorMessage && <p>{errorMessage}</p>}
        </div>
      )}
      <hr />
    </div>
  );
}

export default function PlanDetails() {
  const [redirectModal, setRedirectModal] = useState(false);
  const [yearlyPremiumCost, setYearlyPremiumCost] = useState(null);
  const [yearlyPharmacyCost, setYearlyPharmacyCost] = useState(null);
  const [yearlyMedicalCost, setYearlyMedicalCost] = useState(null);
  const [totalYearCost, setTotalYearCost] = useState(null);
  const [planNameDetails, setPlanNameDetails] = useState(null);
  const [planInfo, setPlanInfo] = useState({});
  const [hNumber, setHNumber] = useState("");
  const [planIdentifier, setPlanIdentifier] = useState("");

  const navigate = useNavigate();

  const { sm, md } = useBreakpoints({
    sm: { min: 0, max: 640 },
    md: { min: 641, max: 1000 }
  });

  useEffect(() => {
    if (!auth.loggedIn()) {
      navigate("/");
    }
    const planInfoURL = {};
    const searchParams = new URLSearchParams(window.location.search);

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of searchParams) {
      planInfoURL[key] = value;
    }

    const premiumCost = planInfoURL.monthlyPremium.substring(1);
    const pharmacyCost = planInfoURL.estMonthlyPharmCost.substring(1);
    const medicalCost = planInfoURL.estMonthlyMedCost.substring(1);

    setPlanInfo(planInfoURL);
    setTotalYearCost(premiumCost * 12 + pharmacyCost * 12 + medicalCost * 12);
    setYearlyPremiumCost(parseInt(premiumCost, 10) * 12);
    setYearlyPharmacyCost(parseInt(pharmacyCost, 10) * 12);
    setYearlyMedicalCost(parseInt(medicalCost, 10) * 12);
    setPlanNameDetails({
      planName: planInfoURL.name,
      planCarrier: planInfoURL.carrier,
      planType: planInfoURL.planType
    });
    setHNumber(planInfoURL.hNumber);
    setPlanIdentifier(planInfoURL.planIdentifier);

    // ensures we scroll to top of page on load
    window.scrollTo(0, 0);
  }, [navigate]);

  // ensures we scroll to top of page on load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // add up all the money values
  const addMoney = (...args) => {
    if (!args || args[0] === undefined) return "$0";
    let total = 0;
    args.forEach((arg) => {
      total += parseInt(arg[0] === "$" ? arg.substring(1) : arg, 10);
    });
    return `$${total}`;
  };

  return (
    <>
      <BrokerContactModal
        redirectModal={redirectModal}
        setRedirectModal={setRedirectModal}
        planNameDetails={planNameDetails}
      />
      <div className="pt-16 bg-mediumHighlight">
        <Button className="text-base my-2 ml-2" onClick={() => navigate(-1)}>
          Back
        </Button>
      </div>
      <header className="planDetailsJumbotron">
        <h2 className="text-center">{planInfo.name}</h2>
        <p>{planInfo.carrier}</p>
        <p>{planInfo.planType}</p>
        <p>{planInfo.network}</p>
        <Button
          invertedTheme
          className="mt-10 border-[whiteTextColor]"
          onClick={() => {
            setRedirectModal(true);
            Mixpanel(`Switch Plan to ${planInfo.name}`);
            ReactGATracking("Button Click", `Switch Plan to ${planInfo.name}`);
            ReactPixelInitialize("Switch Plan Selected", { value: planInfo.name });
          }}>
          Switch To This Plan
        </Button>
        <p>or</p>
        <p>Call +1 (800) 378-1880 </p>
      </header>
      <section className="planDetailsBody">
        <div className="planDetailsCard">
          <div className="planDetailsCostSection">
            <h3 className="text-2xl text-center flex items-center justify-center sm:justify-start min-w-ful font-semibold">
              <div className="hidden sm:flex sm:items-center">
                <AiOutlineDollar /> &nbsp;
              </div>
              Estimated Cost Breakdown
            </h3>
            <p className="italicGrey text-center sm:text-start">* All values are approximations</p>
          </div>

          <div className="planDetailsTableWrapper">
            {sm || md ? (
              <>
                <div className="flex mb-[-1rem]">
                  <p className="w-1/2 text-base font-bold">Premium</p>
                  <p className="w-1/2 text-base">
                    {" "}
                    ${yearlyPremiumCost} ({planInfo.monthlyPremium}/month)
                  </p>
                </div>
                <hr />
                <div className="flex  my-[-1rem]">
                  <p className="w-1/2 text-base font-bold">Pharmacy</p>
                  <p className="w-1/2 text-base">
                    {" "}
                    ${yearlyPharmacyCost} ({planInfo.estMonthlyPharmCost}/month)
                  </p>
                </div>
                <hr />
                <div className="flex my-[-1rem]">
                  <p className="w-1/2 text-base font-bold">Medical</p>
                  <p className="w-1/2 text-base">
                    {" "}
                    ${yearlyMedicalCost} ({planInfo.estMonthlyMedCost}/month)
                  </p>
                </div>
              </>
            ) : (
              <Table
                tableClass="planDetailsTable"
                headers={["Expense", "Monthly", "Annual"]}
                data={[
                  [
                    <span key="premium">Premium</span>,
                    planInfo.monthlyPremium,
                    `$${yearlyPremiumCost}`
                  ],
                  [
                    <span key="pharmacy">Pharmacy</span>,
                    planInfo.estMonthlyPharmCost,
                    `$${yearlyPharmacyCost}`
                  ],
                  [
                    <span key="medical">Medical</span>,
                    planInfo.estMonthlyMedCost,
                    `$${yearlyMedicalCost}`
                  ]
                ]}
              />
            )}

            <hr />
          </div>

          <div className="planDetailsTotalWrapper">
            <div className="planDetailsTotal">
              <p>Monthly</p>
              <p>
                <span>Total</span>
              </p>
            </div>
            <div className="planDetailsTotal planDetailsTotalValues">
              <p>
                {addMoney(
                  planInfo.monthlyPremium,
                  planInfo.estMonthlyMedCost,
                  planInfo.estMonthlyPharmCost
                )}
              </p>
              <p>{`$${totalYearCost}`}</p>
            </div>
          </div>

          <div className="planDetailsCostSection">
            <h3 className="text-2xl text-center flex items-center justify-center sm:justify-start min-w-ful font-semibold">
              <div className="hidden sm:flex sm:items-center">
                <AiOutlineDollar /> &nbsp;
              </div>
              Plan Benefits
            </h3>
          </div>

          <PlanBenefitsDisplay
            hNumber={hNumber}
            planIdentifier={planIdentifier}
            planName={planInfo.name}
          />

          <PlanDetailInput
            icon={<BsPersonAdd />}
            label="Check Doctors In Network"
            placeholder="Search for you doctor by first or last name"
            searchType="provider"
            carrier={planInfo.carrier}
          />

          <PlanDetailInput
            icon={<CgPill />}
            label="Check Drugs In Network"
            placeholder="Search for drugs"
            searchType="drug"
            carrier={planInfo.carrier}
          />
        </div>
      </section>
    </>
  );
}
