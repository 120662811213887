import React, { useState, useEffect } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

// Prop Chart:
// prop name              | default value                  | is required | permission required to update
// ------------------------------------------------------------------------------------------------------
// value                  | -                              | true        | -
// setValue               | -                              | true        | -
// label                  | -                              | true        | -
// onChange               | (e) => {                       | false       | -
//                        |   setValue(e.target.value);    | false       | -
//                        | }                              | false       | -
// type                   | "text"                         | false       | -
// placeholder            | ""                             | false       | -
// maxlength              | ""                             | false       | -
// id                     | -                              | false       | no
// name                   | -                              | false       | no
// disabled               | false                          | false       | no
// required               | false                          | false       | no
// passwordRevealToggle   | false                          | false       | no
// inlineStartIcon        | -                              | false       | no
// inlineStartIconClasses | *see props below*              | false       | no
// backgroundColor        | "var(--white-text-color)"             | false       | no
// labelClasses           | ""                             | false       | yes
// inputClasses           | ""                             | false       | yes
// wrapperClasses         | ""                             | false       | yes

export default function Input({
  value,
  setValue,
  label,
  onChange = (e) => {
    setValue(e.target.value);
  },
  type = "text",
  placeholder = "",
  maxLength = 100,
  id,
  name,
  disabled = false,
  required = false,
  passwordRevealToggle = false,
  inlineStartIcon: IconComponent = null,
  inlineStartIconClasses = "absolute top-1/2 left-[0.625rem] -translate-y-1/2 text-[1.25rem] text-[var(--medium-gray-text-color)]",
  backgroundColor = "var(--white-text-color)",
  labelClasses = "",
  inputClasses = "",
  wrapperClasses = ""
}) {
  const [visible, setVisible] = useState(false);

  // toggles the visibility of the password
  const handlePWVisibility = () => {
    setVisible(!visible);
  };

  // determines the type of the input. If passwordRevealToggle is true, then it will return "text" if visible is true, and "password" if visible is false
  const determineType = () => {
    if (passwordRevealToggle) {
      return visible ? "text" : "password";
    }
    return type;
  };

  // and clears out the input value
  // also will ensure that both value, set value, and label are provided
  useEffect(() => {
    if (value === undefined || setValue === undefined || label === undefined) {
      throw new Error("Input component requires value, setValue, and label props");
    }

    if (passwordRevealToggle && type !== "password") {
      throw new Error("Input component passwordRevealToggle prop only works with type='password'");
    }
  }, []);

  return (
    <div className={`min-w-full ${wrapperClasses}`}>
      {label && (
        <label
          htmlFor={label}
          className={`block text-left text-lg font-medium leading-6 text-darkGrayColor ${labelClasses}`}>
          {label}
        </label>
      )}
      <div className={`${label ? "mt-4" : null} min-w-full`}>
        <div className="inline-block rounded-md shadow-sm ring-1 ring-inset ring-mediumGrayTextCl relative min-w-full">
          {IconComponent && <IconComponent className={inlineStartIconClasses} />}
          <input
            id={id}
            type={determineType()}
            name={name}
            value={value}
            onChange={onChange}
            className={`
              ${
                IconComponent
                  ? "min-w-[calc(100%-5.1rem)] pl-10"
                  : "min-w-[calc(100%-3.35rem)] pl-3"
              }
              h-9 p-1.5 pr-10 border border-solid border-opacity-25 ring-mediumGrayColor rounded-md disabled:cursor-not-allowed disabled:bg-mediumHighlightColor disabled:text-darkGrayTextColor
              ${inputClasses}`}
            // inline styles are used here because tailwindcss does not support dynamic styling
            style={{
              backgroundColor
            }}
            maxLength={maxLength}
            placeholder={placeholder}
            disabled={disabled}
            required={required}
          />
          {passwordRevealToggle && (
            <div
              className="absolute m-[0.3rem] h-full top-[70%] text-lg text-[var(--light-gray-color)] -translate-y-1/2 right-[0.625rem]"
              onClick={handlePWVisibility}>
              {visible ? (
                <AiOutlineEye className="text-xl cursor-pointer" />
              ) : (
                <AiOutlineEyeInvisible className=" text-xl cursor-pointer" />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
