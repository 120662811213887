import React from "react";
import "./home.css";
import { FaLock, FaShieldAlt, FaQuestion } from "react-icons/fa";

const securityFeatures = [
  {
    name: "Confidentiality",
    description:
      "All of our team members are trained in HIPAA and prioritize transparency, security and your comfort beyond all else.",
    icon: <FaLock />
  },
  {
    name: "Reliability",
    description:
      "We work tirelessly and have spent months researching and debating each and every single security measure we are taking.",
    icon: <FaShieldAlt />
  },
  {
    name: "Support",
    description: "Not sure yet? Have more questions? Contact us at: info@healthcare-download.com.",
    icon: <FaQuestion />
  }
];

export default function Security() {
  return (
    <div className="darkHomeSection">
      <div className="homeTitleLeft">
        <h2>Security Center</h2>
        <br />
        <h4>Your security is our number one priority.</h4>
      </div>
      <div className="homeDarkFlex">
        {securityFeatures.map((feature, index) => (
          <div key={`security feature #${index}`} className="homeDarkCardFrosted">
            <h5>
              <span className="homeDarkIcon">{feature.icon}</span> &nbsp; {feature.name}
            </h5>
            <br />
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
