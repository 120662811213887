/* eslint-disable react/prop-types */
import React from "react";

// for our card component we should make hooks to populate the card
// information easily

// the card component also needs to switch to vertical when it hits a breakpoint

export default function LightGreenCard({ ...props }) {
  return (
    <div className="personalHealthCardContainer">
      <div className="personalHealthCard">
        <div className="personalHealthHead">
          <img src={props.icon} className="card-icon" alt={props.altText} />
        </div>
        <h3>{props.title}</h3>
        <div className="boldText">
          <p>{props.textContent}</p>
        </div>
      </div>
    </div>
  );
}
