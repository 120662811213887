import React from "react";

import AboutPageHeader from "../../components/about/cards/AboutPageHeader";
import InsurancePartnersSection from "../../components/about/cards/InsurancePartnersSection";
import OurValuesSection from "../../components/about/cards/OurValuesSection";
import PersonalHealthSection from "../../components/about/PersonalHealthSection";
import "../../components/about/About.css";

export default function About() {
  return (
    <>
      <div className="about">
        <AboutPageHeader />
      </div>
      <div className="insurancePartners">
        <InsurancePartnersSection />
      </div>
      <div className="value">
        <OurValuesSection />
      </div>
      <div className="personalHealthSection">
        <PersonalHealthSection />
      </div>
    </>
  );
}
