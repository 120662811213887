/* eslint-disable react/button-has-type */
import React, { useEffect } from "react";

// Prop Chart:
// prop name        | default value | is required | allowed values                 | permission required to update
// ----------------------------------------------------------------------------------------------------------------
// children         | -             | false       | -                              | -
// fontSize         | "1rem"        | false       | Any valid CSS font size value  | -
// padding          | "0.65rem"     | false       | Any valid CSS padding value    | -
//                  |               |             |                                |
// theme            | "primary"     | false       | -"primary"                     | -
//                  |               |             | -"warning"                     | -
//                  |               |             | -"danger"                      | -
//                  |               |             | -"info"                        | -
//                  |               |             | -"success"                     | -
//                  |               |             |                                |
// invertedTheme    | false         | false       | true, false                    | -
// type             | -             | false       | Any valid html button type     | -
// onClick          | () => {}      | false       | Function                       | -
// className        | ""            | false       | -                              | -
// fullWidth        | false         | false       | -                              | -
// disabled         | false         | false       | - true, false                  | -

export default function Button({
  children,
  padding = "0.65rem",
  disabled = false,
  theme = "primary",
  invertedTheme = false,
  type = "button",
  onClick = () => {},
  className = "",
  fullWidth = false
}) {
  // if a non-valid theme is passed, throw an error
  useEffect(() => {
    if (
      ![
        "primary",
        "insuranceSelect",
        "warning",
        "danger",
        "success",
        "loading",
        "minMax",
        "noStyle"
      ].includes(theme)
    ) {
      throw new Error(
        "Button component theme prop must be one of the following: primary, warning, danger, info, success"
      );
    }
  });

  // list of color themes for buttons
  const baselineStyle = " border-none hover:opacity-80";
  const baselineStyleInverted = "border-none bg-whiteTextColor hover:opacity-80";

  // non-inverted colors:
  const danger = `${baselineStyle} bg-dangerColor text-whiteTextColor hover:bg-dangerColor`;
  const insuranceSelect = `bg-lightGray border-none  m-3 h-[12rem] p-1 h-40 hover:bg-whiteTextColor hover:drop-shadow-xl focus:drop-shadow-sm focus:grayTextColor`;
  const success = `border-none bg-successColor  text-base text-whiteTextColor hover:opacity-80`;
  const disabledTheme = `border-borderColor p-[0.65rem] text-base cursor-not-allowed bg-borderColor shadow-sm rounded-md hover:border-borderColor hover:border-borderColor hover:bg-borderColor`;
  const loading = `border-borderColor cursor-wait bg-borderColor hover:border-borderColor hover:bg-borderColor`;
  const minMax = "float-right border-transparent text-mainTextColor bg-transparent";
  const primary = `${baselineStyle} border-solid bg-mainTextColor text-whiteTextColor`;

  // inverted colors:
  const dangerInverted = `${baselineStyleInverted} border-dangerColor text-dangerColor hover:border-dangerColor hover:text-dangerColor`;
  const insuranceSelectInverted = `${baselineStyleInverted} border-thirdAccentColor text-thirdAccentColor hover:border-thirdAccentColor hover:text-thirdAccentColor`;
  const successInverted = `${baselineStyleInverted} border-successColor text-successColor hover:border-successColor hover:text-successColor`;
  const primaryInverted = `${baselineStyleInverted} text-mainTextColor`;

  // no style:
  const noStyle = "bg-transparent border-none p-0 m-0";

  const determineButtonTheme = () => {
    if (disabled) {
      return disabledTheme;
    }
    switch (theme) {
      case "noStyle":
        return noStyle;
      case "danger":
        return invertedTheme ? dangerInverted : danger;
      case "insuranceSelect":
        return invertedTheme ? insuranceSelectInverted : insuranceSelect;
      case "success":
        return invertedTheme ? successInverted : success;
      case "minMax":
        return minMax;
      case "loading":
        return loading;
      case "primary":
      default:
        return invertedTheme ? primaryInverted : primary;
    }
  };

  return (
    <button
      className={`cursor-pointer m-0 duration-300 font-semibold shadow-none rounded-md
        ${determineButtonTheme()}
        ${className}
        ${fullWidth && "min-w-full"}
      `}
      style={{
        padding
      }}
      type={type}
      onClick={onClick}
      disabled={disabled || theme === "loading"}>
      {children}
    </button>
  );
}
