import React from "react";

export default function OurValuesSection() {
  // This card explains our values at HCDL

  const values = [
    {
      name: "- Our Vision",
      description:
        "Empower users to access and use their healthcare data to maintain healthy outcomes in their lives, and their pocketbooks."
    },
    {
      name: "- Our Mission",
      description:
        "We will use information from insurance companies to improve health and financial outcomes."
    },
    {
      name: "- Our Innovation",
      description:
        "We leverage access to data so that it serves you in the most efficient and repable way possible."
    },
    {
      name: "- Our Customers",
      description:
        "You inspire and motivate us. You are curious, bold and demand more from services that purport to serve you. Ensuring that you have the information you need to take charge of your care is what we’re about."
    },
    {
      name: "- Our Team",
      description:
        "We thrive by talent-driven excellence and a diversity of perspectives. Being open to learning means being open to challenges; we embrace both and are the better for it. We support our team members and reward outstanding performance."
    },
    {
      name: "- Our Ethics",
      description:
        "At the heart of our service is to provide for and protect you and your data. Internally, we hold ourselves to the highest standards of integrity and accountability."
    }
  ];

  return (
    <div>
      <h1>Our Values</h1>
      <div className="valuesContainer">
        {values.map((value, index) => (
          <div key={index + value.name} className="valueCard">
            <div className="valueBody">
              <h2>{value.name}</h2>
              <p>{value.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
