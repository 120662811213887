import React from "react";

export default function Table({ tableDivClass, tableClass, headers, data, nestedRows }) {
  if (!data || data.length === 0) {
    return (
      <div className={tableDivClass}>
        <table className={tableClass}>
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={`${header} #${index}`}>{header}</th>
              ))}
            </tr>
          </thead>
        </table>
      </div>
    );
  }

  return (
    <div className="universalTableDivClass">
      <table className={`universalTable ${tableClass}`}>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={`${header} #${index}`}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <React.Fragment key={`Row #${rowIndex}`}>
              <tr>
                {row.map((cell, cellIndex) => (
                  <td key={`${cellIndex} ${cell}`}>{cell}</td>
                ))}
              </tr>
              {/* Render nested rows if available */}
              {nestedRows && nestedRows[rowIndex] && (
                <tr>
                  <td className="nestedTableBackground" colSpan={headers.length}>
                    {nestedRows[rowIndex]}
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}
