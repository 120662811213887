// eslint-disable-next-line import/no-extraneous-dependencies
import mixpanel from "mixpanel-browser";
import Auth from "../utils/auth";
import getCookie from "../utils/cookieHelpers";

const consentCookie = getCookie("consentCookie");
const environmentCheck = process.env.NODE_ENV === "production";

export default function Mixpanel(name) {
  const userID = Auth.getUser().data.id;
  const userEmail = Auth.getUser().data.email;
  if (consentCookie !== "rejectAll") {
    mixpanel.init(`${process.env.REACT_APP_MIXPANEL_TOKEN}`, {
      ignore_dnt: true,
      persistence: "localStorage"
    });
    if (environmentCheck) {
      mixpanel.track(name, { "user email": userEmail });
      mixpanel.identify(userID);
    }
  }
}
