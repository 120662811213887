export default {
  // this function gets the authorization token from the token endpoint
  getAuthToken: async (insurer, authCode, userID, JWTtoken) => {
    // this is the base url for the humana sandbox token endpoint
    const fetchURL = `/api/insurerDataFetch/${insurer}Fetch/oauth2/tokenCallback`;

    // this is the fetch request to the server's token endpoint
    const response = await fetch(fetchURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${JWTtoken}`
      },
      body: JSON.stringify({
        authCode,
        userID
      })
    });
    const data = await response.json();
    return data.pollingEventID;
  },

  // this function gets the patient information from the patient endpoint
  getInformation: async (insurer, informationType, userID, JWTtoken) => {
    // url for patient information fetch
    const fetchURL = `/api/insurerDataFetch/${insurer}Fetch/${informationType}`;

    const response = await fetch(fetchURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${JWTtoken}`
      },
      body: JSON.stringify({
        userID
      })
    });

    const data = await response.json();
    return data.pollingEventID;
  }
};
