import React from "react";
import { Link } from "react-router-dom";
import ReactGATracking from "../../clients/googleAnalyticsClient";
import Mixpanel from "../../clients/mixPanelClient";
import ReactPixelInitialize from "../../clients/pixelClient";
import { makeFirstLetterCapital } from "../../utils/dataFormatHelpers";
import LoadingSkeleton from "../loadingSkeleton/loadingSkeleton";

function VerticalLine() {
  return <div className="w-px bg-whiteAccentColor mx-0" />;
}

const navigateToClinicalTrialsMore = (userTrial) => {
  // create a search set of params to pass to the clinicalTrialsMore page for each key and value in the userTrial object
  const searchParams = new URLSearchParams();
  Object.keys(userTrial).forEach((key) => {
    searchParams.append(key, userTrial[key]);
  });

  return `/clinicalTrialsMore?${searchParams.toString()}`;
};

// This renders 3 clinical trial cards to the dashboard
export default function ClinicalTrialDashboardCard({ data, status }) {
  const filteredDataByOverallStatus = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i].overallStatus === "RECRUITING") {
      filteredDataByOverallStatus.push(data[i]);
    }
    if (filteredDataByOverallStatus.length === 3) {
      break;
    }
  }

  if (status === "loading") {
    const loadingSkeletonArray = new Array(3).fill(null).map((_, index) => (
      <div
        key={index}
        className="mb-2 ring-1 ring-whiteAccentColor rounded-md bg-whiteTextColor shadow animate-pulse">
        <div className="bg-mainTextColor rounded-t-md">
          <LoadingSkeleton backgroundColor="var(--main-text-color)" height="2.5" />
        </div>
        <div className="p-2">
          <LoadingSkeleton skeletonRenderCount={3} className="m-6" maxWidth="40" />
        </div>
      </div>
    ));
    return loadingSkeletonArray;
  }

  if (status === "failed" || status === "missing") {
    return;
  }

  const renderTrialStatus = (trialStatus) => {
    const refinedStatus = makeFirstLetterCapital(trialStatus.split("_").join(" "));
    let color;
    switch (refinedStatus) {
      case "Recruiting":
        color = "successColor";
        break;
      case "Not Yet Recruiting":
        color = "thirdAccentColor";
        break;
      case "Completed":
      case "Terminated":
      case "Withdrawn":
      case "Active Not Recruiting":
        color = "dangerColor";
        break;
      default:
        color = "mainTextColor";
    }

    return (
      <span
        className={`inline-flex flex-shrink-0 items-center rounded-md px-1.5 py-0.5 text-sm font-medium text-${color} border border-solid border-${color}`}>
        {refinedStatus}
      </span>
    );
  };

  return filteredDataByOverallStatus.map((trial, index) => (
    <div key={index}>
      <div>
        <div className="mb-2 ring-1 ring-whiteAccentColor rounded-md bg-white">
          <div className="flex items-center justify-between">
            <div className="flex-1 p-6">
              <div className="flex items-center space-x-3">
                <h3 className="text-2xl leading-7">{trial.briefTitle}</h3>
                {renderTrialStatus(trial.overallStatus)}
              </div>
              <p className="mt-1 text-lg text-darkGrayColor">
                <span className="font-medium text-mainTextColor">Conditions:</span>{" "}
                {trial.conditions.join(", ")}
              </p>
            </div>
          </div>
          <div>
            <div className="flex divide-x divide-white bg-mainTextColor rounded-bl-md rounded-br-md overflow-hidden">
              <div className="flex w-0 flex-1">
                <a
                  onClick={() => {
                    Mixpanel("Clinical Trials Official Site");
                    ReactGATracking("Page View", "Clinical Trials Official Site");
                    ReactPixelInitialize("Clinical Trials Official Site", {
                      value: trial.briefTitle
                    });
                  }}
                  href={`https://clinicaltrials.gov/study/${trial.nctId}`}
                  target="_blank"
                  className="text-whiteTextColor inline-flex w-0 flex-1 items-center justify-center rounded-bl-md py-2 text-base font-semibold">
                  Go to Official Site
                </a>
              </div>
              <VerticalLine />
              <div className="flex w-0 flex-1">
                <Link
                  onClick={() => {
                    Mixpanel("Clinical Trials Details Page");
                    ReactGATracking("Page View", "Clinical Trials Details Page");
                    ReactPixelInitialize("Clinical Trials Details Page", {
                      value: trial.briefTitle
                    });
                  }}
                  to={navigateToClinicalTrialsMore(trial)}
                  className="text-whiteTextColor inline-flex w-0 flex-1 items-center justify-center rounded-br-md py-2 text-base font-semibold">
                  More Details
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));
}
